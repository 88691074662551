@import '../../common/style/styleguide';

.of-employee-login {
  max-width: 1300px;
  margin: 0px auto;

  &__header {
    @include breakpoint(laptop-and-up) {
      padding-bottom: 4 * $ds-baseline;
    }
  }
  &__grid {
    > .ds-grid__row {
      @include breakpoint(tablet) {
        display: grid;
      }
    }
    &__row0 {
      @include breakpoint(tablet-and-down) {
        padding-bottom: 4 * $ds-baseline;
      }
      &__button-tablet-and-below {
        @include breakpoint(laptop-and-up) {
          display: none;
        }
      }
    }
    &__row1 {
      @include breakpoint(laptop-and-up) {
        height: 230px;
        margin-bottom: 32px;
      }
      &__picto {
        padding: 2 * $ds-baseline;
        height: 100%;
        width: 100%;
        background: transparent url('../../common/svg/EOE_Illustration.svg') no-repeat;
        background-size: contain;
        @include breakpoint(laptop-and-up) {
          margin-left: 2 * $ds-baseline;
        }
        @include breakpoint(tablet) {
          padding-bottom: 4 * $ds-baseline;
          margin-bottom: 4 * $ds-baseline;
        }
        @include breakpoint(phone-large) {
          padding-bottom: 3 * $ds-baseline;
          margin-bottom: 3 * $ds-baseline;
          width: 400px;
        }
        @include breakpoint(phone) {
          padding-bottom: 0px;
          margin-bottom: 0px;
          width: 280px;
        }
      }
      &__text {
        margin-bottom: $ds-baseline * 4;
        @include breakpoint(tablet-and-down) {
          display: grid;
        }
        p {
          font-size: 3 * $ds-baseline;
          margin: auto;
          @media only screen and (min-width: 401px) and (max-width: $breakpoint-xs-max-width) {
            font-size: 20px;
          }
          @media only screen and (min-width: 321px) and (max-width: 400px) {
            font-size: 15px;
          }
          @media only screen and (max-width: 320px) {
            font-size: 14px;
          }
        }
      }
      &__button-laptop-and-above {
        @include breakpoint(tablet-and-down) {
          display: none;
        }
      }
    }
    &__row2 {
      @include breakpoint(laptop-and-up) {
        padding-bottom: 3 * $ds-baseline;
      }
      #row2Col1,
      #row2Col2 {
        @include breakpoint(tablet-and-down) {
          padding-bottom: 3 * $ds-baseline;
        }
      }
      &__text {
        > h3 {
          margin: auto;
          margin-bottom: 2 * $ds-baseline;
        }
        > ul {
          list-style-type: disc;
          padding-left: 3 * $ds-baseline;
        }
        background-color: $ds-color__blue-200;
        padding: 3 * $ds-baseline;
        border-radius: 5px;
        height: 100%;
      }
      #readMoreEmployeeOmaElisa {
        border-width: 2px;
        border-radius: 0px;
        margin-top: 2 * $ds-baseline;
      }
    }
    &__row3 {
      padding-bottom: 3 * $ds-baseline;
    }
    > h4 {
      margin: $ds-baseline 0px;
      @include breakpoint(tablet-and-up) {
        padding-left: $ds-baseline;
      }
    }
    &__row4 {
      &__links {
        display: flex;
        flex: 1 1 0;
        gap: 24px;
        flex-wrap: wrap;
        > .ds-link {
          display: flex;
        }
        > .ds-link--style-link {
          text-decoration: none;
        }
      }
      &__link {
        svg {
          align-self: center;
          width: 56px;
          height: 56px;
          margin: $ds-baseline;
        }
        &__home-office {
          background: transparent url('../../common/svg/home_office.svg') no-repeat;
        }
        &__self-service {
          background: transparent url('../../common/svg/self_service.svg') no-repeat;
        }
        &__saunalahti-logo {
          background: transparent url('../../common/svg/saunalahti-logo.svg') no-repeat;
        }
        background: transparent url('../../common/svg/icon-arrow-forward-black.svg') no-repeat;
        background-position: 95%;
        border: 1px solid $ds-color__neutral-300;
        border-radius: 5px;
        flex: 1 1 0;
        min-width: 250px;
        padding: 10px;
        align-items: center;
        gap: $ds-baseline;
        @include breakpoint(phone-large-and-up) {
          min-width: 320px;
        }
        > .ds-link-last-object {
          padding-right: 2 * $ds-baseline;
          > span {
            color: $ds-color__blue-800;
          }
        }
      }
    }
  }
}
