@import '../../common/style/styleguide';

.of-simple-summary {
  padding-bottom: 2 * $ds-baseline;

  tbody tr {
    td {
      text-align: right;
    }
  }

  @include breakpoint(phone) {
    display: flex;
    flex-direction: column;

    tbody tr {
      td {
        padding-bottom: 0;
        padding-top: $ds-baseline;
        text-align: left;
      }

      th {
        padding-bottom: 0;
        padding-top: 3 * $ds-baseline;
      }
    }

    tr {
      display: flex;
      flex-direction: column;
    }
  }
}
