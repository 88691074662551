@import '../../common/style/styleguide';

.of-usernumber-and-publicity {
  &__edit_privacy {
    .ds-radio-group {
      margin-bottom: calc($ds-baseline / 2);
    }

    .ds-input {
      margin-bottom: $ds-baseline;
      @include breakpoint(tablet-and-up) {
        max-width: 380px;
      }
    }
  }

  &__edit_button {
    .ds-button {
      min-width: 0;
      text-transform: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }
  }

  &__save_cancel {
    @include breakpoint(phone-large-and-down) {
      .ds-button {
        margin-bottom: $ds-baseline * 1.5;
      }
    }
  }
}
