@import '../../common/style/styleguide';

.of-error-tooltip {
  & {
    position: relative;
  }

  &.ea-notification {
    padding: $global-baseline * 1.5;
  }

  &--overlay {
    position: absolute;
    z-index: $zindex1;

    &.of-error-notification--top {
      transform: translate3d(0, calc(-100% - 20px), 0);
    }
  }
}
