@import '../../common/style/styleguide';

.of-sim-card-type {
  label {
    display: block;
    font-weight: 600;
  }
}
.of-sim-card-type--invalid-sim-type {
  color: $ea-color-grey;
}
