@import '../../common/style/styleguide';

.of-order-summary {
  background-color: $ds-color__neutral-100;
  border: 1px solid $ds-color__neutral-300;
  padding: 3 * $ds-baseline 2 * $ds-baseline;
  width: 100%;
  box-sizing: border-box;

  &__details-toggle {
    background: transparent;
    border: none;
    color: $ds-color__blue-600;
    // make button text level with the table text
    margin-right: 2 * $ds-baseline;
    padding: 0;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: $ds-color__blue-800;
    }
  }
}
