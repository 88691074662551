@import '../../../../../../common/style/styleguide';
@import '../../../../../../components/BreadCrumbs/BreadCrumbs.scss';

.of-subscription-selection-nav {
  background-color: $ds-color__neutral-100;

  nav > ol {
    margin: 0 2 * $ds-baseline;
    padding: 2 * $ds-baseline 0;
    @include breadcrumb-dynamic-margins;
  }

  .auto-complete-container {
    margin-bottom: 6 * $ds-baseline;
    > .autocomplete-root {
      min-height: auto;
      max-width: 320px;
      @include breakpoint(phone-large-and-down) {
        max-width: initial;
      }
    }
  }
}
