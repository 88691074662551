.of-support-case-description {
  width: 100%;

  // TODO these font-size styles are here because of component-library-overrides so remove this when overrides are removed
  & + .ds-button--size-l {
    font-size: 13px;
  }

  .description-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
  }

  [name='description'] {
    height: 150px;
  }
}
