@import '../../../common/style/styleguide';

.of-punchout-checkout-billing-info {
  &__field {
    padding-bottom: $global-baseline * 2;
  }

  &__column {
    padding-right: 6%;
  }

  label {
    display: block;
    font-weight: 600;
  }

  .of-sub-header {
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 1em;
    border-bottom: 1px solid $ds-color__neutral-300;
  }

  div[class^='ds-grid__col--'],
  div[class*=' ds-grid__col--'] {
    padding: 0;
  }
}
