@import '../../common/style/styleguide';

.of-fixed-bb-product-selection {
  &__picto-wrapper {
    display: flex;
    margin-top: $global-baseline * 2;
  }

  &__heading {
    font-size: 32px;

    @include breakpoint(phone-large-and-down) {
      text-align: center;
      margin-top: $global-baseline * 2;
    }
  }

  &__subheading {
    font-size: 17px;

    @include breakpoint(phone-large-and-down) {
      text-align: center;
    }
  }

  &__tile-label {
    font-size: 18px;
    font-weight: 600;

    @include breakpoint(phone-large-and-down) {
      text-align: center;
    }
  }

  &__tile.ea-selection-tile ul {
    grid-template-columns: 1fr 1fr;

    @include breakpoint(tablet-and-up) {
      width: $global-baseline * 28;
    }

    li {
      min-height: $global-baseline * 6;
    }

    @include breakpoint(phone-large-and-down) {
      margin: 0 auto;
      li {
        min-height: $global-baseline * 8;
      }
    }
  }
}
