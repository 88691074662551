@import '../../style/styleguide';

.form-grid-fieldset {
  border: 0;
  display: grid;
  // Based on the layout grid gutter
  // This is not responsive by design: I asked designers, and they said it
  //   should be 24px on all viewport sizes.
  $column-gap: $ds-baseline * 3;
  // row gap is not needed as form elements have margins/padding/whatever
  gap: 0 $column-gap;

  /*
  Responsive columns based on container width without container queries.
  See https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
   */
  $max-column-count: 2;
  $max-total-gap: ($max-column-count - 1) * $column-gap;
  // Arbitrary minimum usable width for form fields (based on actual values in use)
  $min-column-width: 248px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max($min-column-width, calc((100% - $max-total-gap) / $max-column-count)), 1fr)
  );

  margin: 0;
  padding: 0;

  > legend {
    /*
    This makes legend function somewhat like a normal element.
    It doesn't actually float anywhere as it's in the grid.
    See https://github.com/whatwg/html/issues/3912
    See https://stackoverflow.com/questions/3973456/default-css-values-for-a-fieldset-legend
      (we don't need the width: 100% part as grid mangles that anyway)
     */
    float: left;
    padding-left: 0;
    padding-right: 0;
  }

  .form-grid-clear {
    // Place in first column
    grid-column: 1;
  }

  .form-grid-force-end {
    // Place in last column
    grid-column: -2;
  }

  .form-grid-full-width {
    // Take up all the columns
    grid-column: 1 / -1;
  }
}
