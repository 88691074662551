@import '../../common/style/styleguide';

.ea-product-card {
  display: block;
  padding: 0 $global-baseline 10.5 * $global-baseline;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 440px;

  &__big-footer {
    @extend .ea-product-card;
    padding: 0 $global-baseline 15 * $global-baseline;
  }

  &__header {
    height: 5 * $global-baseline;
  }

  &__button {
    margin: 20px 10px 10px 10px;
  }

  &__title {
    .ea-h1 {
      @include breakpoint(phone-large-and-up) {
        @include fluid-type(36px, 40px, 54px, 62px);
      }
      @include breakpoint(desktop) {
        font-size: 54px;
      }
    }
  }

  &__product-name {
    min-height: 6 * $global-baseline;
    .ea-h4 {
      padding-bottom: 0;
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: $global-baseline;
    padding-right: $global-baseline;
    padding-bottom: $global-baseline;
    min-height: 8.5 * $global-baseline;
    .price-text {
      color: #006dfc;
      padding: 0;
      &--highlight {
        color: $ea-color-warning;
      }
      &--line-through {
        color: $ea-color-grey;
        font-weight: normal;
        text-decoration: line-through;
      }
    }
    .ea-disclaimertext {
      color: $ea-color-grey;
    }
    h3 {
      display: inline;
    }
  }
  p {
    margin: 0 0 0.5em;
  }
}
