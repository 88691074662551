@import '../../common/style/styleguide';

.of-pbx-accordion-content {
  .ds-tooltip {
    min-width: 300px;
  }

  div[class^='ds-grid__col--'],
  div[class*=' ds-grid__col--'] {
    padding-left: 0;
    padding-right: 0;
  }

  .ds-tooltip {
    padding: $ds-baseline;
  }

  &__chain_dropdown {
    @include breakpoint(tablet-and-up) {
      min-width: 400px;
    }
  }

  .ds-dropdown__button {
    width: 95%;
  }

  &__number {
    @include breakpoint(phone) {
      width: 100%;
    }

    .ds-input--inputarea {
      margin: 0;
      position: relative;
      width: 90%;
      min-width: 130px;
    }

    .ds-input {
      margin-bottom: $ds-baseline;
    }
  }

  &__working-hours {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: $ds-color__neutral-200--light;
    border-color: $ds-color__neutral-400;
    border-width: 1px;
    border-style: solid;
    padding-left: 2 * $ds-baseline;
    padding-right: 0;
  }
}
