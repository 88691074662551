.status-wrapper {
  display: flex;
  align-content: flex-start;

  .status-badge {
    display: flex;
    height: 12px;
    width: 12px;
    margin: auto 8px auto 0;

    .ds-badge.ds-badge--status {
      display: flex;
      padding-top: 50%;
      padding-bottom: 50%;

      &::before {
        top: -6px;
        padding: 6px;
        border-radius: 6px;
      }
    }
  }

  &.status-column {
    .status-badge {
      margin-right: 6px;
    }
  }

  &.small-badge {
    .status-badge {
      height: 9px;
      width: 9px;
      margin-left: 0.5px;
      margin-right: 6.5px;

      .ds-badge.ds-badge--status::before {
        top: -1px;
        padding: 4.5px;
        border-radius: 4.5px;
      }
    }
  }
}
