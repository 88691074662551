@import '../../common/style/styleguide';

.of-image-cards {
  max-width: 100%;
  text-align: center;

  &.ds-cardcontainer {
    margin-bottom: 6 * $ds-baseline;
    margin-top: 2 * $ds-baseline;

    &.three-wide {
      .ds-cardcontainer-inner {
        @include breakpoint(laptop-and-up) {
          grid-template-columns: repeat(3, minmax(270px, 1fr));
        }
      }
    }

    .ds-card {
      &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2 * $ds-baseline;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        > img {
          &.of-card-image--s {
            height: 81px;
          }

          &.of-card-image--m {
            height: 264px;
            padding-top: 24px;
          }

          &.of-card-image--l {
          }
        }

        > div {
          align-items: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 2 * $ds-baseline;
          padding: 0 1rem;

          > a:last-child {
            margin-top: auto;
          }

          h3 {
            margin: 0;
            padding: 0;
          }

          > p {
            margin-top: 0;
          }
        }
      }

      &-heading {
        display: none;
      }
    }
  }

  @media screen and (max-width: 900px) {
    &.ds-cardcontainer {
      .ds-cardcontainer-inner {
        grid-template-columns: 1fr;

        .ds-card {
          margin: 0 auto;
          max-width: 604px;
        }
      }
    }
  }
}
