@import '../../common/style/styleguide';

.of-active-filter-tiles-container {
  display: none;

  &__active {
    display: flex;
    margin-top: 25px;
  }
}

.of-active-filter-tiles {
  display: flex;
  flex-wrap: wrap;

  &__tile {
    display: flex;
    align-items: center;
    background-color: $ds-color__neutral-200;
    border-radius: 2px;
    padding: 4px 10px;
    margin: 0 10px 10px 0;
    font-size: smaller;
    cursor: pointer;

    &:hover {
      background-color: $ds-color__neutral-300;
    }
  }
}

.of-clear-filters-button {
  margin-left: auto;

  .ds-button {
    font-size: 12px !important;

    @media screen and (max-width: $breakpoint-m) {
      display: none;
    }
  }
}

.of-search-filters {
  font-size: 14px;

  .ds-checkbox__label,
  .of-toggle-view-items-button {
    font-size: 14px;
  }
}

.of-search-filters-grid {
  @media screen and (max-width: $breakpoint-m) {
    display: none;
  }

  position: relative;
  min-height: 60px;
  border: $global-border-normal solid $ds-color__neutral-300;

  .of-checkbox-group .ds-button {
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    color: $ds-color__blue-400--dark;
    background-color: transparent;
    border: unset;

    &:hover {
      background-color: unset;
    }
  }

  .ds-grid__row {
    width: 100%;
  }
}

.of-search-filters-modal {
  display: none;
  @media screen and (max-width: $breakpoint-m) {
    display: block;
    position: fixed;
    z-index: 10001;
  }

  &__sticky-buttons {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding-bottom: 30px;
    z-index: 1;
    border-top: $global-border-normal solid $ds-color__neutral-300;
  }

  .ds-modal {
    top: auto;
    bottom: 0;
    border-radius: 0;
  }

  .ds-modal-contentarea {
    background-color: white;
    max-height: 100vh;
    min-height: 25vh;
    overflow-y: auto;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .loading-spinner {
    // element's contents are constantly animated, might as well minimize the
    // effect it has on the rest of the document
    contain: strict;
    left: 50%;
    // Don't block click to input field
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
