.of-bb-invitation-employee-not-found {
  p {
    padding-bottom: 10px;
  }
  p:first-of-type {
    padding-top: 5px;
  }
}

.of-bb-employee-invitation {
  @extend .of-bb-invitation-employee-not-found;
  padding-bottom: 20px;
  &__contact-person-info span {
    display: block;
  }
}
