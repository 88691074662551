@import '../../common/style/styleguide';

.of-hierarchical-selector {
  padding: 3 * $ds-baseline;
  border: 1px solid $ds-color__neutral-400;

  hr {
    margin-top: 3 * $ds-baseline;
    margin-bottom: 3 * $ds-baseline;
    border-top: 1px solid $ds-color__neutral-400;
    border-bottom: none;
  }

  &__item,
  &__additional-item {
    display: flex;
    justify-content: space-between;

    input[type='checkbox']:disabled {
      visibility: hidden;
    }

    input[type='checkbox']:disabled,
    label.disabled {
      opacity: 0.4;
    }
  }

  &__spacer {
    border-left: 1px solid $ds-color__neutral-500;
    margin-top: 0.5 * $ds-baseline;
    margin-left: 1.5 * $ds-baseline;
    height: 2 * $ds-baseline;
  }

  &__spacer:first-child {
    display: none;
  }
}
