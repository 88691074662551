// Page Margins mixins, mostly useful when you only want to apply the margins on some breakpoints.
@mixin page-margins-as-grid-bleed {
  grid-column: 1 / -1;
}

@mixin page-margins-as-grid-bleed-left {
  grid-column: 1 / -2;
}

@mixin page-margins-as-grid-bleed-right {
  grid-column: 2 / -1;
}

@mixin page-margins-as-grid {
  display: grid;
  gap: 0;
  // minmax(0, 1fr) instead of 1fr basically forces the column to be the correct size.
  //   With just 1fr, too large content would widen the column.
  grid-template-columns: var(--of-page-margin) minmax(0, 1fr) var(--of-page-margin);

  > * {
    grid-column: 2 / 3;
  }

  > .of-page-margins__bleed {
    @include page-margins-as-grid-bleed;

    &-left {
      @include page-margins-as-grid-bleed-left;
    }

    &-right {
      @include page-margins-as-grid-bleed-right;
    }
  }
}

@mixin page-margins-as-margin-or-padding-bleed {
  margin-left: calc(var(--of-page-margin) * -1);
  margin-right: calc(var(--of-page-margin) * -1);
}

@mixin page-margins-as-margin-or-padding-bleed-left {
  margin-left: calc(var(--of-page-margin) * -1);
  margin-right: 0;
}

@mixin page-margins-as-margin-or-padding-bleed-right {
  margin-left: 0;
  margin-right: calc(var(--of-page-margin) * -1);
}

@mixin page-margins-as-margin {
  margin-left: var(--of-page-margin);
  margin-right: var(--of-page-margin);
}

@mixin page-margins-as-padding {
  padding-left: var(--of-page-margin);
  padding-right: var(--of-page-margin);
}
