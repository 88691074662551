// TODO: This file is deprecated, all uses of below variables should be eradicated.
// This files contents were copied from pattern-library: '~@styleguide/pattern-library/release/scss/variables'.
// All unused variables were deleted.

$global-borderradius: 2px;
$global-border-normal: 1px;

$global-font-normal: 'Open Sans', Arial, sans-serif;
$global-font-brand-book: 'Jost black', sans-serif;
$global-font-brand: 'Jost book', sans-serif;
$global-font-brand-bold: 'Jost bold', sans-serif;

/* colors used */
$ea-color-blue: #0019af;
$ea-color-orange: #f66000;
$ea-color-black: #000000;
$ea-color-white: #ffffff;
$ea-color-coal-grey: #303031;
$ea-color-dark-grey: #404041;
$ea-color-grey: #767677;
$ea-color-light-grey: #b0b0b1;
$ea-color-dark-smoke: #d0d0d1;
$ea-color-smoke: #e0e0e1;
$ea-color-light-smoke: #fafafa;
$ea-color-white-smoke: #f5f5f5;
$ea-color-linkblue: #0068f0;

$ea-color-light-grey-rgb: rgb(176, 176, 177);

$ea-color-cyan: #00baf2;
$ea-color-light-orange: #fcaf17;
$ea-color-yellow: #ffd503;
$ea-color-green: #00a950;
$ea-color-medium-green: #54bc7b;
$ea-color-light-green: #a1d5ae;

$ea-color-error: #df1515;
$ea-color-warning: #f66000;

/* global margins */
$global-base-font-size: 16px;

$global-baseline: 12px;
$global-column-cap: 20px;
$global-padding-horizontal-xl: 3 * $global-column-cap;

/* Z-index defaults */
$zindex1: 10;
$zindex2: 20;
$zindex6: 60;

$zindex-dropdowns: $zindex2;
