@import '../../common/style/styleguide';

.of-subscription-details {
  #simCardConfigurationExisting-0 {
    .ea-icon--info-nega {
      font-size: 24px; // Overrides normal size by -1 px just to preserve single line
    }
  }
  & .ea-h2 {
    padding-top: 6px; // Added spacing for multiline subscription names
    padding-bottom: 6px; // Added spacing for multiline subscription names
  }

  &__content--device {
    .of-subscription-details__description {
      margin-left: 0;
    }
  }

  &__content--broadband .ea-composite-field-grid,
  &__content--service .ea-composite-field-grid {
    padding-left: $ds-baseline * 2;
  }
  &__content--service h5 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  &__actions {
    .ds-button,
    .ds-button__content {
      font-family: 'Open Sans', sans-serif;
      font-size: ($ds-baseline * 2);
      text-transform: unset;
      padding-left: 0;
      border: 0;
      background-color: $ds-color-_white;
      text-align: left;
      &:hover {
        background-color: $ds-color__white;
      }
      line-height: ($ds-baseline * 2.5);
      .ds-icon {
        padding-right: 5px;
      }
    }

    padding: (3 * $global-baseline) 0 (3 * $global-baseline);

    a {
      display: inline-block;
    }

    @media screen and (max-width: $breakpoint-s-max-width) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (min-width: $breakpoint-s-max-width) and (max-width: $breakpoint-l) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (min-width: $breakpoint-l) {
      display: inline-flex;
      flex-direction: row;
    }
  }

  &__add-on-list {
    &__line-thin {
      border-top: $global-border-normal solid $ds-color__neutral-300;
    }
    &__link {
      color: $ea-color-blue;
    }
  }
  .ea-combobox__input {
    cursor: pointer;
  }
  .of-linkable-accordion:first-of-type {
    margin-top: 4px;
    border-top: 1px solid #e8e8ea;
  }
  /* Tooltip is not visible if overflow is hidden */
  .of-linkable-accordion > section,
  .of-linkable-accordion > section div {
    overflow: visible;
  }
  .of-vertical-bar {
    color: $ea-color-smoke;
    font-weight: 600;
    position: relative;
    top: -3px;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .of-request-approval__action-buttons {
    margin-top: $global-baseline * 1.5;
    display: flex;
    flex-flow: row-reverse;
    flex: 0 1;

    &--approve {
      padding: 9px $global-baseline * 2;
    }
    &--deny {
      margin-right: $global-baseline * 3;
    }
  }
  &__company-info-header {
    padding-top: 50px;
    font-weight: 600;
  }
  &__employee-invitation-link {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #ring-subscription-user {
    .ds-contentWrapper {
      > div {
        padding-bottom: 3 * $global-baseline;
        padding-left: $global-baseline;
        padding-top: 3 * $ds-baseline;

        > .ea-composite-field-grid + div {
          padding-top: 3 * $global-baseline;
        }
      }
    }
  }

  #user-or-use-purpose {
    .ds-contentWrapper {
      > div {
        padding-bottom: 3 * $global-baseline;
      }
    }
  }

  #additional-services .of-toggle-checkbox label.ds-checkbox__label {
    font-weight: normal;
  }

  #additional-services .of-toggle-checkbox .of-toggle-checkbox__priceinfo {
    font-weight: normal !important;
  }

  button.edit-in-classic {
    white-space: nowrap;
    @include breakpoint(laptop-and-up) {
      width: 100%;
    }
  }
}
