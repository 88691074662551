@import '../../common/style/styleguide';

/* Minimum height 40px (as per Apple's documentation), 7.6mm (as per Google's documentation, no idea what that means) */
$badgeHeight: 40px;
$badgeVerticalMargin: 10px;

.of-app-store-badges {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  // 25px is from design
  gap: $badgeVerticalMargin 25px;
  margin: $badgeVerticalMargin 0;
  min-height: $badgeHeight;

  > a {
    border-radius: 5px;
    height: $badgeHeight;
    outline: none;

    &:focus-visible {
      box-shadow: none;
      outline: 3px solid $ds-color__blue-600;
      outline-offset: 1px;
    }
  }
}
