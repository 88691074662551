@import '../../common/style/styleguide';

.of-manage-catalog-draft {
  margin-top: 0;
  margin-bottom: $ds-baseline * 3;

  .ea-context-notification__text {
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__instructions {
    flex: 1 0 50%;
  }

  button:nth-child(n + 1) {
    margin: 1rem 1rem 1rem 0;
  }
}
