@import '../../../common/style/styleguide';

#of-number-chain-row-offWorkReachabilityChain {
  border-top: none;
  margin-top: -1.5 * $ds-baseline;
}

.of-number-chain {
  margin-top: 1.5 * $ds-baseline;

  &:not(.of-number-chain--editing) {
    padding-top: $global-baseline;
    @include breakpoint(tablet-and-up) {
      padding-top: 0;
    }
  }

  &__row {
    display: flex;
    border-bottom: 1px $ds-color--neutral-300 solid;
    border-top: 1px $ds-color--neutral-300 solid;
    flex-wrap: wrap;
    padding-top: 1.5 * $ds-baseline;

    &--special-number {
      border: none;
      padding-top: 0;
    }
  }

  &__title-row {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;

    .of-number-chain__column {
      display: none;
    }

    @include breakpoint(laptop-and-up) {
      border-bottom: 1px $ds-color--neutral-300 solid;
      margin-bottom: $ds-baseline * 3;
      .of-number-chain__column {
        display: block;
      }
    }
  }

  &__column {
    width: 100%;
    @include breakpoint(tablet-and-up) {
      width: 25%;
    }
    @include breakpoint(laptop-and-up) {
      width: 20%;
    }
    &--header {
      align-items: center;
      display: flex;
      width: 100%;
      @include breakpoint(laptop-and-up) {
        width: 20%;
      }
    }
    &--edit {
      @include breakpoint(tablet-and-up) {
        width: 25%;
      }

      .ds-input--labelarea label {
        color: $ds-color--neutral-600;
        font-size: 0.8 * $ds-base-font-size;
        font-weight: normal;
      }
    }
    &--bold {
      font-weight: 600;
    }
    &--fullwidth {
      width: 100%;
    }
    &--special-number {
      border-bottom: 1px $ds-color--neutral-300 solid;
      width: 100%;

      &:first-of-type {
        border-top: 1px $ds-color--neutral-300 solid;
      }
      @include breakpoint(tablet-and-up) {
        &:not(:nth-child(n + 5)) {
          border-top: 1px $ds-color--neutral-300 solid;
        }
        width: 25%;
      }
    }

    &:not(.of-number-chain__column--header) + .of-number-chain__column > .of-number-chain__mobile-edit > .ds-input {
      @include breakpoint(tablet-and-up) {
        padding-left: 20px;
      }
    }
  }

  &__generic-error {
    color: $ds-color--error;
  }

  &__generic-errors {
    padding-bottom: 1.5 * $ds-baseline;
  }

  &__mobile-edit {
    padding-bottom: 1.5 * $ds-baseline;
  }

  &__mobile-title {
    font-size: 0.8 * $ds-base-font-size;
    color: $ds-color--neutral-600;

    &--special-number {
      display: block;
    }
  }
}
