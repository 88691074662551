@import '../../common/style/styleguide';

.of-authorization-successful-scene {
  text-align: center;

  &__thumb-up {
    background-color: transparent;
    background-image: url('../../common/svg/thumb-up.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 60px;
    width: 60px;
  }
}
