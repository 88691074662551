@import '../../common/style/styleguide';

.of-device-checkout-personal-billing-details {
  padding-bottom: $global-baseline;
  padding-top: $global-baseline;

  &__actions {
    &--edit {
      padding-top: $global-baseline;
    }
  }
}
