@import '@design-system/component-library/src/globals/scss/variables';
@import '@design-system/component-library/src/globals/scss/_variables.color-map';

.ls-category-menu {
  display: block;

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    align-items: center;

    @media only screen and (min-width: 640px) {
      border-bottom: none;
    }
  }

  &__item {
    display: block;
    flex: 0 0 50%;
    align-content: center;
    line-height: 12px * 6;
    cursor: pointer;
    color: #00239d;

    @media only screen and (min-width: 640px) and (max-width: 959px) {
      flex-basis: 33.3%;
    }

    @media only screen and (min-width: 960px) {
      border: none;
      text-align: center;
      flex: 1 0 auto;
      min-width: 0;
    }

    @media only screen and (min-width: 960px) {
      &:last-child {
        border-right: none;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;

    &.ds-icon {
      &--color {
        @each $name, $color in $ds-colors {
          &-#{$name} {
            color: #{$color};
          }
        }
      }
    }
    &:hover {
      color: #00baf2;
      span {
        color: #00baf2;
      }
      .ds-icon {
        &--color {
          @each $name, $color in $ds-colors {
            &-#{$name} {
              background-color: #00baf2;
            }
          }
        }
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }

    .ds-icon {
      &--color {
        @each $name, $color in $ds-colors {
          &-#{$name} {
            background-color: #{$color};
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  &--icon-container {
    text-align: center;
    text-align: -webkit-center;
    @media only screen and (min-width: 960px) {
      position: absolute;
      text-align: inherit;
    }
  }

  &__icon {
    display: block;
    text-align: center;
    line-height: 50px;
    padding-bottom: 12px / 2;
    transition: color 0.3s ease;
    margin: 0 auto;

    path {
      transition: fill 0.3s ease;
    }

    @media only screen and (max-width: 1399px) {
      height: auto;
    }

    @media only screen and (min-width: 1400px) {
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin: 0 auto;
    }
  }

  &__text {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    transition: color 0.3s ease;

    &:focus {
      outline: none;
    }

    @media only screen and (min-width: 960px) {
      max-width: calc(100% - 55px);
      padding-left: 10px;
      line-height: 20px;
    }

    @media only screen and (min-width: 640px) and (max-width: 959px) {
      padding: 0;
      display: block;
      font-size: 16px;
    }
  }
}
