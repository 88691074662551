@import '../../common/style/styleguide';

.of-mobile-id-addon {
  &__button {
    flex-direction: column;
    display: flex;
    align-items: center;
    &__text {
      font-size: $ds-base-font-size * 0.7;
      color: $ds-color__neutral-900;
    }
  }
}
