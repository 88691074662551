@import '../../common/style/styleguide';

.of-select-phone-number {
  &__transfer-date-input {
    .ds-input--inputarea {
      max-width: 159px;
    }
  }

  &__existing {
    @include breakpoint(laptop-and-up) {
      max-width: 50%;
      padding-right: 6%;
    }
  }

  .ds-calendar {
    z-index: 2;
  }
}
