@import '../../common/style/styleguide';

.of-sim-activation {
  &__sim-type {
    @include breakpoint(phone) {
      // The select items get too narrow without this.
      .ds-selectgroup__list {
        grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
      }
    }
  }

  li {
    p {
      font-weight: 600;
      font-size: 2rem;
    }
  }
}
