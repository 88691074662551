@import '../../common/style/styleguide';

.of-campaign-voucher {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: $global-baseline;

  &__container {
    flex: 1;

    .ds-disclaimer {
      padding: calc($global-baseline / 2) $global-baseline;
      display: inline-flex;
      align-items: center;
      border: 0 !important;
    }
  }

  @include breakpoint(phone-large-and-up) {
    &__container {
      flex: none;
    }
  }

  &__form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $global-baseline;
    justify-content: space-around;

    & > div {
      width: 100%;
    }

    #voucherButton {
      margin-bottom: $global-baseline;
      width: 100%;
    }

    .ds-input {
      width: 100%;
      margin-bottom: $global-baseline;

      // Avoid layout shift when error is made visible
      &.ds-input-error {
        margin-bottom: 0;
      }
    }

    @include breakpoint(phone-large-and-up) {
      flex-direction: row;
      gap: 0;
      justify-content: flex-start;

      #voucherButton {
        margin-left: 16px;
        margin-top: 28px;
        width: auto;
      }

      .ds-input {
        min-width: 340px;
        width: auto;
      }
    }

    label {
      font-weight: bold;
    }
  }
}
