@import '../../common/style/styleguide';

// TODO get rid of the grid version, it's too janky to use with
//   different card amounts.
.of-subscription-cards {
  display: inline-grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4 * $ds-baseline 2 * $ds-baseline;
  padding: 2 * $ds-baseline $ds-baseline;

  .ds-card {
    height: 100%;
  }

  .of-subscription-card--card-wrapper {
    width: 100%;
  }

  // These arbitrary breakpoints are here so that we can keep the subscription
  // cards as readable as possible.
  @include breakpoint(phone-large-and-up) {
    .of-subscription-card {
      // If you change this, make sure the english version looks ok (it usually has longer text)
      max-width: 400px;
    }
  }

  @media screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 840px) {
    padding: $ds-baseline 0 2 * $ds-baseline 0;
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 2 * $ds-baseline 0 6 * $ds-baseline 0;
  }
}

.of-subscription-cards > div {
  display: flex;
}

.of-subscription-cards.of-subscription-cards--flex {
  display: flex;
  flex-wrap: wrap;
  gap: 4 * $ds-baseline 2 * $ds-baseline;
  justify-content: center;
  padding: 2 * $ds-baseline 0;

  .ds-card {
    flex-basis: 1px;
    flex-grow: 1;
    height: 100%;
  }
}
