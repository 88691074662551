@import '../../common/style/styleguide';

.of-copy-catalog-multi-business-dialog {
  &__content {
    > div:first-child {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;
    }

    > .ea-text--error {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;
    }

    .ea-disclaimertext {
      padding-top: $global-baseline;
    }
  }
}
