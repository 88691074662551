@import '../../common/style/styleguide';

.of-small-header {
  .ds-local-navigation-section {
    &:first-child,
    &:nth-child(3) {
      // Icon width
      flex-basis: 32px;
      flex-grow: 1;
    }

    .ds-logo-and-service {
      margin-right: 0;
    }
  }

  &__button {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;

    &-text {
      // This adds some space between the text and focus outline
      padding-right: $ds-baseline;
    }
  }
}

@include breakpoint(phone-large-and-down) {
  .of-small-header {
    &__button {
      &-text {
        display: none;
      }
    }
  }
}
