@import '../../breakpoints.scss';
.ds-reset .ds-badge--secondary {
      background: #deedff;
      color: #171c43;
      padding: 10px 10px;
      margin: 4px;
    }
    .ds-reset .ds-badge--text {
      padding: 12px 12px;
    }
    .ds-badge--secondary a{
      color: #171c43;
    }

    .react-multi-carousel-list {
      list-style-type: none;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
    }
    .react-multi-carousel-track {
      list-style-type: none !important;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }
    .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      background: rgba(0, 0, 0, 0.5);
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
      &::before {
        font-size: 20px;
        color: #fff;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative;
      }
      &:disabled {
        cursor: default;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .react-multiple-carousel__arrow--left {
      left: calc(4% + 1px);
      &::before {
        content: '\e824';
      }
    }
    .react-multiple-carousel__arrow--right {
      right: calc(4% + 1px);
      &::before {
        content: '\e825';
      }
    }
    .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 0;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .react-multi-carousel-dot {
      button {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 1;
        padding: 5px 5px 5px 5px;
        box-shadow: none;
        transition: background 0.5s;
        border-width: 2px;
        border-style: solid;
        border-color: grey;
        padding: 0;
        margin: 0;
        margin-right: 6px;
        outline: 0;
        cursor: pointer;
        &:hover {
          &:active {
            background: #080808;
          }
        }
      }
    }
    .react-multi-carousel-dot--active {
      button {
        background: #080808;
      }
    }

    .scroll-image {
        .ds-picture img {
          height: 300px;
          @include breakpoint(tablet-and-up) {
          padding-right: 20px;
        }
      }
    }
    .scroll-text {
      .react-multi-carousel-item {
        width: fit-content!important;
        background: #deedff;
        margin-right: 15px;
      }
    }
    .react-multi-carousel-item {
      width: fit-content;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      cursor: pointer;
      text-align: center;
      webkit-transition: -webkit-box-shadow 0.2s;
      transition: -webkit-box-shadow 0.2s;
      transition: box-shadow 0.2s;
      transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
      min-width: var(--slider-item-min-width, 100px);
      white-space: nowrap;
      width: var(--slider-item-width, 25%);

      &:hover {
        //box-shadow: 0px 0px 10px rgba(33, 33, 33, 0.2);
      }
    }
    @media all and (-ms-high-contrast: none) {
      .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }
      .react-multi-carousel-track {
        overflow: visible !important;
      }
    }
    @media (-ms-high-contrast: active) {
      .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }
      .react-multi-carousel-track {
        overflow: visible !important;
      }
    }

    .custom-left-arrow {
      position: absolute !important;
      left: 5px;
      outline: 0;
      transition: all .5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      background: rgba(0,0,0,0.5);
      min-width: 33px;
      min-height: 33px;
      opacity: 1;
      cursor: pointer;
    }
    .custom-right-arrow {
      position: absolute !important;
      right: 5px;
      outline: 0;
      transition: all .5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      background: rgba(0,0,0,0.5);
      min-width: 33px;
      min-height: 33px;
      opacity: 1;
      cursor: pointer;
    }

    .scroll-desktop {
      @include breakpoint(tablet-and-down) {
        display: none;
      }
      span{
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-right: 20px;
      }
    }

    .scroll-mobile {
      @include breakpoint(laptop-and-up) {
        display: none;
      }
    }

