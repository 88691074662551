@import '../../common/style/styleguide';

.of-attach-pbx-content-confirmation-dialog {
  &__bold {
    font-weight: 600;
  }
  &__price-label,
  &__price-value {
    border-top: 1px solid $ea-color-smoke;
  }
  &__price-value {
    text-align: right;
    font-weight: 600;
  }
  &__price-label-no-border,
  &__price-value-no-border {
    border-top: 0;
    border-bottom: 1px solid $ea-color-smoke;
    padding-top: 0;
  }
  &__price-value-no-border {
    text-align: right;
    font-weight: 600;
    padding-top: 0;
  }
}
