@import '../../common/style/styleguide';

.of-invoice-details {
  &__accounts {
    small,
    span {
      display: block;
    }
    small {
      padding-top: 1.5 * $ds-baseline;
      line-height: 15px;
    }
  }

  &__actions {
    padding-bottom: 1.5 * $ds-baseline;
    padding-top: 6 * $ds-baseline;

    a {
      display: inline-block;
    }
    button,
    a {
      margin-bottom: 1.5 * $ds-baseline;
      margin-right: 2.75em;
      text-align: left;
    }
    .ea-icon {
      margin-right: 1.25rem;
      vertical-align: middle;
    }
  }

  .of-open-cases-notification {
    min-width: 35 * $ds-baseline;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 4px;
  }
}
