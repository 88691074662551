@import '../../common/style/styleguide';

.of-employee-home-scene {
  .of-employee-home-scene-content {
    margin: $global-baseline;
  }

  .of-hero-heading {
    height: 248px;
  }

  .of-employee-home-scene-card-icon {
    height: 64px;
    width: 64px;
  }

  .of-employee-subscription-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8ea;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f5;
    }

    &:active {
      background-color: #e0e0eb;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .of-employee-subscription-row-details {
    flex-grow: 1;
    padding-left: 10px;
  }

  .of-employee-subscription-row-name {
    color: #005fed;
    text-decoration: underline;
  }

  .of-employee-subscription-row-details-text {
    font-size: 14px;
    color: #666;
    margin: 0;
  }

  .of-employee-contract-warning {
    color: #ffaf38;
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .of-employee-subscription-row-icon {
    width: 40px;
    height: 40px;
  }

  .of-employee-home-scene-subheading {
    justify-content: center;
    display: flex;
    padding-top: $global-baseline;
  }

  .of-employee-home-scene-card {
    margin-top: $global-baseline;
  }

  .ea-picto {
    background-size: 110px 120px;
    display: inline-block;
    margin-right: 4rem;
    width: 110px;
    margin-top: 4px;

    &--broadband {
      height: 93px;
      background: transparent url('../../common/svg/picto-broadband.svg') left top no-repeat;
    }
    &--sim {
      height: 93px;
      background: transparent url('../../common/svg/picto-sim.svg') left top no-repeat;
    }
    &--generic-service-filled {
      height: 93px;
      background: transparent url('../../common/svg/picto-generic-service.svg') left top no-repeat;
    }
    &--phone {
      height: 93px;
      background: transparent url('../../common/svg/picto-phone.svg') center top no-repeat;
    }
    &--laptop-filled {
      height: 93px;
      background: transparent url('../../common/svg/picto-laptop.svg') left top no-repeat;
    }
    &--epp {
      height: 93px;
      background: transparent url('../../common/svg/picto-epp.svg') center top no-repeat;
    }
    &--maintenance {
      height: 93px;
      background: transparent url('../../common/svg/picto-maintenance.svg') center top no-repeat;
    }
    &--money {
      height: 93px;
      background: transparent url('../../common/svg/picto-money.svg') center top no-repeat;
    }
    &--puk-code {
      height: 93px;
      background: transparent url('../../common/svg/picto-puk-code.svg') center top no-repeat;
    }
    &--magnifier {
      height: 93px;
      background: transparent url('../../common/svg/picto-magnifier.svg') center top no-repeat;
    }
  }
}
