.of-local-navi {
  .ds-icon {
    bottom: -3px;
    margin-left: 8px;
  }
  &--item,
  &--item:hover {
    color: inherit;
    display: block;
    padding: 14px 0;
  }
  &--item:not(&--item__selected):hover {
    padding-bottom: 12px;
  }
  button.ds-tab-item,
  button.ds-tab-item:hover {
    padding: 0;
  }
}
