@import '../../common/style/styleguide';

.of-contact-support-container {
  display: flex;
  cursor: pointer;
  color: $ea-color-black;

  &:hover {
    color: $ea-color-blue;
  }

  & > div {
    flex: 0 0 50%;
  }

  .of-contact-support-text {
    align-items: center;
    border: 1px solid #e8e8ea;
    display: flex;
    justify-content: center;
    text-align: center;

    > div {
      > div:nth-child(2) {
        padding-bottom: 3 * $global-baseline;
      }
    }
  }

  .of-contact-support-background img {
    object-fit: cover;
    object-position: 100% 0;
    width: 100%;
    height: 100%;
  }
}
