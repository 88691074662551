@import '../../common/style/styleguide';

.of-offer-badge {
  .ds-badge {
    &:not(.ds-badge--status):not(.ds-badge--numeric) {
      @include breakpoint(desktop) {
        padding: $global-baseline;
      }
    }
  }
}
