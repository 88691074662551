@import '../../common/style/styleguide';

.of-product-details {
  margin: 0 5% $global-baseline * 3 5%;

  &__header {
    line-height: 48px;
  }

  .square-container {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 2px;
  }

  .square-container__wrapper {
    text-align: center;
    position: absolute;
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
  }

  .of-product-details-offer .ds-dropdown__button .ds-icon {
    top: 50%;
  }
}
