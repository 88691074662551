@import '../../common/style/styleguide';

.terminate-subscription-form {
  padding-bottom: $global-baseline * 5;

  &__donate-number-information {
    background-color: $ds-color-_neutral-200--light;
    border: 1px solid $ds-color-_neutral-300;
    border-radius: 2px 0;
    padding: $global-baseline 30px;
    margin-bottom: $global-baseline * 3;
  }

  .ds-checkbox {
    margin-top: $global-baseline * 4;
    margin-bottom: $global-baseline * 3;
  }

  input {
    max-width: 150px;
  }

  &__actions {
    margin-top: $global-baseline * 5;
    text-align: right;
  }

  .ds-calendar {
    z-index: 1000;
  }
}
