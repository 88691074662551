@import '../../common/style/styleguide';

.of-device-checkout-cart-product__redeem-or-return-wrapper {
  margin-bottom: 1.5 * $ds-baseline;

  h4 {
    margin: 0;
    padding-bottom: 1.5 * $ds-baseline;
  }

  .information-message {
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
    padding-bottom: 2.25 * $ds-baseline;
  }

  .of-device-checkout-delivery-mode {
    .of-device-checkout-cart-product__redeem-or-return {
      &-disclaimer {
        padding-bottom: 3 * $ds-baseline;
        padding-top: 1.5 * $ds-baseline;
      }

      &-information {
        margin-top: -2 * $global-baseline;
        margin-bottom: 2 * $global-baseline;
        color: $ds-color__neutral-600;
      }
    }
  }
}
