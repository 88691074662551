@import '../../common/style/styleguide';

.of-change-offer-dialog {
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $ds-baseline 0;
    border-top: 1px solid $ds-color__neutral-300;
  }
  .disclaimer {
    font-size: 13px;
  }
}
