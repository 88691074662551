@import '../../common/style/styleguide';

.of-addon-management-buttons {
  margin: $ds-baseline * 4 0;
  &__view {
    display: flex;
    justify-content: flex-end;
  }
  &__edit {
    display: flex;
    justify-content: flex-start;
  }
}
