.ca-containers {
  .ca-heading-link {
    color: #404041;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 2 * 12px;
    font-family: 'Jost Medium', sans-serif;
    letter-spacing: 0.5px;
  }

  .ca-thumbnail-link {
    line-height: 0;
  }

  .ca-youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .ca-youtube-container .ca-youtube-container__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ca-facebook-container {
    background-color: #dfe3ef;
    text-align: center;
    overflow: hidden;
    line-height: 0;
  }

  .ca-instagram-image {
    width: 100%;
  }
}
