.of-contact-channels-info-card {
  h3 {
    margin: 0;
    padding: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 8px;
  }
}
