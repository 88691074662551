@import '../../common/style/styleguide';

.of-public-pages-search-metadata {
  margin-bottom: 4 * $ds-baseline;

  &-suggestion {
    margin-bottom: 2 * $ds-baseline;

    a {
      font-style: italic;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
