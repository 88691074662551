$videra-green: rgb(45, 168, 79);


//COPIED FROM PL (can be removed if include pl as dep in this repo):

$ea-color-yellow: #ffd503;
$ea-color-green: #00a950;
$ea-color-error: #ee4444;
$ea-color-blue: #0019af;
$ea-color-orange: #f66000;
$ea-color-black: #000000;
$ea-color-white: #ffffff;
$ea-color-coal-grey: #303031;
$ea-color-dark-grey: #404041;
$ea-color-grey: #767677;
$ea-color-light-grey: #b0b0b1;
$ea-color-dark-smoke: #d0d0d1;
$ea-color-smoke: #e0e0e1;
$ea-color-light-smoke: #fafafa;
$ea-color-white-smoke: #f5f5f5;
$ea-color-linkblue: #0068F0;
$ea-color-dark-blue: #002447;

$global-baseline: 12px;
$global-border-normal: 1px;
$global-border-thick: 2px;
$global-border-focus: 3px;
$global-font-icon: 'Elisaicons';


