@import '../../common/style/styleguide';

.of-employee {
  .ea-profile-content .ea-profile-links {
    padding: ($global-baseline * 2) ($global-baseline * 2) ($global-baseline * 1.5) ($global-baseline * 2);
  }

  &__form-container {
    background-color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 1024px;
    width: 510px;

    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }

  &__guide-notification-container {
    margin: auto;
    max-width: 800px;
    padding-bottom: 2 * $global-baseline;
    padding-top: $global-baseline;
    width: 100%;

    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }

  &__form-column {
    box-sizing: border-box;
    flex: 0 1 auto;
    padding: 46px;
  }

  &__form-error {
    color: red;
  }

  &__employee-portal-home {
    border-bottom: $global-border-normal solid $ea-color-smoke;

    @include breakpoint(tablet-and-up) {
      background: bottom/cover url('../../common/svg/art-employee-login.svg') no-repeat;
      height: 100%;
      margin: 0;
      padding-bottom: 5%;
      padding-top: 5%;
      width: 100%;
    }

    .eid-button.eid-button-transparent {
      & .icon {
        line-height: unset;
        vertical-align: unset;
      }
    }
  }

  &__marketing-block {
    flex-direction: column-reverse;
    @include breakpoint(tablet-and-up) {
      flex-direction: row;
    }

    &__bodytext {
      margin-top: 0;
    }
  }

  .ea-fgrid__item {
    overflow: visible;
  }

  .of-icon--exclamation-mark {
    background: url('../../common/svg/icon-exclamation-mark.svg') no-repeat;
  }

  .of-empty-or-error {
    padding-bottom: $global-baseline * 10;
  }
}

.of-site--employee {
  padding-bottom: $global-baseline * 10;

  .ea-header {
    position: relative;

    &__panel {
      bottom: auto;
      top: auto;
    }

    .of-user-options {
      margin-left: 2.5%;
    }
  }
}
