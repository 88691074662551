@import '../../common/style/styleguide';

.ea-composite-list-top {
  padding-top: $global-baseline * 2;
  padding-bottom: $global-baseline * 2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    flex-basis: 66%;
  }

  &__right {
    margin-left: $global-baseline;
    text-align: right;
    flex-basis: 33%;

    &-link {
      color: $ea-color-blue;
      cursor: pointer;

      .ea-icon--arrow-right:before {
        font-size: 18px;
        margin-left: 0.25rem;
        vertical-align: bottom;
        line-height: $global-baseline * 2;
      }
    }

    & > *:not(:first-child) {
      margin-left: $global-baseline;
    }
  }

  &__column {
    white-space: nowrap;

    &:only-child {
      flex-basis: 100%;
      white-space: normal;
    }
  }

  &--right-hide-on-mobile {
    .ea-composite-list-top__left {
      white-space: normal;
      flex-basis: 100%;
      @include breakpoint(tablet-and-up) {
        flex-basis: 33%;
      }
    }
  }

  &--wrap-on-mobile {
    @include breakpoint(phone) {
      flex-wrap: wrap;
      .ea-composite-list-top__left {
        width: 100%;
        flex-basis: 100%;

        .ea-composite-list-search {
          max-width: unset;
        }
      }
      .ea-composite-list-top__right {
        margin: $global-baseline * 1.5 auto 0 auto;
        display: flex;
        justify-content: space-around;
        flex-basis: 100%;
      }
    }
  }
}
