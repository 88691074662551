.of-header-notification {
  position: initial;

  .of-site > & {
    flex-grow: 0;
  }

  // overwrite
  .of-notification-container {
    position: initial;
  }
}
