@import '../breakpoints.scss';

.notification {

  .notification-container {
    display: block;
    margin: 0 auto;
    max-width: 1400px;
    height: 100%;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(phone-large-and-down) {
      flex-flow:column nowrap;
    }
  }

  .flex-item--Padding {
    padding-left: 20px;
    padding-right: 20px;
    @include breakpoint(tablet) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include breakpoint(laptop-and-up) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  .flex-item--left {
    flex: 10%;
    @include breakpoint(tablet-and-down) {
      flex: 20%;
    }
  }

  .flex-item--right {
    flex: 90%;
    @include breakpoint(tablet-and-down) {
      flex: 80%;
    }
  }

  .flex-item--center {
    display: flex;
    justify-content: center;
    padding-top: 4px;
  }

  .flex-item--border {
    padding: 25px;
    border-radius: 10px;
  }

  @mixin setColor($bgcolor , $color, $border) {
    background : $bgcolor;
    border: $border dashed $color;
  }

  .background {
    &-green {
      @include setColor(#00887A05, #00887A, 2px);
      &--default {
        @include setColor(#00887A05, #00887A, 2px);
      }
      &--thin-dashed {
        @include setColor(#00887A05, #00887A, 1px);
      }
      &--no-border {
        @include setColor(#00887A05, #00887A, 0px);
      }
      .ds-icon {
        background-color: #00887A;
      }
    }
    &-blue {
      @include setColor(#00239D05, #00239D, 2px);
      &--default {
        @include setColor(#00239D05, #00239D, 2px);
      }
      &--thin-dashed {
        @include setColor(#00239D05, #00239D, 1px);
      }
      &--no-border {
        @include setColor(#00239D05, #00239D, 0px);
      }
      .ds-icon {
        background-color: #00239D;
      }
    }
    &-red {
      @include setColor(#E9566F05, #E9566F, 2px);
      &--default {
        @include setColor(#E9566F05, #E9566F, 2px);
      }
      &--thin-dashed {
        @include setColor(#E9566F05, #E9566F, 1px);
      }
      &--no-border {
        @include setColor(#E9566F05, #E9566F, 0px);
      }
      .ds-icon {
        background-color: #E9566F;
      }
    }
    &-neutral-100 {
      @include setColor(#FAFAFB, #70707A, 2px);
      &--thin-dashed {
        @include setColor(#FAFAFB, #70707A, 1px);
      }
      &--no-border {
        @include setColor(#FAFAFB, #70707A, 0px);
      }
      &--default {
        @include setColor(#FAFAFB, #70707A, 0px);
        border-radius: 0px;
        border-bottom: 1px solid #E8E8EA;
      }
      .ds-icon {
        background-color: #171C43;
      }
    }
    &-neutral-200 {
      @include setColor(#F6F6F7, #70707A, 2px);
      &--thin-dashed {
        @include setColor(#F6F6F7, #70707A, 1px);
      }
      &--no-border {
        @include setColor(#F6F6F7, #70707A, 0px);
      }
      &--default {
        @include setColor(#F6F6F7, #70707A, 0px);
        border-radius: 0px;
        border-bottom: 1px solid #D2D2D6;
      }
      .ds-icon {
        background-color: #171C43;
      }
    }
    &-neutral-300 {
      @include setColor(#E8E8EA, #70707A, 2px);
      &--thin-dashed {
        @include setColor(#E8E8EA, #70707A, 1px);
      }
      &--no-border {
        @include setColor(#E8E8EA, #70707A, 0px);
      }
      &--default {
        @include setColor(#E8E8EA, #70707A, 0px);
        border-radius: 0px;
        border-bottom: 1px solid #8D8D95;
      }
      .ds-icon {
        background-color: #171C43;
      }
    }
  }

  .padding-bottom {
    @include breakpoint(phone-large-and-down) {
      padding-bottom: 12px;
    }
  }
}
