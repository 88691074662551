@import '../../common/style/styleguide';

.of-messaging-center {
  .of-messaging-center-content {
    margin: 0 16px;
    max-width: 1400px;

    @media only screen and (min-width: $breakpoint-s) {
      margin: 0 48px;
    }

    @media only screen and (min-width: 1496px) {
      margin: 0 auto;
    }
  }

  .ds-button {
    width: 100%;

    @media only screen and (min-width: $breakpoint-m) {
      width: auto;
    }
  }

  .create-case-button {
    margin-bottom: $ds-baseline * 2;

    @media only screen and (min-width: $breakpoint-m) {
      display: flex;
      justify-content: flex-end;
    }

    @media only screen and (min-width: $breakpoint-l) {
      margin-bottom: 0;
    }
  }
}
