@import '../../common/style/styleguide';

.of-subscription-usage-table {
  margin-top: 0;
  margin-bottom: 5 * $ds-baseline;
  border-bottom: 1px solid $ds-color__neutral-400;

  &__subtitle {
    font-weight: $ds-base-font-bold__weight;
    padding-top: 2 * $ds-baseline;
  }

  & &__expandable-row th {
    border-top: 1px solid $ds-color__neutral-400;
    border-bottom: none;
  }

  & thead tr &__expand-icon {
    width: 6 * $ds-baseline;
  }

  & thead tr &__data-column {
    width: 27 * $ds-baseline;
    font-weight: $ds-title-font__weight;
  }
}
