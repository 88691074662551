.dots {
  display: flex;
  gap: 8px;

  .dot {
    width: 10px;
    height: 10px;
    margin: 7px 0;
    border-radius: 50%;
    background-color: transparent;
    animation: colorChange 3s infinite;
  }

  .dot:nth-child(1) {
    animation-delay: 0s, 0s;
  }

  .dot:nth-child(2) {
    animation-delay: 0.3s, 0.3s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.6s, 0.6s;
  }
}

@keyframes colorChange {
  0% {
    background-color: #40404a;
  }
  33% {
    background-color: #70707a;
  }
  66% {
    background-color: #d2d2d6;
  }
  100% {
    background-color: #40404a;
  }
}
