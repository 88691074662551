@import '../../common/style/styleguide';

.of-service-level-addons {
  .ea-table {
    .ds-checkbox {
      label {
        font-weight: 400 !important;
        display: flex;
      }
    }
  }
}
