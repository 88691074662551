@import '../../common/style/styleguide';

.of-fixed-bb-checkout {
  margin-top: 5%;

  .ea-fgrid {
    margin: 0;
    &__item {
      overflow: inherit;
    }
  }

  .ea-composite-field {
    &--tight {
      @include breakpoint(tablet-and-up) {
        input,
        .ea-combobox__input,
        .ea-combobox__options {
          width: 80%;
        }
        .ea-combobox__options {
          height: $global-baseline * 12;
        }
      }
    }

    &--hidden {
      display: none;
    }
  }
  .ea-composite-field-grid {
    &__title {
      font-size: $global-baseline * 1.5;
      font-weight: 600;
    }
  }

  &__heading {
    max-width: $legacy-pl-content-size-laptop;
    margin: 0 0 0 0px;

    @include breakpoint(laptop-and-down) {
      margin-left: 8px;
    }

    @include breakpoint(phone-large-and-down) {
      margin-left: 12px;
    }
  }

  &__product-header {
    &-image {
      @include breakpoint(tablet-and-up) {
        padding: 0 $global-baseline * 2;
      }
    }

    &-opening-fee {
      margin-top: -6px;
      margin-bottom: 6px;
    }

    &-price {
      @include breakpoint(tablet-and-up) {
        text-align: end;
      }
    }
  }

  &__company-info,
  &__installation-info {
    width: 100%;
    &--bordered {
      border: 1px solid $ea-color-dark-smoke;
      padding: $global-baseline * 1.5;
      overflow: inherit;

      h3 {
        margin: 0 0 18px 0;
      }
    }
  }
}
