@import '../../../common/style/styleguide';

.of-mfa-enable-confirmation {
  padding: $ds-baseline * 3;

  .picto-lock {
    margin: 0 0 $ds-baseline 0;
    height: $ds-baseline * 8;
    width: $ds-baseline * 8;
    background: transparent url('../../../common/svg/picto-lock.svg') left top no-repeat;
  }
}
