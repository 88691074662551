@import '../breakpoints.scss';

.small-cards {

  .one-card-mobile-view {
    @include breakpoint(phone-large-and-down) {
      display: block;
    }
  }

  .two-cards-mobile-view {
    @include breakpoint(phone-large-and-down) {
      .small-card {
        max-width: 50%;
      }
    }
  }

  .grid--full-width {
    @include breakpoint(laptop-and-up) {
      width: 100%;
    }
  }

  img{
    max-width: 100%;
    height: auto;
  }

  .row-gap {
    row-gap: 16px;
  }

  .card-height {
    height: 100%
  }

  .small-card {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .small-card__image-header {
    position: relative;
  }

  .small-card__image-header:before {
    display: block;
    content: "";
    height: 0;
    padding-top: 66.66667%;
  }

  .small-card__header-image {
    img {
      height: 100%;
      font-size: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      margin: 0 auto;
      width: initial;
      padding: 30px 0 0;
    }
  }

  @media only screen and (min-width: 640px) {
    .small-card__header-image {
      padding: 40px 0 0;
    }
  }

  .small-card__header-image--background {
    img {
      padding: 0;
      max-height: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}
