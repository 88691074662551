@import '../../common/style/styleguide';

.of-minute-based-subscriptions {
  margin: 0 8 * $ds-baseline 0 $ds-baseline;

  .ds-dropdown {
    max-width: 50 * $ds-baseline;
    margin-bottom: $ds-baseline;
  }
}
