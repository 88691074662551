.product-blocks {
  .desc-container {
    white-space: pre-line;
  }
  .ds-card-heading--no-padding:before {
    padding-top: 66.66% !important;
  }
  .custom-height-auto {
    height: auto;
  }
  .custom-width {
    width: 350px;
  }
  ul {
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
  }
}
