@import '../../common/style/styleguide';

.of-customer-order-list {
  .ds-table tr td {
    .ds-icon {
      display: inline-block;
      margin-right: 4px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .additional-field-content {
    font-size: 13px;
    line-height: 1.4em;
  }
}
