@import '../../common/style/styleguide';

.of-order-esim {
  &__button {
    text-align: left;
    text-transform: uppercase;
  }

  // Left margin is needed to keep the buttons from touching, but don't apply to first as it's not needed
  // and with narrow screen will cause the buttons to wrap unnecessarily.
  &__button:nth-child(n + 2) {
    margin-left: 2em;
  }

  &__info {
    border-bottom: $global-border-normal solid $ea-color-smoke;
    padding: 1em 0 1em;
  }

  &__actions {
    text-align: right;
    margin-top: 1em;
  }
}
