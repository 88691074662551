@import '../../../common/style/styleguide';

$of-color-error: #df1515;

.of-punchout-checkout-auth {
  .ds-icon svg {
    fill: $of-color-error;
  }

  &__error {
    margin-bottom: $global-baseline * 2;
  }
}
