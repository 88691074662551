@import '../../common/style/styleguide';

.of-label-with-tooltip {
  display: flex;
  position: relative;
  width: 100%;

  &__label {
    &--full {
      width: 100%;
    }
  }
}
