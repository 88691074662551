@import '../../common/style/styleguide';

.of-device-checkout-cart-product {
  padding: 3 * $ds-baseline 0;

  .of-link {
    cursor: pointer;
    text-decoration: none;
  }

  &__additional-field {
    width: 400px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
  }

  // this resembles ea-fgrid tablet-push-1
  .grid-push-1 {
    margin-left: 9.33%;
  }

  .ds-accordion__section,
  .ds-accordion__section__content {
    overflow: visible;
    height: unset !important;
  }

  .ds-accordion__section__content .ds-contentWrapper {
    padding: 0;
  }

  .bottom-row {
    text-align: left;
    margin-bottom: 0.75 * $ds-baseline;

    @include breakpoint(tablet-and-up) {
      margin-bottom: 0;

      .left-column {
        float: left;
      }
    }
  }

  &__addOn-details {
    border-top: 1px solid $ds-color__neutral-400;
    padding-bottom: 1.5 * $ds-baseline;
    padding-top: 1.5 * $ds-baseline;

    &--price {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--remove {
      padding-top: 1.5 * $ds-baseline;
    }
  }

  &__details {
    font-size: 13px;

    &--price {
      margin: 0;
      padding-bottom: $ds-baseline;

      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--disclaimer {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--quantity {
      font-weight: normal;
      @include breakpoint(tablet-and-up) {
        float: right;
      }
    }
  }

  .ds-quantity {
    @include breakpoint(tablet-and-up) {
      float: right;
    }
  }

  .of-enrollment-program-consent {
    .of-label-with-tooltip {
      width: auto;
    }
  }

  &__additional-detail {
    border: 1px solid $ds-color__neutral-400;
    margin-top: 3 * $ds-baseline;
    padding: $ds-baseline * 3 $ds-baseline * 3 $ds-baseline;

    @include breakpoint(tablet-and-up) {
      padding: 4.5 * $ds-baseline 4.5 * $ds-baseline 3 * $ds-baseline;
    }

    &-header {
      padding-bottom: 0.75 * $ds-baseline;
    }

    .of-sim-card-selection {
      padding-top: 0.75 * $ds-baseline;
    }
  }

  .of-sales-product-additional-parameters {
    > h5 {
      margin: 1.5 * $ds-baseline 0 $ds-baseline 0;
    }

    #number-privacy {
      padding-bottom: 1.5 * $ds-baseline;
      padding-top: 0.75 * $ds-baseline;
    }
  }
}
