@import '../../common/style/styleguide';

table.of-detailed-summary-table {
  margin-top: $ds-baseline;

  .of-detailed-summary-table__name {
    width: 100%;
  }

  tfoot tr {
    td,
    th {
      font-weight: inherit;
    }
  }

  tfoot tr:not(:first-child) > * {
    border-top: none;
  }

  td,
  th {
    > span[aria-hidden='true'] {
      display: none;
      font-weight: inherit;
    }

    &:not(.of-detailed-summary-table__name) {
      white-space: nowrap;
    }
  }

  @include breakpoint(phone-large-and-down) {
    display: flex;
    flex-direction: column;

    tr {
      td,
      th {
        padding-bottom: 0;
        padding-top: 0.3rem;

        &.of-detailed-summary-table__cell--no-value {
          display: none;
        }
      }
    }

    thead {
      display: none;
    }

    tbody,
    tfoot {
      border-top: solid 1px $ds-color__neutral-600;
      padding-bottom: 3 * $ds-baseline;

      td,
      th {
        // This is the highest specificity we can get to without trickery,
        // so let's just use !important...
        border-bottom: none !important;
        border-top: none !important;
      }
    }

    tfoot {
      tr th {
        font-weight: bold;
      }
    }

    tr {
      display: grid;
      grid-template-columns: auto min-content;
      padding-top: 3 * $ds-baseline;

      > .of-detailed-summary-table__monthlyCharge,
      > .of-detailed-summary-table__onetimeCharge {
        grid-column: 1/3;
        display: flex;
        justify-content: space-between;
      }

      > .of-detailed-summary-table__amount {
        text-align: right;
      }
    }

    td,
    th {
      &:not(.of-detailed-summary-table__name):not(.of-detailed-summary-table__amount) {
        > span[aria-hidden='true'] {
          display: block;
        }
      }
    }
  }
}
