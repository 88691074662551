@import '../../common/style/styleguide';

#of-public-pages-search-results {
  list-style-type: none;
}

.of-public-pages-search-results-buttons {
  .ds-button {
    margin-left: 2 * $ds-baseline;
  }

  @include breakpoint(phone) {
    display: flex;
    justify-content: center;

    .ds-button {
      margin-left: 0;
    }
  }
}
