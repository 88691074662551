.ea-h3--thick {
  font-weight: 600;
}
.ea-button {
  &.button-margin {
    margin: 10px;
  }
}

.text-box-container {
  .body-text {
    img, iframe {
      max-width: 100%;
      max-height: 100%;
    }
  }
}


