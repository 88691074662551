@import '../../common/style/styleguide';

.of-duplicate-contact-check {
  &__radios {
    &-radio {
      &-children {
        float: left;
        padding: 0 0 0 33px;
        margin: 0;
        opacity: 0.6;
      }

      &-compensator {
        width: 100%;
        display: flex;
      }
    }
  }
}
