@import '../breakpoints.scss';
@import '../variables.scss';

.multiColumnTextContainer {
  .resize-image img {
    max-width: 100%;
  }

  .row-gap {
    row-gap: 16px;
  }

  .card-height {
    height: 100%;
  }

  .card-width {
    width: 100%;
  }

  @include breakpoint(phone-large-and-down) {
    .ya-compress {
      padding-left: $global-baseline;
      padding-right: $global-baseline;
    }

    .ya-compress-wide {
      padding-left: $global-baseline;
      padding-right: $global-baseline;
    }

    .ya-compress-narrow {
      padding-left: $global-baseline;
      padding-right: $global-baseline;
    }
  }

  /** When using ya-compress options, there shouldn't be any padding on the edges.
      However, the padding to create the gutter between items should remain, so it's not enough to remove it altogether.
      The following classes are to target the items on the edge of the grid, depending on the number of columns (based on the props) as well as the breakpoints.
  **/

  // Reusable classes to handle padding based on breakpoints.
  .ya-padding-tablet-breakpoint {
    @include breakpoint(tablet) {
      &:nth-child(odd) {
        padding-left: 0;
      }
      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }

  .ya-padding-laptop-breakpoint {
    @include breakpoint(laptop) {
      &:nth-child(4n + 1) {
        padding-left: 0;
      }
      &:nth-child(4n) {
        padding-right: 0;
      }
    }
  }

  .ya-padding-desktop-breakpoint {
    @include breakpoint(desktop) {
      &:nth-child(6n + 1) {
        padding-left: 0;
      }
      &:nth-child(6n) {
        padding-right: 0;
      }
    }
  }

  // Handle padding based on number of columns.
  .ya-padding-1-col {
    padding-right: 0;
    padding-left: 0;
  }

  .ya-padding-2-col {
    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 0;
    }
  }

  .ya-padding-3-col {
    @extend .ya-padding-tablet-breakpoint;

    &:nth-child(3n + 1) {
      padding-left: 0;
    }
    &:nth-child(3n) {
      padding-right: 0;
    }
  }

  .ya-padding-4-col {
    @extend .ya-padding-tablet-breakpoint;

    &:nth-child(4n + 1) {
      padding-left: 0;
    }
    &:nth-child(4n) {
      padding-right: 0;
    }
  }

  .ya-padding-6-col {
    @extend .ya-padding-tablet-breakpoint;
    @extend .ya-padding-laptop-breakpoint;
    @extend .ya-padding-desktop-breakpoint;
  }
}
