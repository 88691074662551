@import '../../../common/style/styleguide';

.of-license-management-accounts {
  .ds-table tr td {
    > a {
      align-items: center;
      display: flex;
    }

    // External link icon
    .ds-icon {
      display: inline-block;
      margin-left: 8px;
      @media only screen and (min-width: $breakpoint-m) {
        padding-top: 3px;
      }

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}
