@import '../../common/style/styleguide';

.of-subscription-table {
  margin: 0 16px;
  max-width: 0.8 * $breakpoint-xl;

  &__icon-column:before {
    margin-right: 0;
  }

  &__hidden {
    display: none;
  }

  &__text-column {
    max-width: 21 * $global-column-cap;
    text-overflow: ellipsis;
    display: inline;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .ds-table tr td {
    > a {
      align-items: center;
      display: flex;

      .ea-icon {
        display: inline-block;
        margin-right: 8px;
      }
    }

    // External link icon
    .ds-icon {
      display: inline-block;
      margin-left: 8px;
      @media only screen and (min-width: $breakpoint-m) {
        padding-top: 3px;
      }

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-xs-max-width) {
  .of-subscription-table {
    margin: 0 48px;
  }
}

@media only screen and (min-width: $breakpoint-xl-max-width) {
  .of-subscription-table {
    margin: 0 auto;
  }
}
