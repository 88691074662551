.show-more-button {
  padding: 0 !important;
  border: 0 !important;
}

.expandable-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
