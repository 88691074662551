@import '../../common/style/styleguide';

.of-sim-card-selection {
  padding-top: 3 * $global-baseline;

  > .of-label-with-tooltip {
    padding-bottom: $global-baseline;
  }

  &__esim-info {
    font-size: 1.3rem;
  }
}
