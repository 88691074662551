@import '../../common/style/styleguide';

.of-catalog-product-summaries {
  > div {
    padding-bottom: 1.5 * $global-baseline;
    padding-top: $global-baseline;

    > .ea-fgrid {
      padding-bottom: $global-baseline;
    }
  }
}
