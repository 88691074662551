@import '../../common/style/styleguide';

aside.of-public-selfservice-banner.of-public-selfservice-banner {
  background: #{desaturate(lighten($ds-color__turquoise-400, 35%), 10%)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  gap: 2 * $ds-baseline;
  margin: 10 * $ds-baseline auto;
  max-width: min(100%, 1200px);
  padding: 0 4 * $ds-baseline 0 4 * $ds-baseline;

  > div:first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    text-align: center;

    > a {
      margin-bottom: 4 * $ds-baseline;
      white-space: nowrap;
    }
  }

  > div:nth-child(2) {
    flex-shrink: 0;
    height: 33vw;
    margin: -4vw auto 0 auto;
    overflow-y: hidden;

    img {
      width: 80vw;
    }
  }

  @media screen and (min-width: 900px) {
    flex-direction: row-reverse;
    gap: 4 * $ds-baseline;
    margin: 4 * $ds-baseline auto 4 * $ds-baseline auto;
    overflow-y: hidden;

    > div:first-child {
      gap: 0;
      padding-bottom: 2 * $ds-baseline;
      padding-top: 3 * $ds-baseline;
      text-align: left;

      > a {
        align-self: flex-end;
        margin-bottom: 2 * $ds-baseline;
      }
    }

    > div:nth-child(2) {
      $image-width: 400px;
      display: flex;
      flex-direction: column;
      height: auto;
      margin: 0;
      position: relative;
      width: $image-width;

      img {
        align-self: flex-end;
        bottom: -60%;
        position: absolute;
        width: $image-width;
      }
    }
  }

  @media screen and (min-width: 1140px) {
    height: 180px;

    > div:first-child {
      flex-direction: row;
      gap: 4 * $ds-baseline;

      p {
        max-width: 60ch;
      }
    }

    > div:nth-child(2) {
      > img {
        bottom: -75%;
      }
    }
  }
}
