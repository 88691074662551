@import '../../../common/style/styleguide';

.of-product-details-description {
  .ds-badge {
    line-height: 1.923em;
  }

  &__paragraph {
    margin: 1em 0;
  }

  &__title {
    display: inline-block;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-sale {
    background-color: #f66000;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-special {
    background-color: #0019af;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge--out_of_stock {
    background-color: #f5f5f5;
    color: #303031;
  }
}

@include breakpoint(phone-large-and-up) {
  .of-product-details-description {
    &__header {
      display: flex;
      flex-direction: column;
    }
    &__model-name {
      display: block !important;
      font-family: $ds-header-font-family--bold !important;
      font-size: 40px !important;
      line-height: 46px !important;
      margin-top: $global-baseline;
    }
  }
}
