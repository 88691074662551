.of-epp-destroyed-or-missing-device {
  hr {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__radio_button_title {
    font-size: 20px;
    font-weight: 600;
    margin-block-end: 0.5em;
  }

  &__description_text {
    p {
      padding-bottom: 20px;
    }
  }

  &__device_details {
    &-value {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      // !important is only only needed for testing purposes (storybooks and screenshot creation), without it padding
      // is ignored. This might get fixed in the future with storybook updates so it's safe to remove !important if
      // screenshots stays the same without it.
      padding-bottom: 30px !important;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
}
