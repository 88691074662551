.fgrid{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.fgrid .fgrid {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.fgrid__item {
  position: relative;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  max-width: 100%;
}
@media only screen and (min-width: 640px) {

  .fgrid__item--tablet-ratio-16-9 {
    display: block;
  }
  .fgrid__item--tablet-ratio-16-9:before {
    display: block;
    content: "";
    height: 0;
    padding-top: 56.25%;
  }
}

@media only screen and (min-width: 1400px) {
  .fgrid__item--desktop-ratio-5-2 {
    display: block;
  }
  .fgrid__item--desktop-ratio-5-2:before {
    display: block;
    content: "";
    height: 0;
    padding-top: 40%;
  }
}
