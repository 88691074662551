@import '../../common/style/styleguide';

.of-list-search {
  width: 100%;

  &__input {
    width: 100%;

    @media screen and (min-width: $breakpoint-m) {
      max-width: 370px;
    }

    input {
      // Ensure text doesn't go behind clear button
      padding-right: 48px;
    }
  }

  .ds-inputgroup__inputcontainer .ds-button {
    margin-top: 0;
    align-self: center;
  }

  .of-toggle-filters-button-container {
    margin-left: $ds-baseline;
  }

  .of-toggle-filters-button {
    display: none;

    @media screen and (min-width: $breakpoint-l) {
      display: block;
    }
  }

  .of-toggle-filters-button-mobile {
    display: block;

    @media screen and (min-width: $breakpoint-l) {
      display: none;
    }

    .ds-button {
      padding: 0;
      margin-left: 6px;
      background-color: transparent;
      border: unset;
      &:hover {
        background-color: unset;
      }
    }
  }
}
