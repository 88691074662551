@import '../../common/style/styleguide';

.of-employee-header {
  .ds-logo-and-service-logo {
    position: relative;
  }

  .ds-user-menu-person {
    padding: 16px !important;
  }

  .ds-notification-list {
    border-top: solid 1px $ea-color-smoke;
    padding: 16px !important;
  }

  .ds-logo-and-service-logo:before {
    content: '';
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 30%;
    background-color: $ea-color-smoke;
  }
}
