@import '../../common/style/styleguide';

.of-day-picker {
  min-height: 409px; // Magical number is DayPicker's height with 6 week rows
  text-align: center;

  &__navbar {
    position: relative;

    &-prev {
      cursor: pointer;
      position: absolute;
      left: 0;
    }

    &-next {
      cursor: pointer;
      position: absolute;
      right: 0;
    }
  }

  .DayPicker {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
  }

  .DayPicker-wrapper {
    &:focus {
      outline: 0;
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 0;
    border-spacing: 0;
    user-select: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    text-align: center;
    margin-bottom: 3 * $global-baseline;
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    color: $ea-color-dark-grey;
    text-align: center;
    font-size: 13px;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    width: 43px;
    height: 43px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    position: relative;
    color: $ea-color-linkblue;

    @include breakpoint(phone-large-and-up) {
      width: 57px;
      height: 57px;
    }

    &:focus {
      outline: 0;
      font-weight: 600;
    }

    &--today {
      // A bit of a hack to make the circle smaller
      &::after {
        content: '';
        border: 1px solid;
        border-radius: 100%;
        display: block;
        position: absolute;
        width: 33px;
        height: 33px;
        left: 5px;
        top: 5px;
        @include breakpoint(phone-large-and-up) {
          width: 39px;
          height: 39px;
          left: 9px;
          top: 9px;
        }
      }
    }

    &--selected {
      color: $ea-color-white;
      font-weight: 600;
      z-index: 0;
      // A bit of a hack to make the circle smaller
      &::after {
        content: '';
        border: 0;
        border-radius: 100%;
        background-color: $ea-color-linkblue;
        display: block;
        position: absolute;
        width: 33px;
        height: 33px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include breakpoint(phone-large-and-up) {
          width: 39px;
          height: 39px;
        }
        z-index: -1;
      }
    }

    &--outside {
      visibility: hidden;
    }

    &--disabled {
      color: $ea-color-light-grey;
      cursor: default;
    }

    .DayPicker--interactionDisabled {
      cursor: default;
    }
  }
}
