@import '../../common/style/styleguide';

.of-contact-support-form {
  .contact-info {
    font-size: 16px;
    border-bottom: 1px solid $ds-color__neutral-300;

    h4 {
      margin-top: 0;
    }

    .contact-info-details {
      display: flex;
      flex-wrap: wrap;

      div {
        flex: 100%;
      }

      @media screen and (min-width: $breakpoint-s) {
        gap: 0 24px;

        div {
          flex: calc(50% - 24px);
        }
      }
    }
  }

  .ea-input {
    &__label {
      font-weight: 700;
      color: $ds-color__secondary-blue-800;
    }
  }

  // These are copied from CompositeFieldGrid.scss
  .column {
    @include breakpoint(tablet) {
      &:nth-child(odd) {
        padding-right: 2.5%;
      }
      &:nth-child(even) {
        padding-left: 2.5%;
      }
    }
    @include breakpoint(laptop-and-up) {
      &:nth-child(odd) {
        padding-right: 6%;
      }
      &:nth-child(even) {
        padding-left: 6%;
      }
    }
    .ea-input {
      max-width: 100%;
      padding-bottom: 0;

      &__label {
        font-weight: 700;
      }
    }
  }

  .of-recaptcha {
    margin-top: 2 * $ds-baseline;
  }
}

.of-create-case-authenticated {
  .formik-input-description {
    margin-bottom: 0;
  }
}
