@import '../../common/style/styleguide';

.of-report-details {
  .ds-notificationbanner-content p {
    line-height: 2;
    display: block;
  }

  .ds-notificationbanner-buttons {
    margin-top: $ds-baseline * 2;
    flex: 1 0 !important;
    min-width: 200px;

    @media screen and (min-width: $breakpoint-m) {
      margin-top: 0;
      margin-left: $ds-baseline;
    }

    .buttons-content {
      align-items: center;
      .of-loading--p-t-4 {
        padding-top: 0;
      }
      .status-text {
        min-width: 150px;
        margin-left: 8px;
      }
    }
  }
}
