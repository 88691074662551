@import '../../common/style/styleguide';

#of-order-sim {
  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }
}
