@import '../../common/style/styleguide';

.of-user-details {
  // Pad globally the self-service area
  padding-bottom: $global-baseline * 10;

  .content {
    display: block;
    margin: 0 auto;
    max-width: 1400px;
    height: 100%;

    .for-margin {
      margin: 0 5%;
      flex-grow: 1;
      min-height: 100%;
    }
  }

  .of-icon--exclamation-mark {
    height: 28px;
    width: 28px;
    float: right;
  }

  &__check-info-message {
    p {
      margin: 0;
    }

    p:first-child {
      margin-top: $global-baseline !important;
    }
  }

  &--notification {
    p {
      margin: 0;
    }

    p + p {
      margin-top: 1em;
    }
  }

  /* Tooltip on phone number breaks if overflow is hidden in accordions */
  .of-linkable-accordion > section {
    overflow: visible;
    div {
      overflow: inherit;
    }
  }

  .ds-grid__row {
    width: 100%;
  }
}

@media only screen and (min-width: $breakpoint-l) {
  .of-user-details {
    .content {
      .for-desktop-margin {
        margin-left: 8.33%;
      }
    }
  }
}
