@import '../../../../common/style/styleguide';

.of-create-dns-record-form-grid {
  display: grid;
  grid-template-columns: auto 150px minmax(300px, auto) 100px min-content;
  gap: 8px;

  @include breakpoint(tablet-and-down) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
