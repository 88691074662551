@import '../../common/style/styleguide';

.of-order-subscription-selection {
  > .ds-grid__row {
    @include breakpoint(phone) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .of-change-availablity-address-row {
    > div {
      padding: 0 8px;
    }
  }

  .title-voucher-row {
    text-align: justify;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 12px;
    @include breakpoint(phone) {
      padding-left: 0px;
      padding-right: 0px;
    }
    .of-campaign-voucher {
      margin: 0 0;
      justify-content: center;
    }
  }

  .heading-margin {
    margin-top: 30px;

    @include breakpoint(phone) {
      h3 {
        margin-left: 4px;
      }
    }
  }

  .coverage-disclaimer-container {
    margin-bottom: 30px;
  }

  .of-contact-support {
    margin-top: 50px;
    > div {
      padding: 0 8px;
    }
  }

  .of-change-address-link {
    cursor: pointer;
    color: $ds-color__blue-600;
    &:hover {
      text-decoration: underline;
    }
  }

  @include breakpoint(desktop) {
    .of-subscription-cards {
      grid-template-columns: repeat(3, 1fr);
      &-single {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  button.nettilite-text-button {
    @include ds-base-font-family();
    font-size: $ds-baseline * 2;
    background-color: transparent;
    border: none;
    color: $ds-color__blue-600;
    font-weight: normal;
    padding: $ds-baseline;
    text-transform: none;
  }
}

.of-nettilite-container {
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
}
