@import '../../../common/style/styleguide';

.of-punchout-checkout-products {
  margin-bottom: 2em;

  .ds-cart-content-product {
    margin: 0;
    padding: $global-baseline * 1.5 0;
    border-bottom: 1px solid $ds-color__neutral-300;

    .ds-card-product-image {
      text-align: center;

      img {
        max-height: $global-baseline * 9;
        max-width: $global-baseline * 9;
      }
    }

    .ds-cart-product-name {
      font-weight: 600;
    }

    .ds-card-product-price {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    .ds-cart-content-childproduct {
      border-top: 1px solid $ds-color__neutral-300;
    }
  }

  .of-sub-header {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 2rem;
    border-bottom: 1px solid $ds-color__neutral-300;
  }
}
