#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
