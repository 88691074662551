@import '../../common/style/styleguide';

.of-login {
  padding: 3 * $global-baseline;

  @include breakpoint(phone) {
    padding-left: $ds-baseline;
    padding-right: $ds-baseline;
  }

  .of-link {
    cursor: pointer;
  }

  .ea-input__addon .ea-icon {
    color: $ea-color-grey;

    &:focus {
      color: $ea-color-black;
    }
  }

  .ds-popover-holder {
    @media screen and (max-width: $breakpoint-m) {
      display: none;
    }
  }

  .of-login-error-popover {
    // .ea-input-wrapper's z-index: 10 and we want popover to be over inputs
    z-index: 11;
    max-width: 380px;

    p,
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    ol {
      list-style-type: decimal;
      margin-left: 1em;
    }
  }

  .of-password-input {
    button {
      background: none;
      border: 0;
      height: 100%;
    }
  }

  .of-login-secondary-actions {
    flex-direction: column;
    align-items: flex-start;
    a,
    button {
      text-decoration: underline;
    }
    button {
      background: none;
      padding: 0;
      border: 0;
      color: $ea-color-linkblue;
    }
  }

  .of-other-logins {
    > div {
      button {
        @include breakpoint(phone) {
          padding-left: $ds-baseline;
          padding-right: $ds-baseline;
        }
        span.of-button-content {
          display: flex;
          flex-direction: column;

          .of-other-login-icon {
            align-self: center;
            width: 56px;
            height: 56px;

            &--home {
              background: no-repeat url('../../common/svg/home_office.svg');
            }
            &--self-service {
              background: no-repeat url('../../common/svg/self_service.svg');
            }
          }

          span {
            text-transform: initial;
            color: initial;
            font-family: $global-font-normal;
            max-width: 120px;
          }
        }
      }
    }
  }
}
