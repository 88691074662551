@import '../../common/style/styleguide';

.support-case-dialog {
  .ea-input__label,
  .title {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #171c43;
  }
}
