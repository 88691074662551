@import '../../common/style/styleguide';

.of-select-number-list {
  text-align: center;

  &__more-numbers-link {
    background: transparent;
    border: 0;
    color: $ds-color__blue-600;
    cursor: pointer;
    margin: 1.5 * $ds-baseline 0 0 0;
    padding: 0;

    &:hover {
      // DS creates this color with darken, so it's not available as a variable.
      color: #0048a1;
    }
  }

  .of-select-one-list {
    text-align: center;
    &__item {
      .ds-icon {
        left: 12px;
        top: 12px;
      }

      > div {
        padding: 1.5 * $ds-baseline 0;
      }

      // This is temporary, will be removed once CFG is removed or cleaned up
      .ea-center-block {
        padding-bottom: 0;
        padding-top: 0;
      }

      &--disabled {
        color: $ds-color__neutral-400;
      }

      &--selected {
        color: $ds-color__blue-600;
      }
    }
  }

  &--inset {
    margin-left: 4 * $ds-baseline;
    text-align: left;
  }
}
