@import '../../common/style/styleguide';

.of-icon-button-container {
  .of-icon-button__button {
    border: none;
    text-transform: none;
    color: $ds-color__blue-600;

    &:hover {
      background-color: $ds-color__transparent;
    }

    &__begin-button-icon {
      color: $ds-color__blue-600;
    }

    &__label {
      font-size: 2.25 * $ds_baseline;
      font-family: $global-font-brand;
      cursor: pointer;
    }
  }
}
