@import '../../common/style/styleguide';

.of-suspend-subscription-options {
  padding-bottom: 5 * $global-baseline;

  > h3 {
    padding-bottom: 3 * $ds-baseline;
  }

  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 600;

    &-fixed-term {
      padding-top: 10px;
    }
  }

  li p {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .selected {
    color: $ea-color-linkblue;
  }

  &--notebox {
    border: 1px solid #e8e8ea;
    background-color: #fafafb;
    padding: 2em;
    padding-top: 1em;
    margin-top: 1em;
  }

  &--link {
    margin-top: 1em;
    float: right;
  }
}
