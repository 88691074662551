@import '../../common/style/styleguide';

.of-add-or-select-billing-accounts-v2 {
  padding-bottom: 1.5 * $global-baseline;

  > h3 {
    padding-bottom: $global-baseline;
  }
}

.of-add-or-select-billing-account--disabled {
  /*
  * TODO This css class and uses should be removed when CL.Search disabled property works correctly
  *  The opened issue url https://github.devcloud.elisa.fi/design-system/component-library/issues
  */
  pointer-events: none;
  opacity: 0.7;
  z-index: 1;
}

.of-billing-account-search-result {
  padding-bottom: $global-baseline;
  padding-top: $global-baseline;
}
