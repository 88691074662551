@import '../variables.scss';

.accordion .ds-accordion__section__content {
  line-height: 2.4rem;
}

.ds-accordion__section__content--open {
  box-sizing: content-box;
}

.accordion.ya-compress {
  .title {
    h2 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}


