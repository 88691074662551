@import '../../common/style/styleguide';

.stepper-block {
  .ds-stepper ol {
    li.ds-stepper__step__sub::after {
      top: 2.725rem;
    }

    li:last-child svg {
      fill: $ds-color__white !important;
    }

    li.ds-stepper__step .ds-stepper__step__name {
      color: $ds-color__blue-200--dark;
    }
  }

  .ds-stepper__step__sub .ds-icon {
    background-color: $ds-color__white;
  }

  .status-color-neutral .ds-stepper ol li:last-child::before {
    background-color: $ds-color__neutral-600;
  }

  .status-color-warning-light .ds-stepper ol li:last-child::before {
    background-color: $ds-color__orange-300;
  }

  .status-color-green .ds-stepper ol li:last-child::before {
    background-color: $ds-color__green-600;
  }

  .status-color-blue .ds-stepper ol li:last-child::before {
    background-color: $ds-color__blue-800;
  }

  .status-color-salmon .ds-stepper ol li:last-child::before {
    background-color: $ds-color__salmon-700;
  }
}
