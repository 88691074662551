@import 'node_modules/@design-system/component-library/src/globals/scss/_variables';
@import 'node_modules/@design-system/component-library/src/globals/scss/_variables.color-map';

.contact-grid {
  color: $ds-color__black;
  overflow: hidden;
  text-align: center;

  &__title {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 40px;
    font-family: $ds-header-font-family;
    line-height: 52px;
  }

  &__nav {
    &__list {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0;
      margin: 0px !important;
      list-style-type: none !important;

      &__item {
        min-width: 300px;
        max-width: 400px;
        .contact-item {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          margin-bottom: 12px;

          .ds-icon {
            &--color {
              @each $name, $color in $ds-colors {
                &-#{$name} {
                  background-color: #{$color};
                }
              }
            }
          }

          &__title {
            font-family: $ds-header-font-family;
            font-size: 20px;
            letter-spacing: 1px;
            margin: 12px 0 0 0;
            display: block;
          }

          &__desc {
            display: block;
            font-size: 14px;
          }
        }
      }
    }
  }
}
