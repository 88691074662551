@import '../../common/style/styleguide';

@keyframes Shinyshiny {
  0% {
    background-position: 93% 0;
  }
  50% {
    background-position: 0 100%;
  }
  100% {
    background-position: 93% 0;
  }
}

.of-self-service-home-navigation {
  .of-navigation-menu__item {
    position: relative;

    .of-diamond-enrichment {
      background: linear-gradient(225deg, #0019af, #aae0fa, #0019af, #0019af);
      background-size: 200% 200%;
      animation: Shinyshiny 3s ease infinite;
      background-clip: inherit;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .ea-icon--reply {
      display: inline-block;
      transform: scaleX(-1) translate(8px);
    }

    &__order-count {
      font-family: $global-font-normal;
      color: $ea-color-white;
      border: 1px solid $ea-color-white;
      background-color: $ea-color-orange;
      border-radius: 99rem;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: -4px;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 1440px) {
      &__order-count {
        right: 50%;
        left: 50%;
      }
    }
  }
}
