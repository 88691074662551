@import '../../common/style/styleguide';

.of-physical-sim-activation {
  &__picto {
    margin: 0;
    background-position: bottom;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__instructions {
    margin-bottom: 3em;
  }

  &__instructions-text {
    flex: 1 0 65%;
    b {
      font-weight: 600;
    }
  }

  &__physical-sim {
    text-align: center;
    flex: 1 0 35%;
  }

  &__activation {
    padding-top: 1.5em;
    border-bottom: $global-border-normal solid $ea-color-smoke;
  }

  &__info {
    border-bottom: $global-border-normal solid $ea-color-smoke;
    padding: 1em 0 1em;
  }

  &__actions {
    text-align: right;
    margin-top: 1em;
  }

  &__button {
    text-transform: uppercase;
  }

  // Left margin is needed to keep the buttons from touching, but don't apply to first as it's not needed
  // and with narrow screen will cause the buttons to wrap unnecessarily.
  &__button:nth-child(n + 2) {
    margin-left: 2em;
  }
}
