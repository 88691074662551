@import '../../common/style/styleguide';

.of-file-upload {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 640px) and (max-width: 1024px) {
    width: 95%;
  }
  @media only screen and (min-width: 1400px) {
    width: 400px;
  }

  &__dropzone {
    height: $global-baseline * 8;
    background-color: $ea-color-light-smoke;
    border: 1px dashed #8b8e95;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--highlight {
      background-color: #e0eefd;
      border: 2px dashed #216ce4;
    }
    &--error {
      border: 2px dashed $ea-color-error;
    }
  }

  &__input {
    display: none;
  }

  &__upload-label {
    color: $ea-color-blue;
    text-transform: uppercase;
    margin-top: $global-baseline * 0.5;
    font-size: 14px;

    svg {
      width: $global-baseline * 1.5;
      height: $global-baseline * 1.5;
      fill: $ea-color-blue;
      margin-right: $global-baseline;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }

  &__file {
    padding: $global-baseline * 0.5 0;
    color: $ea-color-dark-grey;
    border-bottom: 1px solid $ea-color-dark-smoke;
    font-size: 14px;
    display: flex;
    align-items: center;

    .ds-icon[icon='attachment'] {
      margin-right: $global-baseline;
    }

    button {
      background: transparent;
      border: 0;
      margin: 0 0 0 auto;
      padding: 0;
    }
  }

  &__file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__error-message {
    color: $ea-color-error;
    margin-top: $global-baseline * 2;
  }
}
