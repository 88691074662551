.of-header-shopping-basket {
  .ds-headershoppingcart {
    .ds-headershoppingcart__totals {
      display: none;
    }
    .ds-headershoppingcart__summary {
      margin-bottom: 10px;
    }
  }
}
