@import '../../common/style/styleguide';

.of-shopping-basket-wrapper {
  .of-shopping-basket-content {
    .ds-disclaimer {
      border: $ds-color__secondary-blue-600;

      .ds-disclaimer-content {
        display: flex;
        flex-direction: column;

        p a.ds-link {
          text-decoration: none;
          padding: 10px 20px 10px 20px;
        }

        .ds-link--button-color-light {
          border-width: 2px;
        }
      }
    }
  }
}
