@use 'sass:math';
@import '../../common/style/styleguide';

$parent-border-size: 2px;
$ribbon-font-size: 11px;
$ribbon-line-height: 14px;
$ribbon-overflow: 5px;
$ribbon-horizontal-padding: 10px;
$ribbon-vertical-padding: 10px;
// Ribbon colour not found from design system variables
$ribbon-light-blue-base-color: #71b4fc;

.of-ribbon {
  --ribbon-content-height: calc(var(--ribbon-line-count, 1) * #{$ribbon-line-height});
  --ribbon-max-line-length-px: calc(var(--ribbon-needed-line-length, 10) * 1ch + #{2 * $ribbon-horizontal-padding});
  --ribbon-height: calc(var(--ribbon-content-height) + #{2 * $ribbon-vertical-padding});
  // Might seem weird, but we're basically making sure we have enough space for the longest line. Math.
  --ribbon-width: calc(var(--ribbon-height) * 2 + var(--ribbon-max-line-length-px));
  // Box diagonal should be the width of the ribbon, so we divide that by sqrt(2)
  // $ribbon-overflow extra space for the 'corners'
  --ribbon-box-size: calc(var(--ribbon-width) / 1.414213562 + #{$ribbon-overflow});
  --ribbon-color: #{$ds-color__orange-700};
  --ribbon-corner-color: #{desaturate(darken($ds-color__orange-700, 11.5%), 20%)};

  color: $ds-color__blue-800;
  font-size: $ribbon-font-size;
  font-weight: 700;
  height: var(--ribbon-box-size);
  // We want to place the pseudo-elements behind this, but not anything else.
  line-height: $ribbon-line-height;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: ($ribbon-overflow + $parent-border-size) * -1;
  width: var(--ribbon-box-size);

  &--left {
    left: ($ribbon-overflow + $parent-border-size) * -1;
  }

  &--right {
    right: ($ribbon-overflow + $parent-border-size) * -1;
  }

  &::after,
  &::before {
    border: $ribbon-overflow solid transparent;
    content: '';
    display: block;
    position: absolute;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    border-bottom-color: var(--ribbon-corner-color);
    top: -$ribbon-overflow;
  }

  &--left {
    &::after {
      border-right-color: var(--ribbon-corner-color);
      left: -$ribbon_overflow;
    }

    &::before {
      right: 0;
    }
  }

  &--right {
    &::after {
      border-left-color: var(--ribbon-corner-color);
      right: -$ribbon_overflow;
    }

    &::before {
      left: 0;
    }
  }

  &__container {
    background: var(--ribbon-color);
    padding: $ribbon-vertical-padding 0;
    transform-origin: bottom center;
    // Magic number, parent diagonal
    width: var(--ribbon-width);
  }

  &--left &__container {
    --temp: calc(var(--ribbon-width) / 2); // Idea whines if this is inlined, so calculated separately.
    transform:
      // Centers it to the left edge of parent. 100% moves it 'above' the wrapper
      translate(calc(var(--ribbon-width) * -0.5), -100%) // rotate keeping bottom center in place
      rotate(-45deg) // move bottom edge to diagonal of wrapper
      translate(0, var(--temp));
  }

  &--right &__container {
    --temp: calc(var(--ribbon-width) / 2); // Idea whines if this is inlined, so calculated separately.
    transform:
      // Magic number ((sqrt(2) - 1) / 2), centers it to the right edge of parent. 100% moves it 'above' the wrapper
      translate(calc(var(--ribbon-width) * 0.207106781 + #{$ribbon-overflow}), -100%)
      // rotate keeping bottom center in place
      rotate(45deg) // move bottom edge to diagonal of wrapper
      translate(0, var(--temp));
  }

  &--turquoise {
    --ribbon-color: #{desaturate(lighten($ds-color__turquoise-400, 15%), 10%)};
    --ribbon-corner-color: #{desaturate(darken($ds-color__turquoise-400, 9%), 10%)};
  }

  &--light-blue {
    --ribbon-color: #{desaturate(lighten($ribbon-light-blue-base-color, 15%), 10%)};
    --ribbon-corner-color: #{desaturate(darken($ribbon-light-blue-base-color, 9%), 10%)};
  }

  &__content {
    // Just in case the user wants to copy the ribbon text...
    pointer-events: all;
    text-align: center;
    text-transform: uppercase;
  }
}
