.prevent-scrolling {
  overflow: hidden;
}
.products {
  .grid--gap {
    @media only screen and (min-width: 320px) {
      justify-content: center;
      grid-gap: 16px;
    }
  }
  .grid--full-width {
    width: 100%;
    max-width: none;
  }
  .addCursor {
    cursor: pointer;
  }
  .loading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48px;
    height: 48px;
    margin: 24px auto;
    border-style: solid;
    border-color: #0019af #0019af transparent #0019af;
    border-width: 8px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: rotate 0.8s linear infinite;
    animation: rotate 0.8s linear infinite;
  }
  .card--animated {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    will-change: transform;
    background: #fff;
  }
  .card--animated:hover {
    border-color: transparent;
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    position: relative;
    z-index: 100;
    -webkit-box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  }
  .fixed-subscription-product-image-height {
    img {
      height: 120px !important;
    }
  }
  .ds-grid__col--m-3,
  .ds-grid__col--l-4 {
    &--bordered {
      border: solid #e0e0e1;
      border-width: 1px 0 0 1px;
    }
    .card-subscription {
      &-product {
        background: #ffffff;
        padding: 8% 8% 4% 8%;

        &__cover {
          height: 180px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &__image {
          margin: 0 auto;
          width: 250px;
          max-width: 250px;
          height: 100%;

          img {
            object-fit: contain;
            height: 100%;
          }
        }

        &__usage,
        &__speed {
          font-weight: 700;
        }

        &__text {
          ul.custom-list {
            list-style: none;
          }
          li {
            &.icon-done {
              &:before {
                content: '';
                background: url('https://elisa.fi/kauppa/images/checkout/icon_done.svg')
                  no-repeat;
                background-size: 20px 20px;
                height: 20px;
                width: 20px;
              }
            }
            &.speed-4g {
              &:before {
                content: '';
                background: url('https://elisa.fi/kauppa/images/familyicons/speed-4g.svg')
                  no-repeat;
                background-size: 20px 20px;
                height: 20px;
                width: 20px;
              }
            }
            &.speed-5g {
              &:before {
                content: '';
                background: url('https://elisa.fi/kauppa/images/familyicons/speed-5G.svg')
                  no-repeat;
                background-size: 20px 20px;
                height: 20px;
                width: 20px;
              }
            }
            &.euroaming {
              &:before {
                content: '';
                background: url('https://static.kauppa.saunalahti.fi/img/growth/voice/eu-icon.png')
                  no-repeat;
                background-size: 25px 18px;
                height: 18px;
                width: 25px;
              }
            }
            &.sbonus {
              &:before {
                content: '';
                background: url('https://static.kauppa.saunalahti.fi/img/growth/sgroup/sbonus.png')
                  no-repeat;
                background-size: 20px 20px;
                height: 20px;
                width: 20px;
              }
            }
            &.puhe {
              &:before {
                content: '';
                background: url('https://static.kauppa.saunalahti.fi/img/growth/voice/puhe-icon.png')
                  no-repeat;
                background-size: 23px 25px;
                height: 23px;
                width: 25px;
              }
            }
          }
        }

        &__feature {
          margin-bottom: 5px;
        }
      }
    }
  }
  .card-subscription-product__price {
    margin-top: 10px;
    font-size: 36px;
  }
  .card-sub-product-dialog {
    &__features {
      column-width: 300px;
      column-gap: 20px;
    }
    &__feature {
      display: block;

      span.feature-text {
        margin-left: 10px;
      }

      span.ds-icon {
        vertical-align: top;
      }
    }
    .modal {
      &__content {
        max-width: 850px;
        max-height: 100%;
      }
      &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    &__spacer {
      flex: 1 1 auto;
      height: 20px;
    }
  }
  .card__width--l-3 {
    max-width: 450px;
    flex: 100%;
  }
  .card__width--l-4 {
    max-width: 400px;
    flex: 100%;
  }

}

.ds-reset {
  .products {
    ul.ds-list {
      list-style-type: none;
    }
  }
}
