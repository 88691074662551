@import '../../common/style/styleguide';

.of-details-wrapper__action {
  padding: $ds-baseline * 3 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-m) {
    flex-direction: column;
    align-items: flex-start;
  }

  .ds-button,
  .ds-button__content {
    font-family: 'Open Sans', sans-serif;
    font-size: ($ds-baseline * 2);
    text-transform: unset;
    padding-left: 0;
    border: 0;
    text-align: left;
    &:hover {
      background-color: $ds-color__white;
    }
    line-height: ($ds-baseline * 2.5);
  }

  &__replace {
    .ds-button,
    .ds-button__content {
      padding-right: $ds-baseline * 0.5;
      background-color: $ds-color__white;
    }
    .ds-tooltip {
      padding: 10px;
      width: 384px;
      max-width: 88vw;
    }
  }
}
