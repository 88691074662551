@import '../../../common/style/styleguide';

.of-product-details-availability {
  font-size: 13px;
  margin: -6px 0 10px 0;

  &__icon {
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    margin: 0 3px;
    vertical-align: middle;
    width: 14px;

    &--green {
      background: $ds-color__green-500;
    }

    &--orange {
      background: $ds-color__orange;
    }

    &--red {
      background: $ds-color__red-600;
    }
  }
}
