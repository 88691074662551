@import '../../common/style/styleguide';

.of-support-case-public-thank-you {
  max-width: 1100px;
  margin: auto;

  h2 {
    position: relative;
    padding-left: 40px;

    .ds-icon {
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}
