// This file contains overrides to pattern library. The resulting css file will be inlined to index.html after pattern-library.
@import 'styleguide';

.ea-mainheader {
  &-altlogo img {
    width: 72px;
    height: 36px;
  }

  &-cart {
    .ea-cart-content {
      .ea-cart-content-head {
        p {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }

  &-mainmenu a:hover {
    text-decoration: none;
  }
}

.ea-modal {
  z-index: 10001; // above react-header
  overflow-y: scroll;
  @mixin modal-width($prefix, $name, $factor) {
    &--#{$prefix}-#{$name} {
      .ea-modal__content {
        width: 420px + $factor * $global-baseline * 5;
        max-width: 100%;
      }
    }
  }

  @for $i from 1 through 8 {
    @include modal-width('width', $i, $i);
  }

  &__content {
    position: absolute;
    height: auto;
    min-height: 100%;
    overflow-y: visible;

    img {
      max-width: 100%;
    }
    @include breakpoint(tablet-and-up) {
      background: none;
      max-height: none;
      min-height: auto;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      .ea-modal--open & {
        transform: scale(1) translate3d(0, -50%, 0);
      }
    }

    & .ea-modal__box {
      background: #fff;
      position: static;
    }
  }

  &__overlay {
    position: fixed;
  }

  &--wide {
    & .ea-modal__content {
      transform: none;
      top: 0;
      height: auto;
      @include breakpoint(tablet-and-up) {
        padding: 15vh 0;
        max-width: 950px;
        & .ea-modal__close {
          top: calc(15vh + #{$global-baseline});
        }
      }
      & .ea-modal__box {
        padding: 50px;
        width: auto;
      }
    }
  }
}

.ea-disc {
  &:before {
    content: '';
    border-radius: 100%;
    display: inline-block;
    vertical-align: baseline;
    background-color: $ea-color-smoke;
    margin-right: 8px;
  }

  &--small:before {
    width: 12px;
    height: 12px;
  }
  &--light-orange:before {
    background-color: $ea-color-light-orange;
  }
  &--red:before {
    background-color: $ea-color-error;
  }
  &--linkblue:before {
    background-color: $ea-color-linkblue;
  }
  &--green:before {
    background-color: $ea-color-green;
  }
  &--yellow:before {
    background-color: $ea-color-yellow;
  }
  &--transparent:before {
    background-color: transparent;
  }
}

.ea-icon {
  &--activity-band-filled:before,
  &--ba-filled:before,
  &--generic-service-filled:before,
  &--headphones-filled:before,
  &--internet-filled:before,
  &--invoice-filled:before,
  &--mobile-broadband-filled:before,
  &--mobile-m2m-filled:before,
  &--laptop-filled:before,
  &--northeast-arrow:before,
  &--order-filled:before,
  &--phone-filled:before,
  &--phone-number-filled:before,
  &--product-list:before,
  &--sim-filled:before,
  &--tablet-filled:before,
  &--table-order:before,
  &--specialnumber:before,
  &--ring-filled:before,
  &--send-filled:before,
  &--resend:before,
  &--company-user:before,
  &--change-of-device:before,
  &--wireless-device-filled:before {
    content: '';
    background-size: contain;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 1.5rem;
    vertical-align: middle;
  }

  &--power:before,
  &--external-link:before {
    content: '';
    background-size: contain;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin-right: 1.5rem;
    vertical-align: top;
  }

  &--flipped {
    transform: scaleY(-1);
  }

  &--mirrored {
    transform: scaleX(-1) translate(8px);
  }

  &--activity-band-filled:before {
    background: transparent url('../svg/ea-icon-activity-band-filled.svg') center center no-repeat;
  }
  &--ba-filled:before {
    background: transparent url('../svg/ea-icon-ba-filled.svg') center center no-repeat;
  }
  &--company-user:before {
    background: transparent url('../svg/ea-icon-company-user.svg') center center no-repeat;
  }
  &--external-link:before {
    background: transparent url('../svg/ea-icon-external-link.svg') center center no-repeat;
  }
  &--generic-service-filled:before {
    background: transparent url('../svg/ea-icon-generic-service-filled.svg') center center no-repeat;
  }
  &--headphones-filled:before {
    background: transparent url('../svg/ea-icon-headphones-filled.svg') center center no-repeat;
  }
  &--internet-filled:before {
    background: transparent url('../svg/ea-icon-internet-filled.svg') center center no-repeat;
  }
  &--invoice-filled:before {
    background: transparent url('../svg/ea-icon-invoice-filled.svg') center center no-repeat;
  }
  &--laptop-filled:before {
    background: transparent url('../svg/ea-icon-laptop-filled.svg') center center no-repeat;
  }
  &--mobile-broadband-filled:before {
    background: transparent url('../svg/ea-icon-mobile-broadband-filled.svg') center center no-repeat;
  }
  &--mobile-m2m-filled:before {
    background: transparent url('../svg/ea-icon-mobile-m2m-filled.svg') center center no-repeat;
  }
  &--northeast-arrow:before {
    background: transparent url('../svg/ea-icon-northeast-arrow.svg') center center no-repeat;
  }
  &--order-filled:before {
    background: transparent url('../svg/ea-icon-order-filled.svg') center center no-repeat;
  }
  &--phone-filled:before {
    background: transparent url('../svg/ea-icon-phone-filled.svg') center center no-repeat;
  }
  &--phone-number-filled:before {
    background: transparent url('../svg/ea-icon-phone-number-filled.svg') center center no-repeat;
  }
  &--power:before {
    background: transparent url('../svg/ea-icon-power.svg') center center no-repeat;
  }
  &--product-list:before {
    background: transparent url('../svg/ea-icon-product-list.svg') center center no-repeat;
  }
  &--sim-filled:before {
    background: transparent url('../svg/ea-icon-sim-filled.svg') center center no-repeat;
  }
  &--tablet-filled:before {
    background: transparent url('../svg/picto-devices.svg') center center no-repeat;
  }
  &--table-order:before {
    background: transparent url('../svg/ea-icon-table-order.svg') center center no-repeat;
  }
  &--wireless-device-filled:before {
    background: transparent url('../svg/ea-icon-wireless-device-filled.svg') center center no-repeat;
  }
  &--sub-object:before {
    background: transparent url('../svg/ea-icon-sub-object.svg') center center no-repeat;
  }
  &--specialnumber:before {
    background: transparent url('../svg/ea-icon-specialnumber.svg') center center no-repeat;
  }
  &--ring-filled:before {
    background: transparent url('../svg/ea-icon-ring-filled.svg') center center no-repeat;
  }
  &--send-filled:before {
    background: transparent url('../svg/icon-send-filled.svg') center center no-repeat;
  }
  &--resend:before {
    background: transparent url('../svg/icon-resend.svg') center center no-repeat;
  }
  &--change-of-device:before {
    background: transparent url('../svg/ea-icon-change-of-device.svg') center center no-repeat;
  }
}

.ea-picto {
  background-size: 110px 120px;
  display: inline-block;
  margin-right: 4rem;
  width: 110px;
  margin-top: 4px;
  &--billing-account {
    height: 108px;
    background: transparent url('../svg/picto-billing-account.svg') left top no-repeat;
  }
  &--billing-account-new {
    height: 108px;
    background: transparent url('../svg/picto-billing-account-new.svg') left top no-repeat;
  }
  &--order {
    height: 93px;
    background: transparent url('../svg/picto-order.svg') left top no-repeat;
  }
  &--invoice {
    height: 93px;
    background: transparent url('../svg/picto-invoice.svg') left top no-repeat;
  }
  &--sim {
    height: 93px;
    background: transparent url('../svg/picto-sim.svg') left top no-repeat;
  }
  &--mobilebb {
    height: 93px;
    background: transparent url('../svg/picto-mobilebb.svg') left top no-repeat;
  }
  &--mobile-m2m {
    height: 93px;
    background: transparent url('../svg/picto-broadband-m2m.svg') left top no-repeat;
  }
  &--fixedbb {
    height: 93px;
    background: transparent url('../svg/picto-fixedbb.svg') left top no-repeat;
  }
  // Temporary before product based icons are available
  &--generic-service-filled {
    height: 93px;
    background: transparent url('../svg/picto-generic-service.svg') left top no-repeat;
  }
  &--phone-filled {
    height: 93px;
    background: transparent url('../svg/picto-phone.svg') left top no-repeat;
  }
  &--phone {
    height: 93px;
    background: transparent url('../svg/picto-phone.svg') center top no-repeat;
  }
  &--tablet-filled {
    height: 93px;
    background: transparent url('../svg/picto-devices.svg') left top no-repeat;
  }
  &--laptop-filled {
    height: 93px;
    background: transparent url('../svg/picto-laptop.svg') left top no-repeat;
  }
  &--wireless-device-filled {
    height: 93px;
    background: transparent url('../svg/ea-icon-wireless-device-filled.svg') left top no-repeat;
  }
  &--headphones-filled {
    height: 93px;
    background: transparent url('../svg/picto-headphones.svg') left top no-repeat;
  }
  &--activity-band-filled {
    height: 93px;
    background: transparent url('../svg/ea-icon-activity-band-filled.svg') left top no-repeat;
  }
  &--square-trade {
    height: 93px;
    background: transparent url('../svg/picto-square-trade.svg') left top no-repeat;
  }
  &--user {
    height: 108px;
    background: transparent url('../svg/picto-user.svg') left top no-repeat;
  }
  &--pbx {
    height: 93px;
    background: transparent url('../svg/picto-pbx.svg') left top no-repeat;
  }
  &--pbx-lite {
    height: 93px;
    background: transparent url('../svg/picto-pbx-lite.svg') left top no-repeat;
  }
  &--cellular {
    width: 22px;
    height: 22px;
    background: transparent url('../svg/picto-cellular.svg') left top no-repeat;
  }
  &--ios-settings {
    width: 22px;
    height: 22px;
    background: transparent url('../svg/picto-ios-settings.svg') left top no-repeat;
  }
  &--sim-activate {
    height: 93px;
    background: transparent url('../svg/picto-sim-activate.svg') left top no-repeat;
  }
  &--physical-sim {
    width: 200px;
    height: 126px;
    background: transparent url('../svg/picto-physical-sim.svg') left top no-repeat;
  }
  &--order-sim {
    height: 93px;
    background: transparent url('../svg/picto-order-sim.svg') left top no-repeat;
  }
  &--catalog {
    height: 93px;
    background: transparent url('../svg/picto-billing-account.svg') left top no-repeat;
  }
  &--epp-phone {
    height: 93px;
    background: transparent url('../svg/picto-phone.svg') center top no-repeat;
  }

  &--mobile {
    height: 93px;
    background: transparent url('../svg/picto-mobile.svg') center top no-repeat;
  }

  &--device-store {
    height: 93px;
    background: transparent url('../svg/picto-device-store.svg') center top no-repeat;
  }

  &--epp-computer {
    height: 93px;
    background: transparent url('../svg/picto-epp-computer.svg') center top no-repeat;
  }

  &--fixed-broadband {
    height: 93px;
    background: transparent url('../svg/picto-fixed-broadband.svg') center top no-repeat;
  }

  &--landline {
    height: 93px;
    background: transparent url('../svg/picto-landline.svg') center top no-repeat;
  }

  &--corporate-network {
    height: 93px;
    background: transparent url('../svg/picto-corporate-network.svg') center top no-repeat;
  }

  &--maintenance {
    height: 93px;
    background: transparent url('../svg/picto-maintenance.svg') center top no-repeat;
  }

  &--cl-user {
    height: 108px;
    background: transparent url('../svg/picto-cl-user.svg') center top no-repeat;
  }
}

.ea-text-button {
  border: 0;
  padding: 0;
  color: $ea-color-linkblue;
  background: transparent;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: transparent;
  }

  &:disabled {
    color: $ea-color-light-grey;
  }
}

.ea-hidden {
  visibility: hidden;
}

.ea-input--html-validation {
  .ea-input__label {
    top: 3px;
  }

  .ea-input__not-required {
    top: 3px;
    color: $ea-color-grey;
  }

  &.ea-input--invalid > .ea-input__input,
  .ea-input__input:invalid {
    border-color: $ea-color-error;
  }

  .ea-input__disabled {
    position: absolute;
    right: 10px;
    top: 40px;
    font-size: 18px;
    color: $ea-color-light-grey;
    &::before {
      font-weight: bold;
    }
  }

  .ea-input__input {
    color: #404041;
  }

  // TODO: remove this when addons work with html-validation stuff
  .ea-input__addon {
    display: none;
  }
}

.ea-input {
  &--button {
    .ea-input__wrapper {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ea-numbered-discs {
  counter-reset: ea-numbered-disc;

  &__item:before {
    content: counter(ea-numbered-disc);
    counter-increment: ea-numbered-disc;
    border-radius: 15px;
    color: $ea-color-white;
    background-color: $ea-color-light-grey;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
  }
  &__item--highlight:before {
    background-color: $ea-color-linkblue;
  }
}

.ea-hide {
  &--always {
    display: none;
  }
}

.ea-loading--text {
  position: absolute;
}

.ea-tooltip-theme.ea-tooltip {
  padding: 20px 18px;
}

.ea-combobox {
  &__input {
    .ea-input__wrapper {
      flex-direction: column;
      justify-content: center;
    }

    .ea-input__input--sub {
      font-size: 1.3rem;
      align-self: start;
      padding-left: 12px;
      margin-top: -12px;
      margin-bottom: 12px;
      color: $ea-color-grey;
    }

    &:focus-within .ea-input__input--sub {
      display: none;
    }
  }
  &__item--sub {
    font-size: 1.3rem;
    color: $ea-color-grey;
  }
  &--open {
    @include breakpoint(tablet-and-down) {
      z-index: 10002; // above react-header and model
    }
  }

  &__item:hover &__item--sub {
    color: $ea-color-linkblue;
  }

  &__item--selected &__item--sub,
  &__item--selected:hover &__item--sub {
    color: $ea-color-white;
  }

  &--open .ea-input__wrapper,
  .ea-input__wrapper:focus-within {
    border-color: $ea-color-linkblue;
    box-shadow: none;
  }

  .ea-combobox__input:after {
    color: $ea-color-blue;
  }
}

// The rest here basically just enforce some PL selectors so that CL doesn't
// override them.

// CL has an a:hover color that we can't cleanly override, thus these.

// only --linkblue has white links
.ea-color-container--linkblue,

// This button type _on a dark bg_ is white
.ea-color-container {
  &--transparent-light-text {
    &:hover {
      color: #ffffff;
    }
  }
}

// These are in this override file because the issue is caused by pattern library styles.
// .ds-icon doesn't have a defined box-sizing, so in CL it defaults to
// content-box. elisa-styleguide-min.css sets
// *, ::before, ::after { box-sizing: inherit }
// and .ds_dropdown__button has box-sizing: border-box,
// so .ds-icon is forced to border-box, thus breaking its sizing.
// As there's no easy way to tell if setting this globally is safe, we just fix
// known issues.
.ds-button,
.ds-dropdown__button {
  .ds-icon {
    box-sizing: content-box;
  }
}

.ds-radio input:checked + .ds-radio__label .ds-radio__icon::after {
  box-sizing: content-box;
}

// elisa-styleguide-min.css sets box-sizing: content-box and overrides the CL.Input's box-sizing: border-box
.ds-input input[type='search'] {
  box-sizing: border-box;
}

// Overrides with no strict scope
a {
  outline: 0;
}

// Styles with no strict scope
mark {
  background-color: $ds-color__blue-200 !important;
}
