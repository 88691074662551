.offerCards-title {
  text-align: center;
}

.offerCard {
  .ds-card {
    width: 100%;
  }

  img {
    height: 100%;
  }

  &__icon-container {
    cursor: default;
    margin: 12px auto;
    height: 120px;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    float: none;
    background: #0068f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      line-height: 48px !important;
      font-size: 3em !important;
      color: #fff;
      background: #fff;
    }
  }

  .ds-card-heading--no-padding:before {
    padding-top: 66.66% !important;
  }
  ul {
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
  }
}
