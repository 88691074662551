@import '../../common/style/styleguide';

.catalog-configuration {
  .flex .ds-input--inputarea {
    display: flex;
  }

  @media only screen and (min-width: $breakpoint-l) {
    .ds-input--inputarea .width-50 {
      width: 50%;
    }
  }

  .extension {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 0 0 0 0;
    max-height: 48px;
  }
}
