@import '@design-system/component-library/src/globals/scss/variables';

.of-file-editable-list {
  gap: 8px;
  flex: 100%;

  &--file {
    background: $ds-color__neutral-200;
    margin-bottom: 8px;
    border-radius: 4px;

    button {
      border: 0;
      background: inherit;
      padding: 0;
      cursor: pointer;
      float: right;
    }

    .of-file-icon {
      margin-left: 6px;
    }

    &--right-icon {
      vertical-align: middle;
      margin: 8px 6px;
    }

    &--file-name {
      display: inline-block;
      vertical-align: middle;
      margin: 8px 0;
    }
  }
  &--file.invalid {
    color: $ds-color__neutral-500;
    border: 2px solid $ds-color__danger;
    background-color: $ds-color__red-100;
  }

  .no-background {
    .of-file-editable-list--file--right-icon {
      float: right;
    }

    border-width: 0 0 0 0;
    background-color: $ds-color__white;
  }

  .bottom-border {
    border-width: 0 0 1px 0;
    border-color: $ds-color__neutral-400;
    border-style: solid;
    border-radius: 0;
    margin-bottom: 7px;
  }

  .uploading {
    .of-file-editable-list--file--file-name {
      margin: 0;
    }

    .of-progress-bar {
      margin-bottom: 4px;
      margin-top: 0;
    }
  }
}

.of-progress-bar {
  height: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.upload-status-text {
  color: $ds-color__neutral-600;
  margin-left: 5px;
}
