@import '../../common/style/styleguide';

.of-ring-settings {
  padding-bottom: 3 * $global-baseline;
  padding-left: $global-baseline;
  padding-top: 3 * $ds-baseline;

  &__actions {
    padding-bottom: $global-baseline;
    padding-left: $global-baseline;
  }

  &__addons {
    padding-bottom: 3 * $global-baseline;
  }

  &__phone-input {
    max-width: 220px;
    padding-left: 43px;
    .ea-input {
      padding-top: $global-baseline;
      .ea-input__label {
        top: -9px;
      }
    }
  }

  &__additional-services {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__first-row-item {
    padding-bottom: 3 * $ds-baseline;

    @include breakpoint(tablet-and-up) {
      padding-bottom: 0;
    }
  }

  .ea-combobox__input {
    width: 23 * $global-baseline;
  }
  &__short-combobox {
    .ea-combobox__input {
      max-width: $global-baseline * 20;
    }
  }

  label:first-child {
    color: $ea-color-dark-grey;
    display: block;
    font-size: 16px;
    font-weight: 600;
  }

  .ds-description {
    max-width: initial;
  }

  @media only screen and (min-width: 640px) {
    .ea-hide--tablet-and-up {
      display: none !important;
    }
  }
}
