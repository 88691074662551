.ea-separate-character-input {
  display: inline-flex;

  input {
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
    font-size: 30px;
    background-color: #fff;
    border: solid 0;
    border-bottom: 2px solid #e0e0e1;
    outline: none;
    width: 55px;
    transition: all 0.2s ease-in-out;
    border-radius: 1px;

    &:focus {
      border-color: #006dfc;
    }
  }
}
