@import '../../common/style/styleguide';

.of-registration-scene {
  .disclaimer-row {
    justify-content: center;
    > div {
      max-width: 900px;
      padding: 0;
      @include breakpoint(tablet-and-down) {
        padding: 0 12px;
      }
    }

    // We have periods after the links, they would look silly with the margin.
    .ds-disclaimer .ds-disclaimer-content a {
      margin-right: 0;
    }
  }

  .main-header-row {
    justify-content: center;
    > div {
      max-width: 900px;
      padding: 0px 78px;
      @include breakpoint(tablet-and-down) {
        padding: 0px 12px;
      }
    }
  }

  .ds-stepper {
    max-width: 900px;
    margin: 0 auto;

    .ds-stepper__step__completed {
      .ds-stepper__step__content {
        display: block;

        .of-registration-step {
          color: $ds-color__neutral-700--light;
        }
      }
    }
  }

  &-step {
    &2 {
      padding-top: $global-baseline;

      h4 {
        margin-bottom: $ds-baseline;
        margin-top: 0;
      }
    }
    &3 {
      padding-top: $ds-baseline;
    }
    &4 {
      > div:last-child {
        padding-top: 3 * $ds-baseline;
      }
    }
  }
}
