.my-account-details {
  .wide-description {
    max-width: 100%;
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
  }
}
