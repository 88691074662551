// Needed for some colors etc.
@import 'pattern-library-variables';
@import '~@design-system/component-library/src/globals/scss/variables';
@import '~@design-system/component-library/src/globals/scss/mixins';

$legacy-pl-content-size-laptop: 904px;
$legacy-pl-content-size-desktop: 1260px;

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin text-wrap() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin breakpoint($point) {
  @if $point == phone {
    @media only screen and (max-width: $breakpoint-xs-max-width) {
      @content;
    }
  }
  @if $point == phone-large {
    @media only screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-s-max-width) {
      @content;
    }
  }
  @if $point == phone-large-and-up {
    @media only screen and (min-width: $breakpoint-s) {
      @content;
    }
  }
  @if $point == phone-large-and-down {
    @media only screen and (max-width: $breakpoint-s-max-width) {
      @content;
    }
  }
  @if $point == tablet {
    @media only screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-m-max-width) {
      @content;
    }
  }
  @if $point == tablet-and-up {
    @media only screen and (min-width: $breakpoint-m) {
      @content;
    }
  }
  @if $point == tablet-and-down {
    @media only screen and (max-width: $breakpoint-m-max-width) {
      @content;
    }
  }
  @if $point == laptop {
    @media only screen and (min-width: $breakpoint-l) and (max-width: $breakpoint-l-max-width) {
      @content;
    }
  }
  @if $point == laptop-and-up {
    @media only screen and (min-width: $breakpoint-l) {
      @content;
    }
  }
  @if $point == laptop-and-down {
    @media only screen and (max-width: $breakpoint-l-max-width) {
      @content;
    }
  }
  @if $point == desktop {
    @media only screen and (min-width: $breakpoint-xl) {
      @content;
    }
  }
}
