@import '../../common/style/styleguide';

.of-mobile-broadband-four-g-selection {
  margin: $ds-baseline * 4;
  @media only screen and (min-width: $breakpoint-m) {
    margin: $ds-baseline * 6 $ds-baseline * 8 0 $ds-baseline;
  }

  &__dropdown {
    max-width: 400px;
  }

  &__description {
    &__price {
      padding: $ds-baseline * 3 0;
      &__final {
        color: $ds-color__blue-600--light;
        font-family: $ds-header-font-family--bold;
        font-size: $ds-baseline * 4;
      }
      &__before-discount {
        font-family: $ds-header-font-family;
        font-size: $ds-baseline * 3;
        padding-left: $ds-baseline * 2;
        text-decoration: line-through;
      }
    }

    &__footer {
      padding-bottom: $ds-baseline * 7;
      span {
        font-size: $ds-baseline * 1.7;
        display: block;
      }
    }
  }
}
