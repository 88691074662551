@import '../breakpoints.scss';
@import '../variables.scss';

.move--elevate-12 {
  margin-top: -144px;
}

@media only screen and (min-width: 640px) {
  .move--tablet-elevate-17 {
    margin-top: -204px;
  }
}

@media only screen and (min-width: 960px) {
  .move--laptop-elevate-35 {
    margin-top: -420px;
  }
}

.fluid-hero--card .fluid-container__content {
  @include breakpoint(laptop-and-up) {
    margin-left: 12% !important;
  }
}

.fluid-hero--card.text-box-hero {

  img {
    width: 100%;
    height: auto;
  }

  .color-container--default {
    background-color: #fff ;
    color: $ea-color-dark-grey ;
  }

  .color-container--white-smoke {
    background-color: $ea-color-white-smoke ;
    color: $ea-color-dark-grey
  }

  .color-container--smoke {
    background-color: $ea-color-smoke;
    color: $ea-color-dark-grey;
  }

  .color-container--linkblue {
    background-color: $ea-color-linkblue;
    color: $ea-color-white;
  }

  .color-container--blue {
    background-color: $ea-color-blue;
    color: $ea-color-white;
  }

  .color-container--dark-blue {
    background-color: $ea-color-dark-blue;
    color: $ea-color-white;
  }

  .color-container--dark-grey {
    background-color: $ea-color-dark-grey;
    color: $ea-color-white;
  }

  .color-container--coal-grey {
    background-color: $ea-color-coal-grey;
    color: $ea-color-white;
  }

  .color-container--videra-green {
    background-color: $videra-green;
    color: $ea-color-white;
  }

  .color-container--semi-transparent {
    background-color: rgba(255, 255, 255, 0.95);
    color: $ea-color-dark-grey;
  }

  .color-container--transparent {
    background-color: rgba(255, 255, 255, 0.80);
    color: $ea-color-dark-grey;
  }

  .color-container--transparent-light-text {
    background-color: rgba(64, 64, 65, 0);
    color: $ea-color-white;
  }
}
