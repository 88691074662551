@import '../../common/style/styleguide';

.of-shopping-basket-wrapper {
  .of-discontinued-prices-header {
    font-family: 'Open Sans', Arial, sans-serif;
    line-height: 28px;
    font-size: 19px;
    border-bottom: 1px solid #e8e8ea;
    border-top: 1px solid #e8e8ea;
  }
}
