@import '../../common/style/styleguide';

.of-billing-account-subscription-list {
  margin: 0;

  div.of-table-content {
    overflow-x: visible;
  }

  .of-subscription-table__text-column {
    white-space: initial;
  }

  .ds-table tr td {
    > a {
      align-items: center;
      display: flex;

      .ea-icon {
        display: inline-block;
        margin-right: 4px;

        &--mobile-broadband-filled,
        &--mobile-m2m-filled {
          padding-left: calc($ds-baseline / 2);
        }
      }
    }
  }
}
