@import '../../common/style/styleguide';

.of-enrollment-program-consent {
  h5 {
    + div {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;

      + div {
        padding-top: 0.5 * $ds-baseline;
      }
    }
  }
}
