@import '../../common/style/styleguide';

.of-search {
  &__container {
    flex: 1 0 50%;
    position: relative;
    height: 100%;

    @include breakpoint(tablet-and-up) {
      padding-right: $ds-baseline;
    }

    @include breakpoint(laptop-and-up) {
      width: 70%;
    }

    > .ds-input {
      margin: 0;
    }
  }

  &__success-icon {
    right: 1rem;
    top: $ds-baseline * 5;
  }

  &__search-icon {
    top: $ds-baseline * 5;
    left: 1rem;
    z-index: $zindex1;
  }

  &__input-with-icon input[type='text'] {
    text-indent: $ds-baseline * 3;
  }

  &__result-list {
    background-color: $ds-color__white;
    max-height: 0;

    &_open {
      max-height: 273.6px;
    }

    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
    border-color: $ds-color__neutral-400;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    left: 0;
    top: $ds-baseline * 10;
    position: absolute;
    width: calc(100% - 2px);

    li {
      padding: 0;

      button {
        background: transparent;
        border: 0;
        padding: 10px 18px;
        text-align: left;
        width: 100%;
      }
    }
  }
}
