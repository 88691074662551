@import '../../common/style/styleguide';

.of-invoice-list {
  .company-selector-for-list-views {
    margin-top: $ds-baseline * 3;
  }

  // TODO start, below can be removed when CompositeList not used in frontpage anymore
  // i.e. these are just used for the selfservice frontpage invoice CompositeList.
  .ea-composite-list-header__column:last-child {
    text-align: right;
  }

  .sum {
    position: relative;
    padding: 0;
    @include breakpoint(tablet-and-up) {
      margin: -8px 0px 0px 0px;
      position: absolute;
      right: 32px;
    }
  }
  // TODO end

  .ds-table tr td {
    > a {
      display: block;
    }

    .ds-icon {
      display: inline-block;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  // TODO This is only used for frontpage as well, should we use it elsewhere?
  .disclaimer {
    color: $ea-color-grey;
    @include breakpoint(tablet-and-up) {
      position: absolute;
      margin: 14px 0 0 0;
      right: 32px;
    }
  }
}
