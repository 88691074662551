.of-netti-lite-product-info-header {
  &__wrapper {
    display: flex;
  }

  &__picture {
    height: 100px;
    flex: 0 0 20%;

    &__broadband {
      background: url('../../common/svg/picto-fixedbb.svg') no-repeat;
    }

    &__yritystupla {
      background: url('../../common/svg/picto-mobilebb.svg') no-repeat;
    }
  }

  &__price-information {
    flex: 1 0 80%;
    justify-content: space-between;

    h3,
    h4 {
      margin: 0;
    }
  }

  &__details {
    span {
      line-height: 18px;
    }
  }
}
