@import '../../../common/style/styleguide';

.of-employee-user-personal-billing {
  &__disclaimer {
    padding-bottom: $global-baseline;
    padding-top: $global-baseline;
  }

  &__monthly_employee_part,
  &__no-changes-after-confirmation,
  &__return_instructions {
    padding-bottom: $global-baseline;
  }

  &__device_employee_part,
  &__redemption-price {
    padding-bottom: 1.5 * $global-baseline;
  }
}
