@import '../../common/style/styleguide';

@mixin tableLayoutPaddings() {
  padding-left: $global-baseline;
  padding-right: $global-baseline;

  &.ea-composite-list-row--tight {
    padding-left: 0;
    padding-right: 0;
  }

  display: flex;
  .ea-composite-list-row__cell {
    flex-grow: 1;
    flex-basis: 20%;
    padding-left: $global-baseline * 1.5;
    padding-right: $global-baseline * 1.5;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-value {
      padding-bottom: 0;

      &-line {
        &--icon {
          padding-left: $global-baseline * 2;

          &:before {
            display: block;
            width: 25px;
            height: 25px;
            position: absolute;
            left: $global-baseline;
          }
        }

        &--custom-image-icon {
          padding-left: $global-baseline * 3.5;
        }

        &--no-value {
          display: none;
        }

        small {
          color: $ea-color-grey;
          line-height: 12px;
        }
      }
    }

    &--tight {
      // this negates most of the padding of the row for a particular cell
      margin-top: -12px;
      margin-bottom: -12px;
    }

    &--wide {
      flex-grow: 8;
    }
    &--semi-wide {
      flex-grow: 4;
    }
  }
}

.ea-composite-list-row {
  position: relative;
  // Bottom padding missing baseline is compensated by a bottom padding for cell value, which is needed on mobile
  padding: ($global-baseline * 1.5) ($global-baseline * 2) ($global-baseline * 1.5);
  border-bottom: $global-border-normal solid $ea-color-smoke;
  flex-wrap: wrap;

  &--tight {
    padding-left: 0;
    padding-right: 0;
  }

  &--checkListLayout {
    border-bottom: none;
  }

  &--with-icon {
    padding-left: $global-baseline * 7;

    &.ea-composite-list-row--tight {
      padding-left: $global-baseline * 5;
    }
  }

  @include breakpoint(phone-large-and-down) {
    &--mobile-two-columns {
      label + .ea-composite-list-row__cell-value {
        float: right;
      }
    }
  }

  &--mobile-table-layout {
    @include tableLayoutPaddings;
  }

  &__mobile-icon {
    position: absolute;
    left: $global-baseline * 2;

    &:before {
      width: 35px;
      height: 35px;
    }
  }

  &__cell {
    display: block;
    overflow-x: hidden; // needed for line ellipsis
    label {
      color: $ea-color-grey;
    }

    &--no-value {
      label,
      .ea-composite-list-row__cell-value {
        display: none;
      }
    }

    &-postfix-icon.ea-icon {
      // .ea-icon added to raise the specificity above the base icon selectors
      position: relative;

      &:before {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 7px;
        left: -13px;
      }
    }

    &--with-postfix-icon {
      & > .ea-composite-list-row__cell-value {
        padding-right: $global-baseline * 2;
        display: inline-block;
      }
    }

    &-value {
      max-width: 100%; // ellipsis handling
      padding-bottom: $global-baseline;

      &-line {
        // ellipsis handling begins...
        text-overflow: ellipsis;
        display: block;
        overflow-x: hidden;
        white-space: nowrap;
        // ...and ends
        &--icon:before {
          display: none;
        }

        // this is overriden by pattern-library-overrides.scss so !important is needed here!
        @include breakpoint(phone-large-and-down) {
          &--icon:before {
            display: none !important;
          }
        }

        &-addon {
          font-size: 13px;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__link-wrapper {
    color: inherit;
    display: block;

    &:hover {
      color: inherit;
    }

    // labels in mobile size inside anchor should follow inherited cursor
    label {
      cursor: inherit;
    }

    &--read-only {
      color: $ea-color-dark-grey;
    }
  }

  @include breakpoint(tablet-and-up) {
    @include tableLayoutPaddings;

    &--interactive:hover {
      box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: all 0.125s ease-out;
      transform: perspective(500px) translate3D(0, 0, 0.25rem);
    }
  }

  .of-icon {
    &--exclamation-mark {
      height: 28px;
      position: absolute;
      width: 28px;
      top: 1.5%;
      left: $global-baseline * 2;

      @include breakpoint(phone-large-and-down) {
        left: $global-baseline * 3;
      }
    }

    &--custom-image {
      height: 30px;
      float: left;
      position: absolute;
      @include breakpoint(tablet-and-up) {
        left: $global-baseline * 1.5;
      }
      @include breakpoint(phone-large-and-down) {
        left: $global-baseline * 2.5;
      }
    }
  }

  .search-result {
    padding-top: 10px;
    padding-left: 40px;

    @include breakpoint(phone-large-and-down) {
      white-space: initial;
      position: initial;
      padding-bottom: 5px;
      padding-top: 25px;
      padding-left: 0;
    }

    @include breakpoint(phone-large-and-down) {
      margin-bottom: 0;
    }
  }

  .sub-row {
    flex-basis: 100%;
    padding-top: 17px;
  }
}
