@import '../../common/style/styleguide';

.of-support-case-dialog {
  .of-formik-text-input {
    padding-top: 24px;
  }

  textarea {
    margin-top: 24px;
    height: 13rem;
  }

  .cancel-button {
    margin-left: 24px;
    color: #0019af;
    background: transparent;
    border-color: transparent;
  }
}
