@import '../../common/style/styleguide';

.ds-checkbox {
  input:indeterminate + .ds-checkbox__label .ds-checkbox__icon {
    background-color: $ds-color__blue-600--light;

    &:after {
      display: none;
    }

    svg {
      fill: $ds-color__white;
    }
  }
  input:disabled:indeterminate + .ds-checkbox__label .ds-checkbox__icon {
    background-color: $ds-color__neutral-400;
  }
}
