@import '../breakpoints.scss';
.overlay-modal {
  overflow-y: scroll;
  max-height: 90%;
  height: 90%;
  @include breakpoint(phone-large-and-down) {
    min-height: 100%;
    height: 100%;
  }
}
