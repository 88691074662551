@import '../../common/style/styleguide';

.of-selfservice {
  // Pad globally the self-service area
  padding-bottom: $global-baseline * 10;

  // Comboboxes break when using overflow:hidden on parent elements. Anywhere.
  .ea-fgrid__item {
    overflow: visible;
  }

  // React seemingly doesn't allow <a href> (attr without value) so this enables pointer for bare <a>
  a:hover {
    cursor: pointer;
  }

  // TODO, fix this with gutters in the grid
  @media only screen and (min-width: 640px) and (max-width: 1024px) {
    .ea-input {
      width: 95%;
    }
  }

  strong {
    font-weight: 600;
  }

  .of-header {
    padding-bottom: 0;
  }
}
