@import '../../common/style/styleguide';

.of-self-service-header {
  .auto-complete-container {
    margin: 5px 0 -10px -34px;
  }

  .ds-user-menu-person.ds-user-menu-section > div:nth-child(2) {
    flex-grow: 1;
  }

  &__search-active {
    position: sticky !important;
    top: 0;
    z-index: 1001;
  }

  .search-button__text {
    font-size: 12px;
  }

  @media (max-width: $breakpoint-l) {
    &__search-active {
      .of-omaelisa-search {
        padding: 16px 16px 0 16px;
        background: white;
      }

      .ds-navigation-bar {
        padding: 0 !important;
      }
    }

    &__hide-on-mobile {
      display: none !important;
    }
  }

  @media (max-width: 429px) {
    .search-button__text {
      display: none !important;
    }
  }

  .of-omaelisa-main-container {
    width: 100%;
    max-width: 820px;
  }

  @media only screen and (max-width: $breakpoint-l) {
    .of-omaelisa-main-container {
      max-width: none;
    }
  }
}

.of-omaelisa-search {
  display: flex;
  flex: 1 0 100%;
  position: relative;
  align-items: center;

  input {
    width: 100%;
    padding: 14px 44px;
    line-height: 32px;
    color: $ds-color__black;
  }

  svg {
    width: 25px;
    height: 25px;
    fill: $ds-color-_neutral-600--light;
  }

  .search-icon {
    position: absolute;
    padding-left: 14px;
  }

  .close-search-button {
    position: absolute;
    right: 22px;

    &:hover {
      background: none;
      border: none;
    }
  }

  @media only screen and (min-width: $breakpoint-l) {
    .close-search-button {
      right: 4px;
    }
  }
}

.search-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 1000;
}

@media only screen and (max-width: $breakpoint-s-max-width) {
  .search-backdrop {
    background-color: white;
  }
}
