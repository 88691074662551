@import '../../../common/style/styleguide';

.of-lead-form {
  margin: 6 * $ds-baseline auto;

  // 6/12 columns' width of the desktop content max width (1320px).
  // Pretty much the same as readability sweetspot of 75ch.
  max-width: 660px;

  .ds-checkbox {
    // For some inane reason checkbox has top margin, which doesn't collapse in our case so we have to remove it.
    margin-top: 0;

    label {
      @include text--s;
    }
  }
}
