@import '../../common/style/styleguide';

.of-device-checkout-listing-item {
  padding-block: 3 * $ds-baseline;

  .ds-grid__col {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @include breakpoint(tablet-and-up) {
      // This is basically 1fr (so the total is 12fr)
      gap: 8.33%;
      grid-template-columns: 2fr 9fr;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__text {
    display: grid;
    grid-template-columns: 6fr 1fr 5fr;
  }

  &__description,
  &__quantity,
  &__price {
    grid-column: 1/4;
  }

  @include breakpoint(tablet-and-up) {
    &__description {
      grid-column: 1/2;
    }

    &__quantity {
      grid-column: 2/3;
    }

    &__price {
      grid-column: 2/4;
      text-align: right;
    }

    &__quantity + &__price {
      grid-column: 3/4;
    }
  }

  &__disclaimer {
    grid-column: 1/4;
  }
}
