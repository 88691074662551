@import '../../common/style/styleguide';

// .ds-reset is used to fix the styles that get broken while the old styleguide is still being used with the new component library styles.
// this block can be removed after the migration when the old styleguide (react-patterns) are not in use anymore.
// code below fixes the many box-sizing settings that the .ds-reset messes up with.
.ds-reset {
  width: inherit;
  .ds-stepper {
    .ds-stepper__step {
      @include breakpoint(phone) {
        padding-left: 23px;
      }
    }
    .ds-stepper__step__content {
      max-width: 900px;
      box-sizing: inherit;

      @include breakpoint(tablet-and-down) {
        margin-right: calc(5% - 4px);
      }

      * {
        box-sizing: border-box;
      }

      .of-order-summary {
        box-sizing: border-box;
      }

      .of-fixed-bb-checkout {
        fieldset * {
          box-sizing: border-box;
        }

        .of-contact-or-purpose-of-use__grid * {
          box-sizing: border-box;
        }

        .of-contact-or-purpose-of-use__contact_dropdown {
          input,
          button,
          .ds-comboboxv2__listbox {
            box-sizing: border-box;
          }
        }
      }

      .of-formik-text-input {
        input {
          box-sizing: border-box;
        }
      }

      .of-formik-dropdown {
        button {
          box-sizing: border-box;
        }
      }

      .of-select-billing-account {
        input,
        button,
        .ds-comboboxv2__listbox {
          box-sizing: border-box;
        }
      }

      .of-create-billing-account * {
        box-sizing: border-box;
      }

      .of-campaign-voucher {
        input {
          box-sizing: border-box;
        }
      }

      .of-login {
        input {
          box-sizing: border-box;
        }
      }
    }
  }
}
