@import '../../../common/style/styleguide';

.of-change-owner-accordion--data-row {
  &:first-child {
    padding-top: 3 * $ds-baseline;
  }
  padding-bottom: 4 * $ds-baseline;

  .autocomplete-root {
    min-height: auto;
  }
}
