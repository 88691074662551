/* Make print class disappear from screen */
.printable {
  display: none;
}

/* During printing make only 'print' class and it's parents and children visible */
@media print {
  body * {
    display: none !important;
  }

  .printable,
  .printable * {
    display: block !important;
  }
}
