@import '../../common/style/styleguide';
/* This basically duplicates ds-modal styles so that we can be sure CL updates don't break our Modal */

// This is used as the backdrop, so we can make the modal scrollable in a sensible way
.of-modal {
  background: transparent;
  border: 0;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  inset: 0;
  margin: 0;
  max-height: unset;
  max-width: unset;
  // Try to block page scrolling; doesn't currently work, see
  // https://github.com/w3c/csswg-drafts/issues/3349#issuecomment-492721871
  overscroll-behavior: contain;
  padding: 0;
  position: fixed;
  width: 100vw;
  z-index: 1;

  &::backdrop {
    background: rgba(0, 0, 0, 0.1);
  }

  // 'Reset' font size to basic text
  @include ds-base-font-family;
  @include text--m;

  // Separate backdrop so we can give it a presentation role and keep linter happy
  &__backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  // This is the actual modal
  &__wrapper {
    background: $ds-color__white;
    border-radius: $ds-baseline * 0.5;
    box-sizing: border-box;
    left: 0;
    padding: ($ds-baseline * 5) ($ds-baseline * 3) ($ds-baseline * 3) ($ds-baseline * 3);
    @media only screen and (min-width: $breakpoint-s) {
      padding: ($ds-baseline * 6) ($ds-baseline * 5) ($ds-baseline * 5) ($ds-baseline * 5);
    }
    position: absolute;
    right: 0;
  }

  &--align- {
    &center {
      .of-modal__wrapper {
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &top {
      .of-modal__wrapper {
        margin: 36px auto 0 auto;
      }
    }
  }

  &--size- {
    &s,
    &m,
    &l {
      .of-modal__wrapper {
        max-width: 80vw;
        min-height: auto;
      }
    }
    &s {
      .of-modal__wrapper {
        width: $ds-baseline * 55;
      }
    }

    &m {
      .of-modal__wrapper {
        width: $ds-baseline * 82.5;
      }
    }

    &l {
      .of-modal__wrapper {
        width: $ds-baseline * 110;
      }
    }

    &xl {
      .of-modal__wrapper {
        // fallback to vh for browsers that don't support dvh
        height: 100vh;
        max-height: 100vh;
        @supports (height: 100dvh) {
          height: 100dvh;
          max-height: 100dvh;
        }
        max-width: 100vw;
        width: 100vw;
      }
    }
  }

  // non-h2 heading is completely up to whoever creates such a thing
  h2.of-modal__heading {
    @include h3;
    margin: 0 0 $ds-baseline * 2 0;
    padding: 0;
  }

  &__close-button {
    background: transparent;
    border: 0;
    border-radius: 4px; // $ds-form-element-radius;
    display: block;
    height: $ds-baseline * 3;
    margin: 0;
    padding: 0;
    position: absolute;
    right: $ds-baseline * 2;
    top: $ds-baseline * 2;
    width: $ds-baseline * 3;

    &:focus,
    &:focus-visible {
      outline: 2px solid $ds-color__blue-600;
      outline-offset: 1px;
    }

    svg {
      fill: $ds-color__neutral-500;
      outline: none;
    }
  }
}

@media only screen and (max-width: $breakpoint-s-max-width) {
  .of-modal--size- {
    &s,
    &m,
    &l,
    &xl {
      .of-modal__wrapper {
        max-width: 100vw;
        width: 100%;
      }
    }
  }
}

// Block scrolling (best effort, only works on newer browsers and even those have some edge cases)
// see https://github.com/whatwg/html/issues/7732
body:has(.of-modal[open]) {
  overflow: hidden;
}
