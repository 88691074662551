.of-product-grid-container {
  .center-items {
    text-align: center;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-sale {
    background-color: #f66000;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-special {
    background-color: #0019af;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge--out_of_stock {
    background-color: #f5f5f5;
    color: #303031;
  }

  .of-product-grid__sort .ds-dropdown__button .ds-icon {
    top: 50%;
  }
}

.category-list:hover {
  overflow-y: visible;
  overflow-x: clip;
}
