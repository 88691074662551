.of-open-cases-notification {
  .ds-disclaimer-content div,
  a {
    font-size: 14px;
    text-decoration: none;
    margin-right: 0;
  }
  .support-cases-link {
    margin: 12px 0 2px 0;
  }
  h4 {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 4px;
  }
}
