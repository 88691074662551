@import './breakpoints.scss';

.ds-button--color-success {
  background-color: #2da84f!important;
  border-color: #2da84f!important;
}
.ds-link--button-color-success {
  background-color: #2da84f!important;
  border-color: #2da84f!important;
}
.ds-background-color--videra-green {
  background-color: #2da84f;
}
a.ds-background-color--white:hover {
  color: black;
}

.ya-compress {
  max-width: 440px;
  margin: 0 auto;
  @include breakpoint(tablet-and-up){
    max-width: 604px;
  }

  @include breakpoint(laptop-and-up){
    max-width: 904px;
  }

  @include breakpoint(desktop){
    max-width: 1260px;
  }
}

.ya-compress-narrow {
  @extend .ya-compress;
  
  @include breakpoint(desktop){
    max-width: 1046.66px;
  }
}