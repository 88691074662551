.ds-shoppingcart-wrapper {
  .ds-shoppingcart__product-image {
    // Fixes portrait image centering, this can't be moved to CL
    > picture > img {
      display: block;
      margin: 0 auto;
    }
  }

  // Anchor color in product names is a bit jarring
  .ds-shoppingcart__product-name a {
    color: inherit;
  }

  .ds-shoppingcart__product-price {
    white-space: nowrap;
  }
}
