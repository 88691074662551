@import '../../common/style/styleguide';

.of-onboard-employee {
  div,
  .of-formik-text-input div {
    max-width: 100%;
  }

  .of-formik-text-input textarea {
    height: initial;
  }

  .ea-input__not-required {
    display: none;
  }

  .of-onboard-employee__file-upload {
    width: 100%;
  }
}
