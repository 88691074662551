@import '../../common/style/styleguide';

.of-employee-subscriptions {
  padding-bottom: $global-baseline * 10;

  h4 {
    padding-bottom: 0.3em;
    margin: 0;
    font-weight: 550;
    font-size: calc(16px + 2 * (100vw - 479px) / 921);
    color: $ea-color-dark-grey;
  }

  .of-empty-or-error {
    padding-bottom: 0;
  }

  &__info-subscriptions {
    padding-top: 30px;

    .strong {
      display: inline-block;
    }
  }
}
