@import '../../common/style/styleguide';

.of-customer-order-details {
  .of-customer-order-details-form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;

    h3 {
      flex-basis: 100%;
    }
    .ds-input {
      flex-basis: calc(50% - 10px);
    }
    .autocomplete-root {
      flex-basis: 100%;
    }
  }
  .of-table-wrapper .ds-table {
    th {
      border-bottom: solid 1px $ds-color__neutral-300;
    }

    td {
      @include breakpoint(tablet-and-up) {
        padding-bottom: 2 * $ds-baseline;
        padding-top: 2 * $ds-baseline;
      }
    }

    tr td {
      font-size: 2 * $ds-baseline;
      > a {
        align-items: center;
        display: flex;
      }
    }
  }

  &__sticky-footer {
    padding-bottom: $global-baseline;
    padding-top: 4 * $ds-baseline;

    display: flex;
    justify-content: flex-end;
    @include breakpoint(phone-large-and-down) {
      justify-content: center;
    }

    &__buttons {
      display: flex;
      align-items: center;
      @include breakpoint(phone) {
        flex-wrap: wrap;
      }

      .ds-button {
        width: 130px;
        max-height: 5 * $ds-baseline;
        margin-bottom: $ds-baseline;
        margin-top: $ds-baseline;
        margin-left: $ds-baseline;
        @include breakpoint(phone) {
          width: 100px;
        }
      }
    }

    &__text {
      display: flex;
      align-items: center;

      &__underline {
        text-decoration: underline;
      }
    }
  }

  &__content {
    &--contact-info {
      border-bottom: $global-border-normal solid $ea-color-smoke;
      border-top: $global-border-normal solid $ea-color-smoke;
    }

    &--customer-info {
      padding-bottom: 3 * $ds-baseline;

      > div,
      > h3 {
        padding-bottom: 3 * $ds-baseline;
      }

      > h3 {
        padding-top: 1.5 * $global-baseline;
      }
    }
  }

  &__business-account {
    padding-bottom: 3 * $ds-baseline;

    > h3 {
      padding-bottom: 1.5 * $global-baseline;
      padding-top: 1.5 * $global-baseline;
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
    }

    &__field {
      flex-basis: 100%;
      padding-right: 20px;

      @include breakpoint(tablet-and-up) {
        flex-basis: 50%;
      }

      .ea-input {
        max-width: 350px;
      }
    }

    &__select {
      flex-basis: 100%;
      max-width: 865px;

      .ea-input {
        width: 100%;
      }
    }
  }
}
