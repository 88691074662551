@import '../../../../common/style/styleguide';

.of-dns-history-details {
  h5 {
    color: $ds-color__neutral-600;
  }

  pre {
    border: 1px solid $ds-color__neutral-300;
    background: $ds-color__neutral-100;
    padding: 1em;
    border-radius: 0.2em;
    color: $ds-color__neutral-600;
  }

  hr {
    height: 1px;
    background-color: $ds-color__neutral-300;
    border: none;
  }
}
