@import '../../common/style/styleguide';

.of-pbx-ring-settings-accordion {
  .row {
    display: flex;
    width: 100%;
  }
  .fifty {
    flex: 0 0 50%;
  }
  .seventy-five {
    flex: 0 0 75%;
  }
  .twenty-five {
    flex: 0 0 25%;
  }
}
