@import '../../common/style/styleguide';

.of-pbx-number-settings {
  padding-bottom: 3 * $global-baseline;
  padding-top: 3 * $ds-baseline;

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include breakpoint(tablet-and-up) {
      margin-bottom: 2 * $ds-baseline;
    }
  }

  &__row-item {
    width: 34.5 * $ds-baseline;
    flex-grow: 1;

    &:not(:last-child) {
      padding-right: 2.5%;
    }

    &__hidden {
      display: none;
    }

    .ds-dropdown {
      width: 34.5 * $ds-baseline;
    }

    &.narrow {
      .ds-dropdown {
        width: 13 * $global-baseline;
      }
    }
  }

  &__connect-to-number {
    padding-left: 4 * $ds-baseline;
    margin-top: -3 * $ds-baseline;

    .ds-input {
      width: 22 * $ds-baseline;
    }
  }

  &__actions {
    display: flex;
    @include breakpoint(tablet-and-up) {
      padding-top: 4.5 * $ds-baseline;
    }

    & > button:not(:last-child) {
      margin-right: 3 * $ds-baseline;
    }
  }
}
