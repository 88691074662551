@import '../../common/style/styleguide';

.of-employee-device-store-products {
  &__border-bottom {
    border-bottom: 1px solid $ds-color__neutral-300;
  }

  .of-hero-heading {
    @include breakpoint(tablet-and-up) {
      height: 18 * $global-baseline;
    }

    .of-hero-heading__wrapper .of-hero-heading__header-container {
      .ea-h1,
      p {
        text-transform: unset;
        font-family: $global-font-normal;
      }

      .ea-h1 {
        font-size: 3 * $global-baseline;
        font-weight: 600;
        line-height: 4 * $global-baseline;
      }

      p {
        color: #404041;
        font-size: 1.5 * $global-baseline;
        line-height: 2 * $global-baseline;
        font-weight: 300;
      }
    }
  }
}
