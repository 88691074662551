@import '../../common/style/styleguide';

.of-order-product-details {
  &__phaseindicator-container {
    display: flex;
    justify-content: center;
    background-color: $ea-color-white-smoke;
    border: 1px solid $ea-color-smoke;
    margin-bottom: 5 * $global-baseline;
    padding: 2.5 * $global-baseline 30px 1.5 * $global-baseline 30px;
  }

  .ea-fgrid {
    min-height: 0;
  }

  &__product-row {
    border-top: 1px solid $ea-color-smoke;
    line-height: 36px;
    padding-bottom: $global-baseline;
    padding-top: $global-baseline;

    @include breakpoint(tablet-and-up) {
      padding-bottom: 0;
      padding-top: 3 * $global-baseline;
    }

    .ea-h4 {
      display: inline-block;
      padding: 0;
    }

    p {
      line-height: 24px;
      margin: 0;
    }
  }

  .ea-composite-list__rows {
    padding-top: $global-baseline;
  }

  &__product-row-opening-fee {
    @include breakpoint(tablet-and-up) {
      margin-top: -8px;
    }
  }

  &__product-row-image {
    @include breakpoint(tablet-and-up) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .ea-picto {
      height: 80px;
      max-width: 100%;
      @include breakpoint(tablet-and-up) {
        height: 93px;
      }
    }
  }

  &__actions {
    padding-bottom: 3 * $global-baseline;
  }

  &__quantity-and-price {
    @include breakpoint(tablet-and-up) {
      text-align: right;
    }
  }

  &__spacer-column {
    @include breakpoint(tablet-and-down) {
      display: none;
    }
  }

  &__checkout-details-container {
    border-width: 1px;
    border-color: $ds-color--neutral-400;
    border-style: solid;
  }

  .of-purpose-of-use {
    @include breakpoint(tablet) {
      margin-right: 6%;
    }

    .ea-fgrid__item--tablet-6 {
      @include breakpoint(tablet) {
        padding-right: 0;
      }
    }
  }

  .of-select-phone-number {
    padding-top: $global-baseline * 3;
  }

  .of-enable-ring {
    padding-top: $global-baseline * 3;
  }

  .of-attach-ring {
    padding-top: $global-baseline * 3;
  }

  .of-select-ring {
    > h4 {
      padding-bottom: $global-baseline;
    }
  }

  .phone-number-owner-person-email,
  .of-select-phone-number,
  .ea-fgrid__item {
    @include breakpoint(tablet) {
      max-width: 100%;
    }
  }

  &__addon-selections {
    div {
      overflow: inherit;
    }
  }
}
