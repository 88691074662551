@import '../../../../../common/style/styleguide';
.of-company-info-contacts-path {
  &__notification {
    margin: 40px 0 0;
    .ds-notificationbanner-content p {
      line-height: unset;
    }
  }

  &__button {
    width: 200px;
    margin-left: $ds-baseline;
    margin-top: 0em;

    @media screen and (max-width: $breakpoint-m-max-width) {
      margin-top: 1em;
    }
  }

  &__headline,
  &__source {
    margin: 0;
  }

  &__instructions {
    margin-top: $ds-baseline * 4;
    margin-bottom: $ds-baseline * 4;
  }

  &__source {
    &-table {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $breakpoint-m-max-width) {
        flex-direction: column;
      }
    }
  }

  &__headline-and-button {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 0;
    flex-direction: column;
    @media screen and (max-width: $breakpoint-m-max-width) {
      flex-direction: column;
    }
  }

  .of-contacts-list {
    margin: 24px 0 48px 0;

    .of-table-content td {
      height: 4em;
    }
  }

  .ds-notificationbanner-content {
    a,
    p {
      font-size: 16px;
    }
  }
}
