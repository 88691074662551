@import '../../common/style/styleguide';

.ea-composite-list-header {
  // The header isn't visible at all on mobile unless the mobile table layout is set or select all option is not enabled
  @include breakpoint(phone-large-and-down) {
    &:not(.ea-composite-list-header--mobile-table-layout) {
      display: none;
    }
  }

  border-bottom: $global-border-normal solid $ea-color-smoke;
  padding: 0 $global-baseline;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  font-size: 13px;

  &--tight {
    padding-left: 0;
    padding-right: 0;
  }

  &--checkListLayout {
    border-bottom: none;
  }

  &--selectAll {
    padding: 0;
  }

  &__column {
    // The column isn't visible at all on mobile unless the mobile table layout is set
    @include breakpoint(phone-large-and-down) {
      &:not(.ea-composite-list-header__column--mobile-table-layout) {
        display: none;
      }
    }

    flex-grow: 1;
    flex-basis: 20%;
    padding: $global-baseline ($global-baseline * 1.5) $global-baseline ($global-baseline * 1.5);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-heading {
      display: inline-block;
      position: relative;

      &--sortable {
        padding-right: 14px + calc($global-baseline / 2);
      }

      label {
        font-size: 13px;
      }
    }

    &-postfix-icon {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-flex;
      align-items: center;
      height: 100%;

      &:before {
        margin: 0 !important;
        width: 14px !important;
        height: 14px !important;
      }
    }

    &--sortable {
      cursor: pointer;

      &:hover span {
        visibility: visible;
      }
    }

    &--wide {
      flex-grow: 8;
    }

    &--semi-wide {
      flex-grow: 4;
    }

    /* The combobox menu */
    .ea-input {
      margin: -1px 0 0;
      padding: 0;
      width: auto;

      .ea-input__wrapper {
        background-color: transparent;
        border: 0 none;
        margin-right: 16px;

        .ea-input__input {
          padding: 0;

          span {
            font-size: 13px;
          }
        }
      }

      .ea-combobox {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .ea-combobox {
        &--dropdown {
          .ea-combobox__input:after {
            font-size: 16px;
          }
        }
      }
    }
  }

  .ds-checkbox {
    margin: 0;

    label {
      font-weight: 600;
      font-size: 13px;
      line-height: 1.5em;
    }
  }

  .ds-dropdown {
    &.light {
      margin: 0;

      button {
        margin: 0;
        padding: 0;
        height: auto;
        min-height: unset;
        font-weight: 600;
        font-size: 13px;
        border: none;
        outline: none;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      .ds-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
