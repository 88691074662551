@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

.of-public-mobile-voice-subscriptions .of-public-mobile-voice-subscriptions-campaign {
  width: 100%;
  grid-column: 1/4;

  // Carousel arrow buttons closer to edges for mobile for more content space
  .ds-carousel__button--primary > span {
    margin: $ds-baseline;
    @include breakpoint(laptop-and-up) {
      margin: 2 * $ds-baseline;
    }
  }

  @media screen and (min-width: 1328px) {
    grid-column: 2/3;
  }

  &__item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;

    &::before {
      @include breakpoint(phone-large-and-down) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ds-color__black, 0.4);
      }
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 300px;

    @include breakpoint(tablet-and-up) {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__subheading {
    margin: 0;
    font-size: 14px;

    @include breakpoint(phone-large-and-down) {
      display: none;
    }
  }

  &__content {
    z-index: $zindex2;
    position: absolute;
    color: $ds-color__white;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8 * $ds-baseline;

    h3,
    p {
      margin: $ds-baseline 0;
    }

    @include breakpoint(tablet-and-up) {
      h3 {
        font-size: 30px;
      }
      padding: 0 10 * $ds-baseline;
      width: 40%;
      align-items: flex-start;
      text-align: left;
    }
  }

  &__description {
    > p {
      font-size: 14px;

      @include breakpoint(tablet-and-up) {
        font-size: $global-base-font-size;
      }
    }
  }

  &__submit {
    margin-top: 2 * $ds-baseline;
    width: auto;

    &--desktop {
      display: none;

      @include breakpoint(tablet-and-up) {
        display: block;
      }
    }

    &--mobile {
      display: block;
      text-align: center;
      margin-top: $ds-baseline;

      @include breakpoint(tablet-and-up) {
        display: none;
      }
    }
  }
}

.of-public-mobile-voice-subscriptions {
  @include global-mixins.page-margins-as-grid;

  > aside,
  > section {
    padding-bottom: 5 * $ds-baseline;
    padding-top: 5 * $ds-baseline;
    text-align: left;

    h2 {
      padding-bottom: 0.5em;
    }

    > div {
      margin: 0 auto;
    }
  }

  > nav:first-child,
  .of-page-margins--as-padding {
    @include global-mixins.page-margins-as-grid-bleed;
  }

  &__subscriptions {
    .of-subscription-cards {
      @include breakpoint(desktop) {
        grid-template-columns: repeat(3, 1fr);
      }

      & h2 {
        text-align: center;
      }

      @include breakpoint(phone-large-and-up) {
        .of-subscription-card {
          max-width: 430px;
        }
      }
    }

    h2 {
      text-align: left;
    }
  }

  section.of-public-mobile-voice-subscriptions__ingress {
    padding-top: 2 * $ds-baseline;
    p {
      margin: 0 auto 2 * $ds-baseline auto;
      // Magic number to match Figma text width
      max-width: 780px;
      text-align: center;
    }
  }

  .of-public-mobile-voice-subscriptions__contact {
    background: $ds-color__neutral-200--light;

    > h2 {
      text-align: center;
    }

    > div {
      display: grid;
      gap: 80px;

      @include breakpoint(laptop-and-up) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  header {
    text-align: center;

    > h1 {
      margin-top: 32px;
    }
  }

  .of-public-mobile-voice-subscriptions__marketing-block {
    > div {
      align-items: center;
      display: grid;
      gap: 6 * $ds-baseline;
      grid-template-columns: 1fr;
      max-width: 604px;
      text-align: left;

      img,
      picture {
        text-align: center;
        width: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
      }

      @include breakpoint(laptop-and-up) {
        grid-template-columns: 4fr 6fr;
        max-width: 1400px;

        $block-text-ratio: 0.6;
        > div {
          grid-column: 2;
          grid-row: 1;
        }

        > img,
        > picture {
          grid-column: 1;
          grid-row: 1;
          text-align: left;
        }
      }
    }

    &.of-public-mobile-voice-subscriptions__marketing-block--inverse {
      > div > picture {
        text-align: right;
      }
      @include breakpoint(laptop-and-up) {
        > div {
          grid-template-columns: 6fr 4fr;

          > div {
            grid-column: 1;
          }

          > img,
          > picture {
            grid-column: 2;
          }
        }
      }
    }
  }

  #yritysliittymat_additional_services {
    padding: 0;
  }

  .of-public-mobile-voice-subscriptions__avainlippu {
    > div {
      @include breakpoint(laptop-and-up) {
        // Make the first column just fit the image
        grid-template-columns: 313px 1fr;
        margin: 0 auto;
        // Limit the width so the text just fits on two lines
        max-width: 1040px;
      }
    }
  }

  .ds-accordion {
    margin-top: 6 * $ds-baseline;

    .ds-list--unordered {
      margin-bottom: 2 * $ds-baseline;
      padding-left: 2 * $ds-baseline;
    }

    .ds-accordion__section__heading--title {
      white-space: break-spaces;
    }
  }

  .of-modal {
    h2.of-modal__heading::first-line {
      // FIXME text mixins are broken in CL (9.5.0), so remove our font-family rule once those are fixed
      @include h4;
      font-family:
        Jost Medium,
        sans-serif;
    }

    h2.of-modal__heading {
      white-space: pre-line;
      // FIXME text mixins are broken in CL (9.5.0), so remove our font-family rule once those are fixed
      @include text--display;
      font-family:
        Jost Bold,
        sans-serif;
    }
  }
}

aside.of-internet-subscriptions-banner.of-internet-subscriptions-banner {
  background: #{desaturate(lighten($ds-color__turquoise-400, 35%), 10%)};
  display: flex;
  flex-direction: column-reverse;
  gap: 2 * $ds-baseline;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 80px;
  max-width: 1200px;
  padding: 2 * $ds-baseline 4 * $ds-baseline;

  > div:first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    text-align: center;

    > a {
      white-space: nowrap;
    }
  }

  > div:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 0;
    width: 150px;

    img {
      height: 150px;
      width: 150px;
    }
  }

  @media screen and (min-width: 900px) {
    flex-direction: row-reverse;
    gap: 4 * $ds-baseline;
    margin-bottom: 4 * $ds-baseline;
    margin-top: 4 * $ds-baseline;
    overflow-y: hidden;

    > div:first-child {
      align-items: center;
      gap: 0;
      padding-bottom: 2 * $ds-baseline;
      padding-top: 3 * $ds-baseline;
      text-align: left;

      h2 {
        margin-top: 0;
      }

      p {
        margin-bottom: 0;
      }

      > a {
        align-self: flex-end;
      }
    }

    > div:nth-child(2) {
      align-items: center;
      display: flex;
    }
  }

  @media screen and (min-width: 1140px) {
    height: 180px;

    > div:first-child {
      flex-direction: row;
      gap: 4 * $ds-baseline;

      p {
        max-width: 60ch;
      }
    }
  }

  @media screen and (min-width: 1328px) {
    // We have to use auto from this point up, as the width is limited
    margin-left: auto;
    margin-right: auto;
  }
}
