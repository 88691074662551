@import '../breakpoints.scss';
.overlay-modal {
  overflow-y: scroll;
  max-height: 90%;
  height: 90%;
  @include breakpoint(phone-large-and-down) {
    min-height: 100%;
    height: 100%;
  }
}

.form-button-alignment--left {
  display: flex;
  justify-content: left;
}
.form-button-alignment--right {
  display: flex;
  justify-content: right;
}
.form-button-alignment--center {
  display: flex;
  justify-content: center;
}

// This is a hack until we remove our Fieldset -> LUUKKU-14691
.ds-inputhelp.inputhelp-fixer {
  margin-top: -16px;
}
