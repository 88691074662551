@import '../../common/style/styleguide';

.of-list-paging-options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
  padding-top: 24px;

  @media only screen and (min-width: $breakpoint-m) {
    justify-content: space-between;
    flex-direction: row;
    row-gap: 0;
  }
}
