@import '../breakpoints.scss';
.marketing-squares {

  .image-aspect--ratio {
    img {
      aspect-ratio: 1/1;
    }
  }

  .grid--full-width {
    width: 100%;
  }

  @media only screen and (min-width: 1400px){
    .ds-grid__row {
      margin: 0 auto;
      max-width: inherit;
    }
  }

  .custom-display-mobile {
    @include breakpoint(phone-large-and-down) {
      display: block;
    }
  }

  .link-container {
    cursor: pointer;
    position: relative;

    &.link-container--transparent-light-text {
      color: #ffffff;
    }

    &.link-container--transparent-dark-text {
      color: #404041;
    }

    &.link-container--animated {
      .container {
        position: relative;
        margin-bottom: -6px;

        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }

      .container__background-image {
        width: 100%;
        margin-bottom: -6px;
        position: relative;
        display: inline-block;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.65)), color-stop(50%, rgba(0, 0, 0, 0)));
          background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 50%);
        }

        img {
          max-width: 100%;
          width: 100%;
          height: auto;
          display: block;
          transform: scale(1.04);
          will-change: transform;
          background-size: cover;
          -webkit-transition: -webkit-transform 1.1s ease .3s;
          transition: -webkit-transform 1.1s ease .3s;
          transition: transform 1.1s ease .3s;
          transition: transform 1.1s ease .3s, -webkit-transform 1.1s ease .3s;
        }
      }

      .text-container {
        position: absolute;
        bottom: 0;
      }

      .link-container__gridtext {
        will-change: transform;
        transition: transform .5s ease .3s;
        transform: translate3d(0, 1.01em, 0);
      }

      .link-container__cta {
        opacity: 0;
        transition: all .4s ease .4s;
      }

      &:hover {
        .link-container__gridtext {
          transform: translate3d(0, 0.01em, 0);
        }

        .link-container__cta {
          opacity: 1;
        }

        .container__background-image {
          img {
            -webkit-backface-visibility: hidden;
            cursor: pointer;
            transform: translateZ(0) scale(1.01)
          }
        }
      }
    }
  }
}



