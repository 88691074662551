@import '../../common/style/styleguide';

.of-accountmanager-details-card {
  .ds-card-content {
    padding-top: 0;
  }

  .pin-icon {
    margin-right: 4px;
    position: relative;
    top: 2px;

    svg {
      fill: currentColor;
    }
  }

  .ds-link-last-object {
    padding-left: 4px;
    font-size: 1.25rem;
  }
}
