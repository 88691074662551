@import '../../common/style/styleguide';

.of-purpose-of-use {
  &__contact_dropdown {
    width: 380px;
    height: 120px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }

    // This hides the Valitse/select from the combobox options (CL.Comboboxv2 adds that by default)
    li:not([data-value]) {
      display: none;
    }
  }

  .ds-button {
    min-width: 150px;
  }

  &__purpose_of_use_field {
    width: 380px;
    height: 120px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
  }

  &__cost_center_employee_number {
    .ds-input--labelarea-label {
      font-size: smaller;
    }
  }

  &__grid_col {
    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }

  &__grid_row {
    .ds-input {
      @include breakpoint(tablet-and-up) {
        max-width: 380px;
      }
    }

    .ds-grid__row {
      @include breakpoint(phone-large-and-down) {
        display: block;
        width: 100%;
      }
    }

    @include breakpoint(tablet-and-up) {
      > :nth-child(odd) {
        padding-right: $ds-baseline * 1.5;
        padding-left: 0;
      }

      > :nth-child(even) {
        padding-left: $ds-baseline * 1.5;
        padding-right: 0;
      }
    }
  }

  &__select-group {
    width: 380px;
    @include breakpoint(phone) {
      .ds-selectgroup__list {
        // The items get too narrow without this.
        grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
      }
    }
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
  }

  .of-formik-text-input {
    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }
}
