@import '../../common/style/styleguide';

.of-empty-or-error {
  &__top:before {
    content: '';
    display: block;
    height: 150px;
    margin-bottom: $global-baseline * 2;
    @include breakpoint(tablet-and-up) {
      height: 270px;
    }
  }
  &__top--emptystage:before {
    background: transparent url('../../common/svg/art-emptystage.svg') center top no-repeat;
  }
  &__top--server-error:before {
    background: transparent url('../../common/svg/art-server-error.svg') center top no-repeat;
  }
  &__top,
  &__middle,
  &__bottom {
    display: block;
    text-align: center;
    width: 100%;
  }

  &__top,
  &__middle,
  &__bottom {
    padding-top: $global-baseline * 2;
  }
  &__top {
    color: $ds-color__brand-blue;
    @include breakpoint(tablet-and-up) {
      padding-top: $global-baseline * 8;
    }
  }
  &__bottom {
    color: $ds-color__neutral-600;
  }
}
