@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

.of-yja-offer-block.of-yja-offer-block {
  @include global-mixins.page-margins-as-grid-bleed;
  margin: 0 auto;
  max-width: 100%;

  &:focus-visible {
    outline: $ds-focus-outline;
    outline-offset: $ds-baseline * 0.5;
  }

  img,
  picture {
    display: block;
    max-width: 100%;
  }
}
