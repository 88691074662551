@import '../../common/style/styleguide';

.of-case-list {
  @media only screen and (min-width: $breakpoint-m) {
    div[class^='ds-grid__col']:first-of-type {
      max-width: 370px;
    }
  }

  .ds-table tr td {
    > a {
      display: block;
    }

    // status circle
    .ds-icon {
      display: inline-block;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .additional-field-content {
    font-size: 13px;
    line-height: 1.4em;
  }

  .of-all-accounts-toggle {
    margin-top: $ds-baseline * 3;

    @media only screen and (min-width: $breakpoint-m) {
      margin-top: 0;
    }
  }
}
