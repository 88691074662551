.of-epp-maintenance-disclaimer {
  .ds-disclaimer-content {
    padding: 20px;
    h4 {
      font-family: 'Open Sans', Arial, sans-serif;
      font-weight: 600;
      padding-bottom: 20px;
    }
    p {
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 16px;
      line-height: 1.4em;
    }
  }
  &--link {
    margin-top: 1em;
    float: right;
  }
}
