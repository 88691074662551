@import '../../common/style/styleguide';

.of-sim-card {
  .ds-description {
    margin: 0;

    .ds-description__list-item {
      margin: 0;
    }
  }

  &__new {
    label {
      display: block;
      font-weight: 600;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
  }

  &__button1 {
    margin-right: 2em;
    margin-bottom: 2em;
  }

  &__button2 {
    margin-bottom: 2em;
  }

  &__notification {
    margin: 2em;
    padding: 2.4rem 4.8rem;
    background-color: $ea-color-white-smoke;
  }

  &__notification-text {
    width: 100%;
  }
}
