@import '../../common/style/styleguide';

.of-order-newsletter {
  iframe {
    border: 0;
    margin-top: $ds-baseline;
    height: 200px;
    width: 100%;
  }

  @include breakpoint(phone-large-and-down) {
    .ds-grid__col {
      &:first-child {
        // Try to match our text to the iframe contents
        padding-left: 12px;
      }

      &:nth-child(2) {
        padding-left: 0;
      }
    }
  }
}
