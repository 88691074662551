@import '../../common/style/styleguide';

.ea-wizard-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include breakpoint(phone) {
    &--forward-wide-on-phone {
      .ea-wizard-actions__back {
        padding-left: 10px;
        padding-right: 30px;
        margin-right: 0;
      }

      .ea-wizard-actions__forward {
        min-width: 170px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
