@import '../../common/style/styleguide';

.of-order-subscription-selection {
  position: relative;

  .of-product-selector-container {
    height: auto;
  }

  .of-campaign-voucher {
    margin: 0;

    @include breakpoint(tablet-and-up) {
      justify-content: flex-end;
    }
  }

  .of-subscription-header {
    border-bottom: 1px solid $ea-color-smoke;
    padding-bottom: $global-baseline;
  }

  .ea-product-card__header {
    .ds-badge {
      float: right;
      margin-right: 8px;
    }
  }

  .of-order-price {
    left: 0;
    padding-top: $global-baseline;
  }

  .of-order-amount {
    right: 0;
    padding-top: $global-baseline;
    padding-bottom: $global-baseline;
    @include breakpoint(laptop-and-up) {
      position: absolute;
    }
  }

  .sticky-footer-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: $ds_baseline * 2 0;
    @media only screen and (min-width: $breakpoint-m) {
      justify-content: flex-end;
    }

    label {
      font-weight: 600;
    }

    .ds-quantity {
      margin: 0 16px;
      flex-wrap: nowrap;
    }
  }

  &__disclaimer-text {
    border-top: 1px solid $ds-color__neutral-300;
    padding-top: 3 * $ds-baseline;
  }

  &__show-more,
  &__disclaimer-text {
    margin: 0 $ds-baseline;
    @media only screen and (min-width: $breakpoint-m) {
      margin: 0 $ds-baseline;
    }
  }

  .of-minute-based-subscriptions {
    .of-dropdown {
      width: 400px;
    }
  }

  &--voucher-notification {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    div.ds-notificationbanner {
      border-radius: 4px;
      background-color: $ds-color__blue-100;
    }
  }
}
