.width {
  &--full {
    width: 100%
  }
  &--half {
    width: 50%
  }
}
.max-width {
  &--half {
    max-width: 50%
  }
  &--full {
    width: 100%
  }
}
.height {
  &--full {
    height: 100%
  }
  &--half {
    height: 50%
  }
}
.max-height {
  &--half {
    height: 50%
  }
  &--full {
    height: 100%
  }
}
.vertically-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.bodytext--two-lines {
  height: 3.25em;
}
.font-weight {
  &--600 {
    font-weight: 600;
  }
}
.elisa-navi-ea-footer {
  ul {
    margin: 0px;
  }
}

a[href*=".pdf"]:not(.ea-button):not(.remove-pdf-image):not(.react-navi-ea-profile-link *):not(.ds-button):not(.ds-link) {
  background-image: url("//static.elisa.fi/components/images/elisa-cms/icon_pdf_grey.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 34px;
  line-height: inherit;
  width: auto;
  display: inline-block;
}
.page-scroll {
  overflow-y:hidden;
}
