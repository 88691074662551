@import '../../../common/style/styleguide';

.of-product-details-payment {
  .ds-selectgroup__list {
    // The items get too narrow without this.
    grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
  }
  &__label {
    display: inline;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 4px 0 0;
    padding: 0;
  }

  &__info {
    display: block;
    font-size: 13px;
    line-height: 18px;
    margin: $global-baseline 0;
  }
}
