@import '../../common/style/styleguide';

.of-employee-device-store {
  .ds-table {
    tr {
      td:first-of-type {
        color: $ds-color__blue-600;

        &::before {
          color: $ds-color__blue-800;
        }
      }
    }
  }
}
