@import '../../common/style/styleguide';

.of-pbx-time-settings {
  padding-bottom: 3 * $global-baseline;
  padding-top: 3 * $ds-baseline;

  &__item {
    padding-bottom: 2 * $ds-baseline;
    max-width: 60 * $ds-baseline;
  }

  &__section-title {
    display: flex;
    align-items: center;
    margin-bottom: $ds-baseline;

    .ds-popover-holder {
      margin-left: $ds-baseline;
    }
  }

  &__section {
    padding-bottom: 6 * $ds-baseline;
  }

  &__working-hours > :first-child {
    padding-bottom: 1.5 * $ds-baseline;
  }

  &__actions {
    width: 100%;
    margin-top: 6 * $ds-baseline;

    button:first-child {
      margin-right: 4 * $ds-baseline;
    }
  }

  h4 {
    margin: 0 0 $ds-baseline 0;
  }
}
