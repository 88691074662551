@import '../../common/style/styleguide';

.of-epp-maintenance {
  &__service-announcement {
    &-buttons {
      display: flex;
      justify-content: right;
    }

    &-header {
      font-size: larger;
      padding-bottom: $global-baseline;
    }

    hr {
      margin-bottom: 40px;
      margin-top: 40px;
    }

    p {
      font-weight: 600;
    }

    ul {
      list-style-type: disc;

      > li {
        margin-left: 20px;
        padding: 6px;
      }
    }
  }
}
