.description-block {
  &__wrapper {
    margin: 0 auto;
    max-width: 1400px;
    height: 100%;
    padding: 0 5%;
  }

  &__flex-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .rich-text {
    ul {
      margin-left: 1.3em;
      margin-bottom: 12px;
      list-style-type: disc;

      li {
        padding-bottom: 6px;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      margin: 0 0 0.4em 0;
    }
  }
}
