@import '../../common/style/styleguide';

.of-progress-bar {
  height: 16px;
  background: $ea-color-white-smoke;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  border-radius: 8px;

  div {
    background-color: $ea-color-linkblue;
    height: 100%;
    border-radius: 8px;
    animation: progress 1.5s ease-in-out forwards;

    @keyframes progress {
      from {
        width: 0;
      }
      to {
        /**
         * CSS limitations doesn't allow using keyframes in inline css,
         * setting up max-width in the component and here as 100%.
         */
        width: 100%;
      }
    }
  }
}
