.free-html--default {

  table {
    overflow-x: auto;
    display: block;
  }
  table thead th {
    font-weight: bold;
    padding: 7px;
    vertical-align: top;
  }
  table td {
    padding: 7px;
    vertical-align: top;
  }
  table tr.highlight {
    font-weight: bold;
  }
  table tr:nth-child(even) {
    background: #EFEFEF;
  }
  table tr:nth-child(odd) {
    background: #FEFEFE;
  }

  .border-1px {
    border-style: solid;
    border-width: 1px;
    border-color: #b0b0b1;
  }

  }
