@import '../../common/style/styleguide';

.of-epp-maintenance {
  .ds-selectgroup {
    padding: 0 0 5 * $ds-baseline 0 !important;
  }
  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }
  // Override design system default style for select group and enable wrapping text to multiple lines,
  // otherwise text will overflow from selection tab on mobile
  .ds-selectgroup__list-item__text__name {
    white-space: normal;
  }
}
