.bulletins {
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #d0d0d1;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #767677;
    }
    .items {
      height: 525px;
      min-height: 300px;
      background: #ffffff;
      overflow-x: hidden;
      overflow-y: auto;

      .item {
        width: calc(100% - 30px);
        cursor: pointer;
        position: relative;
        padding: 10px;
        color: #2f393f;

        .triangle {
          position: absolute;
          top: 50%;
          right: -10px;
          margin-top: -15px;
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 15px solid transparent;
        }

        &.selected {
          border-radius: 5px;
          background: #e9e9e9;

          .triangle {
            border-left: 10px solid #e9e9e9;
          }
        }

        .title {
          color: #0068F0
        }
      }
    }
  .center-align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
