@import '../../common/style/styleguide';

.of-postal-code {
  &__wrapper {
    @include breakpoint(laptop-and-up) {
      width: 70%;
    }
  }

  &__check-icon {
    right: 1rem;
    top: $ds-baseline * 5;
  }
}
