.full-page-video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background: black;

  &__centered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__close-link {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 2.5px;
    outline: none;
    background: white;
    cursor: pointer;
    &:active {
      border: none;
      outline: none;
      background: transparent;
    }
    &:focus {
      outline: none;
      box-shadow:  0px 0px 0px 4px rgba(0, 106, 237, 0.6);
    }
    svg {
      outline: none;
      fill: #8D8D95;
    }
  }

  &__player {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    width: 100%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
}
