@import '../../common/style/styleguide';

.of-fixed-broadband-order {
  position: relative;

  &__thumb-up {
    width: 60px;
    height: 60px;
    display: inline-block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../common/svg/thumb-up.svg');
    background-size: contain;
  }

  .of-empty-or-error__top {
    padding-top: 0;
  }
}
