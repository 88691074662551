@import '../../common/style/styleguide';

.of-custom-form {
  margin: 4 * $global-baseline 0;

  .title {
    margin-bottom: $global-baseline;
  }

  .ds-grid__row {
    box-sizing: border-box;
    padding: 2 * $global-baseline;
  }

  .ds-checkbox__label {
    width: 100%;
  }

  .of-custom-form-radio {
    > label {
      display: flex;
      width: 100%;

      > span:not(.ds-radio__icon) {
        flex: 1;
        text-align: right;
      }
    }
  }

  h3 {
    margin: 0;
    padding-bottom: 0.2em;
  }

  &__prices {
    margin: (4 * $global-baseline) 0 (4 * $global-baseline) 0;

    &-title {
      margin-bottom: 2 * $global-baseline;
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-disclaimer {
      margin: 0;
      float: right;
    }
  }

  &__quantities {
    margin-bottom: (4 * $global-baseline);

    &-quantity {
      display: flex;
      justify-content: space-between;
      white-space: pre-line;
      width: 100%;

      div {
        margin-bottom: $global-baseline;
      }
    }

    .ds-quantity {
      flex-wrap: nowrap;
    }
  }

  &__radios {
    margin-bottom: (4 * $global-baseline);

    &-radio {
      .of-formik-radio {
        float: left;
      }
      &-children {
        float: right;
        padding: 5px 0 12px;
      }
      &-compensator {
        display: flex;
        width: 100%;
      }
    }
  }
}
