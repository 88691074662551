@import '../../common/style/styleguide';

.of-mfa-otp-form {
  padding: $ds-baseline * 3;
  max-width: $ds-baseline * 59;

  .picto {
    margin: 0 0 $ds-baseline 0;
    height: $ds-baseline * 8;
    width: $ds-baseline * 8;
    background: transparent url('../../common/svg/picto-lock.svg') left top no-repeat;
  }

  .otp-input {
    width: $ds-baseline * 20;
    letter-spacing: calc($ds-baseline / 2);
  }

  input[type='submit'] {
    width: 100%;
  }

  @media screen and (min-width: $breakpoint-s) {
    width: 100%;
    padding: $ds-baseline * 5;
  }
}
