@import '../../common/style/styleguide';

.of-subscription-action-list {
  & .subscription-display-id {
    line-height: 1.5 * $global-baseline;
    & .ea-composite-list-row {
      &__cell-value-line--icon:before {
        bottom: 2 * $global-baseline;
      }
    }
  }
  & .request-status {
    display: block;
    font-size: 1.2 * $global-baseline;
    color: $ea-color-grey;
  }
  & .ea-composite-list-row__cell-value-line {
    overflow: hidden;
  }
}
