@import '../../common/style/styleguide';

.of-modal__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2 * $ds-baseline;
  justify-content: flex-end;
  margin-top: 4 * $ds-baseline;

  @media only screen and (min-width: $breakpoint-s) {
    flex-direction: row;
  }
}
