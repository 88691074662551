@import '../../common/style/styleguide';

.of-delivery-order-details {
  &--section {
    border-bottom: $global-border-normal solid $ea-color-smoke;
    padding: $global-baseline * 1.5 0;
    &:last-child {
      border-bottom: 0 none;
    }
    @include breakpoint(laptop-and-up) {
      .ds-description {
        margin-left: 2%;
      }
      .ds-h4 {
        margin-left: 1%;
      }
    }
  }
}
