@import '../../common/style/styleguide';

.of-device-checkout-card-payment {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40vh;
    text-align: center;

    &--payment {
      &-success,
      &-cancelled,
      &-failed {
        > div {
          padding-bottom: 3 * $global-baseline;
          padding-top: 3 * $global-baseline;
          text-align: center;
        }
      }

      &-success {
        > h3 {
          padding-top: $global-baseline;
        }
      }
    }
  }

  &__thumb-up {
    background-color: transparent;
    background-image: url('../../common/svg/thumb-up.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 60px;
    width: 60px;
  }
}
