@import '../../common/style/styleguide';

.of-customer-order-scene {
  &__disclaimer {
    border: 1px solid #e0e0e1;
    color: $ea-color-coal-grey;
    margin-top: 25px;
    padding: 5px 15px;
  }

  .of-redeem-request-list {
    max-width: 1260px;

    > div {
      margin: 0 5%;
    }
  }

  @media only screen and (min-width: $breakpoint-s) {
    .of-redeem-request-list {
      margin: 0 48px;
    }
  }

  @media only screen and (min-width: $breakpoint-xl) {
    .of-redeem-request-list {
      margin: 0 auto;
    }
  }
}
