@import '../../common/style/styleguide';

.of-one-time-password-form {
  width: 100%;

  &__input-wrapper {
    margin-bottom: 10px;
    text-align: center;
    padding: 30px 0 10px 0;
    min-width: 210px;
  }

  .ea-fgrid__item {
    max-width: 100%;
  }

  > .of-employee__form-column {
    > h3 {
      padding-bottom: $global-baseline;
    }

    > div:last-child {
      padding-top: 3 * $ds-baseline;

      > div {
        padding-top: 3 * $ds-baseline;

        &:last-child {
          padding-top: $global-baseline;
        }
      }
    }
  }

  button {
    width: 100%;
  }

  &__separate-character-input {
    padding: 30px 0px 10px 0px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__otp-submit {
    padding-top: 48px;
  }

  &__submit-button {
    border: 2px;
    padding: 10px 30px 10px 30px;
  }
}
