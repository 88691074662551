@import '../../common/style/styleguide';

.of-subscription-selection-notification {
  margin: 0 5% 20px 0;

  &--modal {
    font-size: 15px;
  }

  div.of-modal__wrapper {
    width: 75%;
    @media only screen and (max-height: 750px), screen and (max-width: 1200px) {
      top: auto;
      transform: none;
    }
  }

  button.text-button {
    @include ds-base-font-family();
    @include text--s();
    background-color: transparent;
    border: none;
    color: $ds-color__blue-600;
    font-weight: normal;
    min-height: 20px;
    padding: 0;
    text-transform: none;
  }

  &--public {
    margin: 5px auto;
    max-width: 1380px;
  }
}
