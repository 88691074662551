@import '../../common/style/styleguide';

.ea-composite-list {
  &__top,
  &__header,
  &__rows,
  &__empty {
    max-width: $legacy-pl-content-size-desktop;
    margin: auto;
  }

  &__top {
    border-bottom: $global-border-normal solid $ea-color-smoke;
  }

  &__empty {
    padding: $global-baseline;
  }

  &__rows .ds-checkbox {
    justify-content: left;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  &__rows {
    &--checkListLayout {
      border-bottom: $global-border-normal solid $ea-color-smoke;
    }
  }

  &__header {
    &--checkListLayout {
      @include breakpoint(tablet-and-up) {
        border-bottom: $global-border-normal solid $ea-color-smoke;
      }
    }
  }

  &__header--hide {
    display: none;
  }

  &__loading-row > .ea-composite-list-row__cell {
    height: $global-baseline * 2;
    background-repeat: no-repeat;
    background-image: linear-gradient(rgba(223, 223, 225, 0.7) 60%, transparent 0);
    background-size: 100% 100%;
    background-position: 0 0;
    margin-right: $global-baseline * 2;
    margin-left: $global-baseline * 2;
  }

  .ea-composite-list-row.ea-composite-list__loading-row:hover {
    box-shadow: none;
    cursor: auto;
    transition: none;
    transform: none;
  }

  .ea-composite-list-row.ea-composite-list__loading-row {
    border-bottom: 1px solid $ea-color-white-smoke;
  }
}

@include breakpoint(tablet-and-up) {
  .ea-composite-list {
    &__top {
      border-bottom-style: none;
    }

    &__loading-row > .ea-composite-list-row__cell {
      margin-left: 0;
    }
  }
}
