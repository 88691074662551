@import '../../../../common/style/styleguide';

.of-dns-record-list {
  tr {
    word-break: break-all;
  }

  // Overwrites `formatRecordListEditRow` behaviour
  .of-table-wrapper .of-table .ds-table--responsive tbody tr.of-dns-record-list-row-edit td {
    padding: 10px 8px;
  }

  .of-dns-record-list-row-edit {
    &__actions {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    }

    // The 'trash can' needs to be shown over the next column to the right and at the same level as the respective
    // input row that will be removed. This could not be done by having the icon in the TTL Column as we need to keep
    // the row positions in sync for these and the TTL column has no (reasonable) way of knowing where the respective
    // row input is (there might be e.g. validation error messages that push the rows around vertically).
    &__remove {
      position: absolute;
      right: -44px;
      z-index: $zindex2;
    }

    &__value {
      .ds-input {
        min-width: 78px;
      }
    }

    &__view {
      .of-record-view-badge {
        color: $ds-color__blue-800;
        background-color: $ds-color__neutral-300;
      }

      &:not(:first-of-type) {
        padding-top: 12px;
      }
    }
  }

  // Overwrites `formatRecordListViewRow` behaviour
  .of-dns-record-list-row-view {
    & > .ds-tooltip {
      max-width: unset;
    }
  }
}
