@import '../breakpoints.scss';
@import '../variables.scss';

.cms-product-details {
  max-width: 90%;
  margin: 0 auto;

  .cms-product-details-description {
    &__paragraph {
      margin: 1em 0;
    }

    &__title {
      @include breakpoint(laptop-and-up) {
        font-size: 24px;
        line-height: 1em;
        font-weight: 300;
        display: block;
        padding-bottom: $global-baseline;

        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }
    }
  }

  .cms-product-details-picture {
    img {
      max-width: 100%;
      max-height: 100%;
    }

    .side-container {
      padding-top: 4 * $global-baseline;
      @include breakpoint(phone-large-and-down) {
        display: none;
      }
    }

    &__side-picture-container {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-bottom: 100%;
      box-sizing: border-box;
      border: $global-border-normal solid transparent;
      border-radius: 2px;

      &-picture {
        text-align: center;
        position: absolute;
        top: 6px;
        left: 6px;
        bottom: 6px;
        right: 6px;
      }

      &--selected {
        border-color: $ea-color-dark-smoke;
      }
    }

    &__main-container {
      padding: 0 0 20px 20px;
      @media screen and (min-width: 961px) {    // TODO why does not $breakpoint-l work?
        padding: $global-baseline;
      }
    }
  }
}