@import '../../common/style/styleguide';

.of-auxiliary-esim-order-scene {
  &__top {
    padding: 10px;
    @include breakpoint(tablet-and-up) {
      background: bottom/cover url('../../common/svg/art-login.svg') no-repeat;
      border: 0;
    }

    &-item {
      margin: 0 auto;
      @include breakpoint(tablet-and-up) {
        padding-bottom: 7 * $global-baseline;
        padding-top: 7 * $global-baseline;
      }
    }

    &-login {
      max-width: 480px;
    }

    .ea-selection-tile__list-item {
      padding: 10px 0;
      border: 1px solid $ea-color-dark-smoke;
      border-left-width: 0;
      border-right-width: 0;
      width: 100%;
    }

    .ea-price-text {
      text-align: left;
    }

    .ea-price-text__price {
      padding: 10px 0;
    }

    .ea-icon--arrow-right {
      color: $ea-color-dark-smoke;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      font-size: 32px;
      width: ($global-baseline * 4) - 3;
      height: ($global-baseline * 4) - 3;
      line-height: 100%;
      text-align: center;
      transform: translate(0%, -50%);
    }

    .ea-color-container {
      &--light-green {
        background-color: $ea-color-light-green;
        border: 1px solid $ea-color-medium-green;
      }
      &--attention {
        background-color: adjust-color($ea-color-error, $lightness: +40%);
        border: 1px solid $ea-color-error;
      }
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 20px 10px 10px;
      .of-icon--success-image {
        display: inline-block;
        background: transparent url('../../common/svg/success.svg') center top no-repeat;
        width: 100%;
        height: $global-baseline * 4;
      }
      .ea-icon--attention {
        color: $ea-color-error;
        text-align: center;
        display: inline-block;
        width: 100%;
        height: $global-baseline * 4;
      }
      .ea-icon--information {
        color: $ea-color-blue;
        text-align: center;
        display: inline-block;
        width: 100%;
      }
    }
  }

  &__bottom {
    flex-direction: column-reverse;
    @include breakpoint(tablet-and-up) {
      flex-direction: row;
    }
    img {
      display: block;
      margin: auto;
      max-width: 1260px;
      width: 100%;
    }
  }

  .ea-stack {
    padding-bottom: 10px;
    border-bottom: 1px solid $ea-color-dark-smoke;
  }

  .ea-stack.borderless {
    border: none;
    .ea-selection-tile__list-item {
      padding: 10px 0;
      border: 1px solid $ea-color-dark-smoke;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }

    button {
      margin-top: 10px;
    }
  }

  .ea-stack h4 {
    margin: unset;
  }

  .ea-stack span {
    display: block;
  }

  .of-header {
    padding-bottom: unset;
  }

  &_LOGIN.of-header--small {
    box-shadow: none;
  }

  .of-language-selector {
    padding: unset;
  }

  .of-login-form {
    div {
      padding: 5px;
    }
  }
}
