@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

.of-public-m2m-subscriptions {
  @include global-mixins.page-margins-as-grid;

  > nav:first-child {
    @include global-mixins.page-margins-as-grid-bleed;
  }

  > aside,
  > header,
  > section {
    text-align: center;
  }

  h1 {
    margin-bottom: 3 * $ds-baseline;

    + .ds-text--lead {
      margin-bottom: 3 * $ds-baseline;
    }

    ~ div {
      text-wrap: balance;
    }

    ~ div:last-child {
      margin-top: 3 * $ds-baseline;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    text-wrap: pretty;
  }

  > section {
    padding-bottom: 5 * $ds-baseline;
    padding-top: 5 * $ds-baseline;
  }

  [aria-describedby] {
    &::after {
      content: ' *';
    }
  }

  &__footnote {
    margin-left: 1em;
    position: relative;

    &::before {
      content: '* ';
      left: -1em;
      position: absolute;
    }
  }

  & &__subscriptions {
    @include breakpoint(desktop) {
      @include global-mixins.page-margins-as-grid-bleed;
    }

    padding-bottom: 2 * $ds-baseline;

    .of-subscription-card {
      // At this stage, SubscriptionCards is complete vomit as every design for the cards is different and
      //   people keep adding exceptions to every part of it. This just cleans up some unnecessary stuff.

      // Just big enough to minimize text rows in cards. 5G's button text still goes on two lines, but fixing
      //   that would make the cards really wide.
      max-width: 410px;

      // Remove stuff that's empty but still creates whitespace
      &--no-voucher,
      &--header > div > .ds-h4 {
        display: none;
      }
    }
  }

  #laitenetti_app {
    align-items: center;
    display: grid;
    gap: $ds-baseline * 5 $ds-baseline * 17;
    grid-template-columns: 1fr;
    text-align: left;

    @include breakpoint(laptop-and-up) {
      // Magic numbers from Figma prototype
      grid-template-columns: minmax(0, 33fr) minmax(0, 80fr);
      // We want the image before the text visually, but not in DOM
      > div {
        order: 2;
      }
    }

    > picture {
      margin: 0 auto;

      > img {
        display: block;
        max-width: min(400px, 100%);
      }
    }
  }

  &__bleed-grey {
    @include global-mixins.page-margins-as-grid-bleed;
    @include global-mixins.page-margins-as-padding;
    background-color: $ds-color--neutral-200;
  }

  #laitenetti_subscription_features {
    margin-top: 4 * $ds-baseline;

    > div {
      text-align: left;
    }

    @include breakpoint(laptop-and-up) {
      display: grid;
      gap: 0 $ds-baseline * 12;
      grid-template-columns: 1fr 1fr;

      > h2 {
        grid-column: 1 / -1;
      }
    }
  }
}
