@import '../../../common/style/styleguide';

.of-punchout-checkout-user-info {
  .of-user-details {
    padding-top: 1em;
    font-size: 16px;
    padding-bottom: 1.5em;
  }

  .of-sub-header {
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 1em;
    border-bottom: 1px solid $ds-color__neutral-300;
  }
}
