@import '../breakpoints.scss';
@import '../variables.scss';

.ea-h3--thick {
  font-weight: 600 !important;
}
.mb-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.marketing-block {
  @include breakpoint(phone-large-and-up) {
    .ds-storyblock-content {
      .storyblock-button {
        margin-right: 2 * $global-baseline;
        margin-bottom: 2 * $global-baseline;
      }
    }
  }

  @include breakpoint(phone-large-and-down) {
    .ds-storyblock.ya-compress {
      .ds-storyblock-content {
        padding-left: $global-baseline;
        padding-right: $global-baseline;
      }
    }

    .ds-storyblock.ya-compress-narrow {
      .ds-storyblock-content {
        padding-left: $global-baseline;
        padding-right: $global-baseline;
      }
    }

    .ds-storyblock-content {
      .storyblock-button {
        margin-bottom: $global-baseline;
        margin-right: $global-baseline;
      }
    }
  }
}
