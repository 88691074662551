@import '../../common/style/styleguide';

.of-pbx-work-hours {
  border: 1px solid $ds-color__neutral-300;
  padding-top: 3 * $ds-baseline;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 340px;

  @include breakpoint(phone) {
    padding-left: 0.5 * $ds-baseline;
    padding-right: 0.5 * $ds-baseline;
  }

  & &__panel-container {
    // Need specific selector to override Grid margin
    margin: 0;
  }

  & &__panel {
    // Need specific selector to override Grid padding
    padding: 0;
  }

  &__errors {
    color: $ds-color__neutral-400--dark;
    padding-top: 1.5 * $ds-baseline;
    padding-bottom: $ds-baseline;
  }

  &__disclaimer {
    padding-bottom: 2px;
  }

  &__range {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 1.5 * $ds-baseline;
  }

  &__range-element {
    display: flex;
    flex-direction: column;
    max-width: 145px;

    div {
      font-size: 3 * $ds-baseline;
    }

    .ds-input {
      margin-bottom: $ds-baseline;
    }

    & .ds-inputerror {
      display: none;
    }
  }

  &__range-name {
    align-self: flex-end;
    width: 100%;
  }

  &__range-delimiter {
    padding-left: 2 * $ds-baseline;
    padding-right: 2 * $ds-baseline;

    &__editing {
      padding-bottom: 2.5 * $ds-baseline;
    }
  }

  &__range-errors {
    color: $ds-color--error;
    padding-bottom: 3 * $ds-baseline;
    font-size: 1.3rem;
  }
}
