@import '../../common/style/styleguide';

.of-employee-subscriptions-list {
  @include breakpoint(phone-large-and-down) {
    &__name-column,
    &__mobile-column {
      .ea-composite-list-row {
        &__cell-value-line {
          font-weight: 600;
        }
      }
    }
    &__name-column {
      .ea-composite-list-row {
        &__cell-value {
          padding-bottom: 0;
        }
      }
    }
    &__puk-column {
      .ea-composite-list-row {
        &__cell-value {
          display: inline-flex;
          padding-left: 10px;
        }
      }
    }
  }

  &__empty_bb_list_notification {
    margin-top: 60px;
  }

  &__button-subscription {
    width: 250px;
  }

  &__button-device {
    width: 150px;
  }

  &__device_buttons {
    display: flex;
    @include breakpoint(phone-large-and-down) {
      flex-flow: column;
    }
    &__button {
      padding-right: 10px;
      @include breakpoint(phone-large-and-down) {
        &:nth-child(even) {
          padding-top: 10px;
        }
      }
    }
  }
}
