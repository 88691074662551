@use 'sass:list';
@use 'global-mixins';
@use '~@design-system/component-library/src/globals/scss/variables';
// This file contains styles that are not tied to any single component and are assumed to be always available.
//   If you have styles that override CL styles, place them in component-library-overrides.scss, not here.

// Page Margins, see https://designsystem.elisa.fi/9b207b2c3/p/668a77-grids-and-spacing
:root {
  --of-page-margin: #{2 * variables.$ds-baseline};

  @media screen and (min-width: variables.$breakpoint-s) {
    --of-page-margin: #{3 * variables.$ds-baseline};
  }

  @media screen and (min-width: variables.$breakpoint-l) {
    --of-page-margin: #{4 * variables.$ds-baseline};
  }

  @media screen and (min-width: variables.$breakpoint-xl) {
    // CL doesn't offer any (correct) variables for calculating this
    --of-page-margin: calc(50vw - 660px);
  }
}

.of-page-margins {
  // This is what you want in most cases, where you just want to have the margins in place all the time.
  &--as-margin {
    @include global-mixins.page-margins-as-margin;
  }

  // This is mostly for wrappers that have (some) children that bleed on (some) breakpoints.
  // One example of this is ProductHero, where the BG image bleeds on all breakpoints, but
  //   the contents' bg only bleeds on smaller breakpoints.
  &--as-grid {
    @include global-mixins.page-margins-as-grid;
  }

  // This allows adding a full width background color without adding an extra
  //   wrapper, e.g. the additional services block in /yritysliittymat
  &--as-padding {
    @include global-mixins.page-margins-as-padding;
  }

  &--as-margin > .of-page-margins__bleed,
  &--as-padding > .of-page-margins__bleed {
    @include global-mixins.page-margins-as-margin-or-padding-bleed;

    &-left {
      @include global-mixins.page-margins-as-margin-or-padding-bleed-left;
    }

    &-right {
      @include global-mixins.page-margins-as-margin-or-padding-bleed-right;
    }
  }
}
// End of Page Margins
