@import '../../common/style/styleguide';

.of-training-enrollment {
  margin-bottom: 20px;
  // This is pretty arbitrary, but the contents almost touch the viewport edges
  // without this.
  padding: 0 2 * $ds-baseline;

  &__content {
    // h2 has the same bottom & top margin, so it doesn't group properly.
    .ds-grid__col > h3:not(:first-child) {
      padding-top: 3 * $ds-baseline;
    }
  }

  &__total-price {
    margin-top: 40px;
  }

  .ds-radio {
    display: flex;
    justify-content: space-between;

    // FIXME having the price show up only in ::after makes it invisible to
    // screen readers.
    // Fix the SelectRadio items in TrainingEnrollment.tsx so that it uses
    // children instead of value, pass in something like
    // <div><span>{label}</span><span>{value}</span><div>
    // in there and make the div display: flex; justify-content:
    // space-between
    &:after {
      box-sizing: content-box;
      display: inline-flex;
      margin: 3px 0;
      text-align: right;
      white-space: nowrap;
    }
  }
}
