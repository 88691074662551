@import '../../common/style/styleguide';

.of-catalog-details {
  &-editable {
    &__corporateShare {
      place-items: baseline;

      &--input {
        max-width: 432px;
      }
    }
  }

  #select-billing-account-combobox {
    padding-bottom: 0;
  }

  .edit-product-type,
  .edit-agreement-period {
    display: flex;
  }
}

.of-virtual-catalog-published-details {
  padding-bottom: $global-baseline;

  > .ea-fgrid__item {
    padding-bottom: $global-baseline;
    padding-top: $global-baseline;
  }
}
