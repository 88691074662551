@import '../../common/style/styleguide';

$of-color-dark-grey: #404041;

.of-punchout-checkout {
  color: $of-color-dark-grey;

  &--header {
    padding-bottom: 8 * $global-baseline;
  }

  .cl-stepper-wrapper {
    margin: 12px auto;
    max-width: 904px;

    .ds-stepper ol li.ds-stepper__step::before {
      top: -3px;
      border: none;
      left: -17px;
      font-weight: 500;
      font-size: 16px;
    }

    .ds-stepper ol li.ds-stepper__step {
      padding-bottom: 30px;
    }
  }
}
