@import '../../../common/style/styleguide';

.punchout-checkout-confirmation {
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: 850px;

  @include breakpoint(phone-large-and-down) {
    padding: 0 $global-baseline * 2;
  }

  .of-main-header {
    font-weight: 600;
    font-size: 36px;
    padding-bottom: 2rem;
    @include breakpoint(phone-large-and-down) {
      padding-top: 4rem;
    }
  }

  .ds-text--l {
    font-size: 18px;
  }
}
