@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

.of-details-with-card {
  display: flex;
  gap: 0 (8 * $ds-baseline);
  @include global-mixins.page-margins-as-padding;
  padding-bottom: 6 * $ds-baseline;
  padding-top: 6 * $ds-baseline;

  @include breakpoint(tablet-and-down) {
    flex-direction: column;
  }

  &__details {
    flex: 1;
    margin-bottom: 8 * $ds-baseline;
  }

  .ds-card {
    flex: 0 1 416px; // 416px to match design
    height: fit-content;

    @include breakpoint(tablet-and-down) {
      flex: 1;
    }

    .ds-card-heading {
      @include breakpoint(tablet-and-down) {
        display: none;
      }
    }
  }
}
