@import '../../common/style/styleguide';

// The below styles are to hack the ea-tooltip to make it work on mobile.
.ea-responsive-tooltip {
  display: flex;
  .ea-tooltip {
    align-items: center;
    margin-left: 8px;
    display: flex;
    position: relative;

    > .ea-tooltip__text.ea-disclaimertext {
      visibility: hidden;
    }

    &__text {
      width: 88vw;
      left: calc(-44vw + 28px);

      @include breakpoint(tablet-and-up) {
        width: 384px;
        max-width: 40vw;
        left: -150px;
        &--align-right {
          left: -164px;
        }
      }

      @include breakpoint(laptop-and-up) {
        left: -50px;
      }

      @include breakpoint(desktop) {
        left: 0;
      }

      // disable PL default pseudo elements
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    &--visible {
      > .ea-tooltip__text.ea-disclaimertext {
        visibility: visible;
      }

      // create pseudo elements in .ea-tooltip level instead
      // enabling the arrow to move properly on mobile
      &::before {
        position: absolute;
        content: '';
        background-color: $ea-color-white;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
        top: -28px;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        z-index: 2;
        margin-left: -4px;
        @include breakpoint(tablet-and-up) {
          margin-left: 2px;
        }
        @include breakpoint(phone-large-and-down) {
          margin-left: 1px;
        }
      }

      &::after {
        position: absolute;
        content: '';
        background-color: $ea-color-white;
        top: -36px;
        width: 24px;
        height: 16px;
        margin-left: -8px;
        @include breakpoint(tablet-and-up) {
          margin-left: -2px;
        }
        z-index: 2;
      }
    }

    i.ea-icon {
      font-size: 20px;
      font-weight: 800;
      color: $ea-color-linkblue;

      &::before {
        font-weight: 400;
      }
    }
  }
}
