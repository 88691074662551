@import '../../common/style/styleguide';

.of-addon-details {
  &__accounts {
    small,
    span {
      display: block;
    }
    small {
      padding-top: $global-baseline;
      line-height: 15px;
    }
  }

  &__actions {
    margin: 0;
    max-width: 330px;
    @include breakpoint(tablet-and-up) {
      max-width: 880px;
      margin: 0 auto;
    }
    a {
      display: inline-block;
    }
    button,
    a {
      margin-right: 2.75em;
    }
    clear: left;
    .ea-icon {
      margin-right: 0.25rem;
      vertical-align: middle;
    }
    padding-top: $global-baseline * 3;
    padding-bottom: $global-baseline * 3;
  }
  &__disclaimer {
    border: $global-border-normal solid $ea-color-smoke;
    color: $ea-color-grey;
    padding: $global-baseline $global-baseline * 2;
    margin-bottom: $global-baseline * 4;
  }
}
