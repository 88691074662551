@import '../../common/style/styleguide';

.of-address-search {
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    max-width: $breakpoint-m;
  }

  &__search-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__search-button-wrapper {
    margin-top: $ds-baseline;

    @include breakpoint(tablet-and-up) {
      margin-top: 0;
    }
  }

  &__search-button {
    width: 100%;

    @include breakpoint(tablet-and-up) {
      width: initial;
    }
  }

  &__postal-code {
    flex: 0 0 25%;

    @include breakpoint(tablet-and-up) {
      padding-right: $ds-baseline;
    }
  }

  &__spinner {
    padding-top: 10px;
    width: 32px;
  }
}
