@import '../breakpoints.scss';
@import '../variables.scss';

.move--elevate-6 {
  margin-top: -72px;
}

.product-hero {
  img {
    max-width: 100%;
    height: auto;
  }

  &__image,
  .ds-picture img {
    display: block;
    min-height: 240px;
    height: 240px;

    @include breakpoint(laptop-and-up) {
      height: 384px;
    }
  }

  &__image--large {
    display: none;

    @include breakpoint(tablet-and-up) {
      display: inherit;
    }
  }

  &__image--small {
    width: 100vw;

    @include breakpoint(tablet-and-up) {
      display: none;
    }
  }

  &__title,
  &__ingress {
    margin: 0;
  }
}
