@import '../../../common/style/styleguide';

.of-device-checkout-delivery-details__pickup {
  .ds-input {
    margin-bottom: 2 * $ds-baseline;
  }

  .ds-selectgroup__list-item__text {
    flex-direction: column;
    gap: 0;
    text-align: left;
  }
}
