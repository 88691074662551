@import '../../common/style/styleguide';

.of-onboarding-request-list {
  .active-state {
    vertical-align: middle;
    display: inline-block;

    @include breakpoint(tablet-and-down) {
      display: block;
    }

    @include breakpoint(phone-large-and-down) {
      display: inline-block;
    }
  }

  .ea-composite-list-row__cell-value-line {
    &.ea-disc--green {
      white-space: initial;
    }
    &.ea-disc--red,
    &.ea-disc--grey {
      overflow-x: visible;
    }
  }

  .ea-composite-list-row__cell-value:last-child {
    min-height: 24px;
  }

  .ea-composite-list-row {
    min-height: 24px;

    @include breakpoint(tablet-and-up) {
      padding: 10px 24px 10px;
    }

    &--interactive:hover {
      box-shadow: none;
      cursor: pointer;
      transition: none;
      transform: none;
    }
  }

  .ea-composite-list-row__cell {
    margin: auto;
  }

  .expired-state {
    vertical-align: middle;
    display: inline-block;
    line-height: normal;

    @include breakpoint(tablet-and-down) {
      display: block;
    }

    @include breakpoint(phone-large-and-down) {
      display: inline-block;
    }

    @include breakpoint(phone) {
      & > span {
        display: block;
      }
    }

    .ea-icon--resend {
      vertical-align: middle;
      margin-left: 15px;

      @include breakpoint(tablet-and-down) {
        margin-left: 0px;
        position: relative;
        left: -92px;
      }
    }
  }
}
