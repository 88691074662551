@import '../../common/style/styleguide';

.of-sim-card-number {
  label {
    display: block;
    font-weight: 600;
  }
}
.of-sim-card-number--invalid-sim-number {
  color: $ea-color-grey;
}
