@import '../../common/style/styleguide';

.autocomplete-root {
  min-height: $ds-baseline * 9;

  &.large-min-height {
    min-height: $ds-baseline * 14;
  }

  .autocomplete-control {
    text-align: left;

    .autocomplete-presentation {
      ul[role='listbox'] {
        position: absolute;
        min-width: 100%;
        max-height: $ds-baseline * 31;
        overflow-y: auto;
        z-index: 30;
        background-color: $ds-color__white;
        border-radius: $ds-baseline * 0.25;
        margin: 1px 0 0;
        padding: 0;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
      }

      li {
        margin: 0;
        padding: $ds-baseline * 1.5 $ds-baseline * 2;
        font-size: $ds-baseline * 1.75;
        line-height: $ds-baseline * 2.5;
        list-style-type: none;
        &:hover {
          background-color: $ds-color__white;
        }

        * {
          pointer-events: none;
        }

        &[role='option'] {
          cursor: pointer;
          &:hover {
            background-color: $ds-color__white;
          }
          &.highlighted,
          .highlighted:hover {
            background-color: $ds-color__neutral-100;
          }
          &.active,
          .active:hover {
            background-color: $ds-color__neutral-200;
          }
          &.active.highlighted,
          .active.highlighted:hover {
            background-color: $ds-color__neutral-300;
          }
        }
      }
    }

    [role='combobox'] {
      padding-right: 51px;
      &[readonly] {
        margin-left: -12px;
        padding-right: 16px;
        border: 0;
      }
    }

    &.focused.clearable [role='combobox'] {
      padding-right: 87px;
    }

    .chevron,
    .clear {
      background-color: transparent;
      border: 0;
      border-radius: 100%;
      cursor: pointer;
      height: 36px;
      width: 36px;
      padding: 0;
      position: absolute;
      &:hover {
        background-color: #f6f6f7;
      }
    }

    .chevron {
      display: flex;
      justify-content: center;
      right: 8px;
      top: 6px;
      [role='img'] {
        margin-top: 6px;
      }
    }

    .clear {
      display: none;
      right: 44px;
      top: 6px;
      [role='img'] {
        margin-top: 9px;
        margin-left: 10px;
      }
    }

    .loading {
      background-color: transparent;
      border: 0;
      border-radius: 100%;
      cursor: pointer;
      height: 36px;
      width: 36px;
      padding: 0;
      position: absolute;
      top: 15px;
      &.left {
        right: 66px;
      }
      &.right {
        right: 44px;
      }
    }

    &.focused .clear {
      display: block;
    }
  }

  &.autocomplete-disabled {
    input {
      background-color: $ds-color__neutral-100;
      border-color: $ds-color__neutral-400;
      color: $ds-color__neutral-600;
    }

    label {
      color: $ds-color__neutral-600;
    }

    .chevron {
      &:hover {
        background-color: unset;
        cursor: unset;
      }
    }

    .ds-icon svg {
      fill: $ds-color__neutral-600;
    }
  }

  &.autocomplete-error {
    input {
      background-color: #fff5f7;
      border-color: #e0022a;
      border-width: 2px;
      color: #171c43;
      box-shadow: none;
    }

    .chevron {
      &:hover {
        background-color: #ffd3dd;
      }
    }
  }

  &.autocomplete-active {
    input {
      border-color: $ds-color__secondary-blue-600;
      box-shadow: 0 0 0 1px $ds-color__secondary-blue-600;
    }
  }

  &.autocomplete-non-writable {
    input {
      caret-color: transparent;
      cursor: default;
    }
  }
}
