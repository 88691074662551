@import '../../common/style/styleguide';

.of-barrings-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__actions {
    width: 100%;
    margin-top: 6 * $ds-baseline;

    button:first-child {
      margin-right: 4 * $ds-baseline;
    }
  }

  &__group {
    flex: 1 0 100%;
    width: 100%;

    @include breakpoint(tablet-and-up) {
      flex: 1 0 47%;
      width: 47%;
      max-width: 47%;
    }

    h5 {
      margin-bottom: 1.5 * $ds-baseline;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    padding-top: $ds-baseline;
    border: none;
    background-color: $ds-color__white;

    .ds-icon {
      cursor: pointer;
    }
  }

  &__link {
    .ea-icon {
      padding-left: $global-baseline;
      font-size: 18px;
      line-height: 18px;
    }
    .ea-icon::before {
      width: 13px;
      height: 13px;
    }
  }

  &__dialog {
    padding-bottom: 3 * $ds-baseline;
  }
}

// TODO: clean these out when MultiSelector and ea-styles are removed
.of-barrings-accordion-content {
  padding-bottom: 3 * $global-baseline;

  &__icon {
    color: $ea-color-linkblue;
    font-size: 21px;
    display: flex;
    align-items: flex-start;
    height: 100%;
    cursor: pointer;
    margin-left: $global-baseline;

    &::before {
      height: 2 * $global-baseline;
    }
  }

  &__link {
    .ea-icon {
      padding-left: $global-baseline;
      font-size: 18px;
      line-height: 18px;
    }
    .ea-icon::before {
      width: 13px;
      height: 13px;
    }
  }

  &__dialog {
    padding-bottom: 3 * $ds-baseline;
  }
}
