@import '../../common/style/styleguide';

.of-simcard-number-input {
  padding-bottom: 1em;
  display: inline-block;
  white-space: nowrap;

  &__with-button {
    .ds-input--inputarea-input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .ds-button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  @include breakpoint(phone-large-and-down) {
    .ds-input {
      max-width: 200px;
    }
  }
  // FIXME I don't see an inputgroup in SimCardNumberInput?
  .ds-inputgroup__inputcontainer {
    .ds-input {
      flex: 0 1 auto;
      width: 14em;
      max-width: 100%;
      padding-bottom: 0;
    }
  }
}
.of-simcard-help-icon {
  margin-bottom: 1px;
}
