@import '../../../../common/style/styleguide';

.of-dns-domain-list {
  margin-top: $ds-baseline * 2;
  margin-bottom: $ds-baseline * 2;

  .ds-disclaimer .ds-disclaimer-content a {
    margin-top: $ds-baseline * 2;
    color: unset;
    text-decoration: none;
  }
}
