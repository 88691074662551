@import '../../common/style/styleguide';

$gutter-minimal: 2px;
$gutter-normal: 4px;

.of-select-one-list {
  max-width: 100%;

  .ds-grid {
    max-width: 100%;
  }

  .ds-grid__row {
    max-width: unset;
    width: unset;
  }

  &__item {
    border: 1px solid $ea-color-smoke;
    border-radius: 2px;
    color: $ea-color-dark-grey;
    display: block;
    height: 100%;
    position: relative;
    transition: box-shadow 120ms ease-in;

    &:not(&--selected):not(&--disabled):hover {
      border: 1px solid $ea-color-linkblue;
      box-shadow: 0 0 0 1px $ea-color-linkblue;
      cursor: pointer;
    }

    &--highlighted {
      border-color: $ea-color-warning;
      box-shadow: 0 0 0 1px $ea-color-warning;
    }

    &--selected {
      border: 1px solid $ea-color-linkblue;
      box-shadow: 0 0 0 1px $ea-color-linkblue;
    }

    &--disabled {
      border: 1px solid $ea-color-smoke;
      box-shadow: 0 0 0 1px $ea-color-smoke;
      cursor: default;
    }

    .ds-icon {
      left: 20px;
      position: absolute;
      top: 20px;

      svg {
        fill: $ea-color-linkblue;
      }
    }

    &--disabled {
      .ds-icon {
        svg {
          fill: $ea-color-smoke;
        }
      }
    }

    &--column {
      flex: none !important;
    }
  }

  &--gutter- {
    &minimal {
      $gutter: $gutter-minimal;

      .ds-grid__row {
        // This doesn't counteract vertical outside gutters to match old implementation.
        margin: 0 -#{$gutter};

        > div {
          margin-top: $gutter;
          padding-left: $gutter;
          padding-right: $gutter;
        }

        @include breakpoint(tablet-and-up) {
          margin: 0 -#{$gutter * 2};

          > div {
            margin-top: $gutter * 3;
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
          }
        }

        @include breakpoint(desktop) {
          margin: 0 -#{$gutter * 4};

          > div {
            margin-top: $gutter * 5;
            padding-left: $gutter * 4;
            padding-right: $gutter * 4;
          }
        }
      }
    }

    &normal {
      $gutter: $gutter-normal;

      .ds-grid__row {
        // Counteract gutters on edges of grid
        margin: -#{$gutter * 5} -#{$gutter} 0 -#{$gutter};

        > div {
          margin-top: $gutter * 5;
        }

        @include breakpoint(tablet-and-up) {
          margin: -#{$gutter * 5} -#{$gutter * 2} 0 -#{$gutter * 2};
        }

        @include breakpoint(desktop) {
          margin: -#{$gutter * 5} -#{$gutter * 4} 0 -#{$gutter * 4};
        }
      }
    }
  }
}
