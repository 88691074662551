@import '../../common/style/styleguide';

.of-site .of-self-service-login-scene {
  &__top {
    border-bottom: $global-border-normal solid $ea-color-smoke;
    @include breakpoint(tablet-and-up) {
      background: bottom/cover url('../../common/svg/art-login.svg') no-repeat;
      border: 0;
    }

    &-login {
      @include breakpoint(tablet-and-up) {
        padding-bottom: 7 * $global-baseline;
        padding-top: 7 * $global-baseline;
      }
    }
  }

  &__bottom {
    padding-bottom: 10 * $global-baseline;
    padding-top: 3 * $global-baseline;

    @include breakpoint(tablet-and-up) {
      padding-bottom: $global-baseline;
      padding-top: 8 * $global-baseline;
    }

    img {
      display: block;
      margin: auto;
      max-width: 1260px;
      width: 100%;
    }
  }
  .omaelisa-info {
    margin: 0 auto;
    max-width: 1400px;

    .info-grid {
      margin: 0 5%;
    }
  }
  .of-login {
    max-width: 480px;
  }
  .center-col {
    flex-basis: auto;
  }
}

@media only screen and (max-width: $breakpoint-l) {
  .of-self-service-login-scene {
    .center-col-picture {
      display: none;
    }
    .text-at-right {
      padding-left: 40px;
    }
  }
}

@media only screen and (max-width: $breakpoint-m) {
  .of-self-service-login-scene {
    .picture-and-text-grid {
      flex-direction: column-reverse;
    }
    .picture-column {
      padding-top: 24px;
    }
    .text-at-right {
      padding-left: 0;
    }
  }
}

@media only screen and (min-width: $breakpoint-s-max-width) {
  .info-grid {
    .picture-column {
      padding-top: 36px;
    }
  }

  .of-self-service-login-scene {
    .center-col {
      padding-bottom: 84px;
      padding-top: 84px;
    }
  }
}
