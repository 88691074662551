@import '../../common/style/styleguide';

.of-employee-email-verification {
  padding-top: 3 * $global-baseline;
  text-align: center;

  > button {
    padding-bottom: 4 * $global-baseline;
    padding-top: $global-baseline;
  }

  > h3 {
    padding-top: $global-baseline;
  }

  &__thumb-up {
    background-color: transparent;
    background-image: url('../../common/svg/thumb-up.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 60px;
    width: 60px;
  }
}
