@import '../../common/style/styleguide';

.of-loading {
  align-items: center;
  display: flex;

  &--p- {
    &b- {
      &0 {
        padding-bottom: 0;
      }
      &1 {
        padding-bottom: 0.75 * $ds-baseline;
      }
      &2 {
        padding-bottom: 1.5 * $ds-baseline;
      }
      &4 {
        padding-bottom: 3 * $ds-baseline;
      }
    }

    &t- {
      &0 {
        padding-top: 0;
      }
      &1 {
        padding-top: 0.75 * $ds-baseline;
      }
      &2 {
        padding-top: 1.5 + $ds-baseline;
      }
      &4 {
        padding-top: 3 * $ds-baseline;
      }
    }
  }

  .ds-loadingspinner--size-xl {
    margin-bottom: 3 * $ds-baseline;
    margin-top: 3 * $ds-baseline;
  }
}
