@import '../../common/style/styleguide';

.of-epp-redeem {
  hr {
    border: 0;
    height: 1px;
    background-color: $ea-color-smoke;
    color: $ea-color-smoke;
  }

  #approverContact {
    @include breakpoint(tablet-and-up) {
      max-width: 50%;
    }
    @include breakpoint(laptop-and-up) {
      max-width: 400px;
    }
  }
}

.of-epp-redeem-dialog {
  @include breakpoint(tablet-and-up) {
    .ds-description__list-item:nth-of-type(2) {
      margin-bottom: 80px;
    }
  }
}
