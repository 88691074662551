@import '../../common/style/styleguide';

.of-contact-access-rights-accordion {
  &__content {
    margin-bottom: $global-baseline * 2;

    &-title {
      margin-top: 0px;
      padding-top: 0px;
    }

    &-options > div {
      margin-top: $global-baseline;
      margin-bottom: $global-baseline;

      label {
        font-weight: normal;
      }
    }

    &__account-selections {
      margin-top: $global-baseline * 1.5;
      &--show-all-link {
        margin-top: $global-baseline;
      }
    }
  }

  &__other_companies_section {
    border: 1px solid #e8e8ea;
  }

  &__buttons {
    display: flex;
    margin-bottom: $global-baseline * 2;
  }

  @media screen and (max-width: 640px) {
    &__buttons {
      display: inline-grid;
      width: 100%;
      button {
        width: 49%;
      }
    }
  }
}
