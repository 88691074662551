.of-file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &--file {
    display: inline-block;
    margin-right: 16px;

    &--file-name {
      display: inline-block;
      vertical-align: middle;
      margin: 10px 0;
    }
  }
}
