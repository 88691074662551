@import '../../common/style/styleguide';

.of-formik {
  &-li {
    padding: 0;
    &-no-selection {
      padding: 12px 18px;
      font-size: 16px;
      font-weight: normal;
      pointer-events: none;
      cursor: default;
    }
  }
  &-list-item-button {
    background: transparent;
    border: 0;
    color: inherit;
    display: block;
    font-size: 16px;
    font-weight: normal;
    padding: 12px 18px;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: $ds-color__neutral-100;
    }
  }

  &-text-input,
  &-invalid {
    padding-top: 0;
  }

  &-text-input {
    textarea {
      height: 8rem;
    }
  }

  &__company-results {
    max-height: 273.6px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    left: 10px;
    position: absolute;
    width: calc(100% - 24px);
    margin-top: -4px;
    margin-bottom: 24px;
    border-top: 0;
    border-left: 2px solid #d0d0d1;
    background: white;
  }

  &__domain-search {
    h3 {
      // FIXME text mixins are broken in CL (9.5.0), so remove our font-family rule once those are fixed
      @include h4;
      font-family: $ds-title-font--500;
      margin: 0;
      padding-bottom: 0.2em;
    }

    .checkbox-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__label {
    .tooltip {
      .ds-tooltip {
        padding: 10px;
        width: 384px;
        max-width: 88vw;
      }

      .ds-icon {
        position: relative;
        top: 3px;
      }
    }
  }

  &__company-search {
    position: relative;

    .ds-input {
      margin: 0 0 8px 0;
    }

    .loading-spinner {
      // element's contents are constantly animated, might as well minimize the
      // effect it has on the rest of the document
      contain: strict;
      // Don't block click to input field
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 42px;
    }
  }
}

.of-file-input {
  &--controls,
  &--file-limits-info {
    flex-shrink: 0;
    min-height: 40px;
    margin: 8px 0;

    &--button-text {
      vertical-align: middle;
    }

    &--info-text {
      font-size: 12px;
    }
  }

  &--file-limits-info {
    margin-left: 16px;
  }

  &--controls-and-text {
    display: flex;
  }
}

@media only screen and (max-width: $breakpoint-s-max-width) {
  .of-file-input {
    &--controls-and-text {
      display: block;
    }
    &--file-limits-info {
      margin-left: 0;
    }
  }
}
