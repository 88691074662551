@import '../../common/style/styleguide';

.support-case-dialog {
  .right {
    float: right;
  }

  .strong {
    font-weight: bold;
  }

  .ea-icon--external-link:before {
    height: 24px;
    width: 30px;
  }

  hr {
    height: 1px;
    background-color: $ds-color__neutral-300;
    border: none;
  }
}
