@import '../../common/style/styleguide';

.of-campaign-select-tile {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    &__selected {
      color: $ds-color__blue-600;
    }
  }

  &__badge {
    position: absolute;
    right: -10px;
    top: -10px;
  }
  span.ds-badge.ds-badge--text {
    margin-top: -10px;
    padding: 8px;
  }

  .price-text--highlight {
    color: $ds-color__orange;
  }
}
