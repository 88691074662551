@import '../../common/style/styleguide';

// This is in the path file, not in the component...
.of-forgot-password-wrapper {
  &__content {
    margin-bottom: 5 * $ds-baseline;
    position: relative;
  }
}

#of-forgot-password {
  box-sizing: border-box;
  display: block;
  float: none;
  line-height: 3 * $ds-baseline;
  margin: 0 auto;
  // Random number that doesn't spread the content too wide
  max-width: 600px;
  padding: 3 * $ds-baseline 2.5 * $ds-baseline 0 2.5 * $ds-baseline;

  @include breakpoint(phone-large-and-up) {
    padding: 3 * $ds-baseline 3 * $ds-baseline 0 3 * $ds-baseline;
  }

  .of-forgot-password-step {
    &__header {
      margin: 5 * $ds-baseline 0 3 * $ds-baseline 0;
    }
  }

  .of-modal__buttons + a {
    display: block;
    margin-top: $ds-baseline * 3;
  }
}
