@import '../../common/style/styleguide';

.of-new-support-case-public {
  max-width: 1096px;
  margin: auto;

  h1 {
    margin-top: 95px;
  }
}
