.of-toggle-with-subcomponent {
  h4 {
    line-height: 30px;
  }

  .info-icon {
    width: 20px;
    height: 20px;
  }

  .info-icon-container {
    white-space: nowrap;
    padding-left: 8px;
    vertical-align: middle;

    .tooltip {
      white-space: break-spaces;
      max-width: 300px;
    }
  }
}
