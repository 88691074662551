@import '../../common/style/styleguide';

.ea-phaseindicator {
  display: flex;
  flex-wrap: wrap;

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 36px;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint(tablet-and-up) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__text {
    display: none;

    @include breakpoint(tablet-and-up) {
      display: inline;
    }
  }
}
