@import '../../common/style/styleguide';

.of-delivery-address-form {
  border: 1px solid $ea-color-dark-smoke;
  padding: $global-baseline;
  border-radius: 3px;

  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }

  @include breakpoint(laptop-and-up) {
    padding: 2 * $global-baseline;
  }

  &--view-column {
    @include breakpoint(laptop-and-up) {
      display: flex;
      align-items: center;
    }
  }

  &--delivery-address-type {
    &--icon {
      display: inline-block;
      vertical-align: middle;
    }

    &--text {
      display: inline-block;
      vertical-align: middle;
      padding-left: 6px;
      @include breakpoint(laptop-and-up) {
        padding: 0 6px 0 20px;
      }
    }
  }

  &--address-confirmation {
    padding-top: 12px;
  }

  &--left {
    @include breakpoint(laptop-and-up) {
      div {
        display: inline-block;
        padding: 0 3px;
      }
    }
  }

  &--right {
    @include breakpoint(phone-large-and-down) {
      right: $global-baseline;
      bottom: 60px;
    }

    @include breakpoint(laptop-and-up) {
      margin-left: auto;
    }
  }

  &--row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include breakpoint(phone-large-and-down) {
      flex-direction: column;
    }
  }

  &--column {
    flex: calc(50% - 5px);
    margin-right: 5px;
    @include breakpoint(tablet-and-up) {
      &:nth-child(even) {
        padding-left: 5px;
      }
      &__company-name {
        width: calc(50% - 5px);
      }
    }
  }

  .ds-selectgroup__list-item__text__name {
    white-space: normal; // override default style to allow icon text wrap
  }
}
