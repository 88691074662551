@import '../../common/style/styleguide';

$small-gap: 5px;

.of-action-block {
  cursor: inherit;
  line-height: inherit;
  color: inherit;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: $global-font-brand-bold;

  .of-action-block-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .action-block-book-link {
    @include breakpoint(phone) {
      line-height: 1em;
    }
  }

  // Forces small gap between the phone number. To take effect this rule has to be quite specific or
  // general styles override it.
  h4.ea-h4.ea-action-blocks__block__title.of-action-block-title {
    margin-bottom: $small-gap;
  }

  .of-action-block-call {
    text-align: center;

    a {
      padding: 10px 1.55em 10px 1.55em;
      margin: 1em;
      background-color: transparent;
      white-space: nowrap;
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .action-block-description {
      line-height: 1.25em;
    }
  }
  #additional-information-link {
    padding: 0;
    margin: 0;
  }
}
