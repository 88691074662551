@import '../../common/style/styleguide';

.of-language-selector {
  margin: 0 auto;
  text-align: right;
  padding: 11px 0;
  max-width: $legacy-pl-content-size-laptop;
  @include breakpoint(phone-large-and-down) {
    text-align: center;
  }

  &__input {
    display: none;
  }

  &__spacer {
    padding-left: 5px;
    padding-right: 5px;
  }

  &__link {
    color: $ea-color-blue;
    cursor: pointer;
  }
}
