@import '../../common/style/styleguide';

.ea-composite-list-search {
  max-width: 300px;
  .ea-input {
    width: 100%;
    padding: 0;
  }

  .ea-input__wrapper {
    box-shadow: none;

    &:focus-within {
      border-color: $ea-color-linkblue;
    }
  }

  .ea-icon--search {
    color: $ea-color-blue;
  }
}
