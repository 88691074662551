@import '../../common/style/styleguide';

.of-contact-or-purpose-of-use {
  .copy-user-info-success-message {
    color: #008253;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .copy-user-info-button {
    display: flex;
    align-items: center;
    background-color: white;
    border-color: white;
    text-transform: none;
  }

  .tooltip {
    padding: 10px;
    width: 400px;
    max-width: 88vw;
  }

  &__contact_dropdown {
    width: 400px;
    height: 110px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }

    // This hides the Valitse/select from the combobox options (CL.Comboboxv2 adds that by default)
    li:not([data-value]) {
      display: none;
    }
  }

  &__purpose_of_use_form {
    width: 400px;
    height: 110px;
    padding-top: 28px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
  }

  &__cost_center_employee_number {
    .ds-input--labelarea-label {
      font-size: smaller !important;
    }
  }

  &__grid {
    max-width: 825px;
    .ds-grid__row {
      max-width: 825px;
      .bottom {
        padding-bottom: 32px;
      }
      @include breakpoint(phone-large-and-down) {
        display: block;
      }
    }
  }

  &__grid_column {
    @include breakpoint(tablet-and-up) {
      > :nth-child(odd) {
        padding-right: 12px;
        padding-left: 0;
      }

      > :nth-child(even) {
        padding-left: 12px;
        padding-right: 0;
      }
    }
  }

  &__select-group {
    width: 400px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
    @include breakpoint(phone) {
      .ds-selectgroup__list {
        // The items get too narrow without this.
        grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
      }
    }
  }
}
