@import '../../common/style/styleguide';

.of-paid-amount-summary {
  background-color: $ds-color__neutral-100;
  border: 1px solid $ds-color__neutral-300;
  padding: 1.5 * $ds-baseline 20px;

  @include breakpoint(tablet-and-up) {
    padding: 4.5 * $ds-baseline 30px;
  }

  &__amount {
    @include breakpoint(tablet-and-up) {
      text-align: right;
    }
  }
}
