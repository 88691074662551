@import '../../common/style/styleguide';

.of-public-pages-search-form {
  input[type='search'] {
    // Don't let text go behind clear button
    padding-right: 48px;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  // For some reason DS broke this, basically a static width
  // button will take up more space than it should if we don't
  // do this.
  .ds-inputgroup__inputcontainer .ds-lastelement {
    flex-grow: 0;
  }
}
