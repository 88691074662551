@import '../../common/style/styleguide';

.of-move-contact-form {
  &__validation-errors-container {
    h4 {
      padding: 0;
      margin: 0;
    }
    margin-top: $ds-baseline * 3;
    padding: $ds-baseline * 2;
    border: 1px solid $ds-color__neutral-300;
    background: $ds-color__neutral-100;
  }

  .autocomplete-root {
    min-height: unset;
  }

  .of-billing-account-search-result h4 {
    font-size: 16px;
  }

  .ds-input {
    &:has(input[name*='costCenter']) {
      margin: 0;
    }
    &--inputarea-input {
      font-size: 14px;
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-between;
  }

  &__subscriptions-list {
    font-size: 14px;
  }

  &__subscriptions-list-header-row {
    display: none;
    border-bottom: 1px solid $ds-color__neutral-300;
  }

  &__border-divider {
    border-bottom: 1px solid $ds-color__neutral-300;
  }

  &__column-header {
    display: none;

    &--mobile {
      display: block;
      font-weight: 700;
      text-align: left;
    }
  }

  div[role='cell'] {
    margin-bottom: $ds-baseline * 2;
  }

  @media only screen and (min-width: $breakpoint-l) {
    &__subscriptions-list-header-row {
      display: block;
      border-bottom: 1px solid $ds-color__blue-800;
    }

    &__column-header {
      display: block;
      font-weight: 700;
      text-align: left;

      &--mobile {
        display: none;
      }
    }

    div[role='gridcell'] {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: $breakpoint-m) {
    .autocomplete-root .ds-inputerror {
      position: absolute;
    }
  }
}
