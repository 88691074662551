@import '../../common/style/styleguide';

.of-public-subscription-image-cards {
  column-gap: 80px;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 6 * $ds-baseline;
  row-gap: $ds-baseline;

  > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: subgrid;
    grid-row: span 3;
    justify-items: center;
    padding-bottom: 4 * $ds-baseline;

    h3 {
      margin: $ds-baseline 0 0 0n;
      grid-row: 2;
    }

    div {
      grid-row: 3;

      > :first-child {
        margin-top: 0;
      }
    }

    img {
      grid-row: 1;
      max-height: 121px;
    }
  }

  @include breakpoint(phone-large-and-up) {
    grid-template-columns: repeat(auto-fit, minmax(29ch, 1fr));
    text-align: center;
  }

  &__align-left > div {
    justify-items: start;
    text-align: left;
  }
}
