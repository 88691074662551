@import '../../common/style/styleguide';

.phone-number-owner {
  .phone-number-owner-rhf-person-email {
    @include breakpoint(laptop-and-up) {
      max-width: 50%;
      padding-right: 6%;
    }

    .ea-input--help-text {
      .ea-input__message {
        @include breakpoint(tablet-and-up) {
          width: 200%;
        }
      }
    }
  }
}
