@import '../../common/style/styleguide';

.of-device-checkout-bill-summary-header {
  position: relative;

  &__table {
    background: $ds-color__white;
  }

  &__button-container {
    color: $ds-color__blue-600;
    background: transparent;
    position: relative;
    border: 0;

    &--button {
      position: relative;
      bottom: $ds-baseline;

      @include breakpoint(phone-large-and-down) {
        display: none;
      }
    }
  }

  &__container {
    background-color: $ds-color__white;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: $zindex1;
    box-shadow: 0 2px 2px 0 rgba(150, 150, 150, 0.3);

    &--expanded {
      left: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: rgba(0, 0, 0, 0.4);

      animation: 0.5s showUp;
    }

    &--collapsed {
      padding: 2 * $ds-baseline 1.5 * $ds-baseline; // 16 12

      animation: 0.5s showUp;

      @include breakpoint(laptop-and-up) {
        padding: 1.5 * $ds-baseline;
      }
    }
  }

  &__text-end {
    position: relative;
    text-align: end;
    margin-right: 30px;

    @include breakpoint(phone-large-and-down) {
      display: none;
    }
  }

  &__text,
  &__text-end {
    margin-top: 4px;
  }

  &__prices {
    display: grid;
    grid-template-columns: auto auto auto;
    cursor: pointer;
    position: absolute;
    right: 22px;

    @include breakpoint(tablet-and-up) {
      right: 0px;
      float: right;
      position: relative;
      margin-left: 0;
    }

    &--item {
      margin-top: 6px;
      white-space: nowrap;
    }

    &--item:not(:last-child) {
      padding: 0 6px;
    }

    &--item:first-child {
      border-right: 1px solid $ds-color__neutral-400;
      max-height: 3 * $ds-baseline; // 24
      text-align: right;
    }
  }

  &__detailed-summary {
    padding: 0 calc($ds-baseline * 3) calc($ds-baseline * 3) calc($ds-baseline * 3);
    border: none;
    background-color: $ds-color__white;
  }

  &--button-down {
    border: transparent;
    background: transparent;
    position: relative;
    bottom: 4px;
  }

  &--button-up {
    margin-top: 1.5 * $ds-baseline;
    padding-right: 3 * $ds-baseline;
    float: right;
    border: transparent;
    background: transparent;
  }

  @keyframes showUp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
