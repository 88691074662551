@import '../../common/style/styleguide';

.of-footer {
  // The footer has padding, so we can't use width: 100% without this.
  box-sizing: border-box;
  width: 100%;

  .ds-footer__disclaimer {
    white-space: nowrap;

    /*
      TODO remove this once we update CL to 4.13.0 or above.

      Copyright text overflows viewport on small screen sizes,
      causing a horizontal scrollbar to appear.
      This rule is copied from CL 4.13.0.
    */
    &_copyright {
      display: inline-block;
      text-align: center;
      white-space: normal;

      @media (min-width: $breakpoint-l) {
        display: inline;
      }
    }

    // Override OneTrust theme for the cookie consent button with the DS styles.
    #ot-sdk-btn.ds-footer__disclaimer_link.ot-sdk-show-settings {
      background: unset;
      border: unset;
      color: #171c43;
      height: inherit;
      white-space: unset;
      word-wrap: unset;
      padding: unset;
      font-size: 12px;
      line-height: 16px;
      transition: unset;

      &:hover {
        color: #0048a1;
        background-color: unset;
      }
    }
  }
}
