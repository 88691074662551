.of-public-header {
  .ds-user-menu-person.ds-user-menu-section > div:nth-child(2) {
    flex-grow: 1;
  }

  .auto-complete-container {
    margin: 5px 0 -10px -34px;
  }

  // This is quick fix which can be removed once https://github.com/elisa-design-system/component-library/issues/228 is fixed
  .ds-user-menu {
    .ds-services-menu__accordion-content {
      .ds-services-menu__accordion-item {
        padding-left: 4px;
        padding-right: 4px;
      }

      .ds-services-menu__accordion-item-text {
        padding: 0;
      }
    }
  }
}

// Temporary fix for CLS issue, remove once it's fixed in CL.Header
.of-public-header-wrapper {
  min-height: 65px;

  // This breakpoint depends on the header's desktopBreakpointOffset
  @media screen and (min-width: 996px) {
    min-height: 122px;
  }
}
