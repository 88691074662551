@import '../../common/style/styleguide';

.of-license-management {
  &--company-selection {
    display: flex;
    gap: 1rem;
    &--dropdown {
      flex-grow: 1;
    }
  }
}
