@import '../../common/style/styleguide';

.of-notification-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;

  .of-notification {
    // Animation related classes (react-transition-group library)
    &__transition-enter {
      margin-top: -3.5 * $global-baseline;
    }

    &__transition-enter-active {
      margin-top: 0;
      transition: margin-top 300ms ease-out;
    }

    &__transition-exit {
      margin-top: 0;
      transition: margin-top 300ms ease-out;
    }

    &__transition-exit-active {
      margin-top: -3.5 * $global-baseline;
      transition: margin-top 300ms ease-out;
    }
  }
}
