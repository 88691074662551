@import '../../common/style/styleguide';

.of-user {
  height: 108px;
  background: transparent url('../../common/svg/picto-cl-user.svg') left top no-repeat;
  height: 9 * $ds-baseline;
  background-size: 9 * $ds-baseline;
}

.of-name-change-confirmation-dialog__heading {
  @include h3;
  margin-top: 2 * $ds-baseline;
}
