.fluid {
  &-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &__background-image {
    display: block;
    position: absolute;
    width: 100%;
    margin: auto 0;
    top: 0;
    bottom: 0;
  }
  &__background-image img {
    width: 100%;
  }
  &__background-image:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.65)), color-stop(50%, rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    /* IE6-9 */
  }
}

.fluid-hero {
  .fluid-container{
    position: relative;
  }
  .fluid-container__background-image {
    position: relative;
    margin-bottom: -6px;
  }
  .fluid-container__text {
    position: relative;
    background: #ffffff;
    color: #404041;
    overflow: visible;
    padding: 36px 20px 24px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: 1260px;
  }
  .fluid-container__text:before {
    display: block;
    content: '';
    position: absolute;
    left: -webkit-calc(50% - 22px);
    left: calc(50% - 22px);
    top: -22px;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 22px solid #ffffff;
  }
  @media only screen and (min-width: 640px) {
    .fluid-container {
      position: absolute;
    }
    .fluid-container__text {
      padding: 0 20px;
      position: absolute;
      background: transparent;
      color: #ffffff;
    }
    .fluid-container__text:before {
      display: none;
    }
  }
  &--card {
    .fluid-container__background-image {
      position: relative;
      margin-bottom: -6px;
    }

    .fluid-container__background-image:after {
      display: none;
    }

    .fluid-container__content {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .fluid-container__text {
      color: #ffffff;
      position: relative;
      padding: 30px;
    }

    .fluid-container__text:before {
      display: none;
    }

    @media only screen and (min-width: 640px) and (max-width: 959px) {
      .fluid-container__content {
        width: 76%;
        margin-left: 14%;
      }
    }

    @media only screen and (min-width: 960px) {
      .fluid-container__text {
        padding: 36px;
      }
      .fluid-container__content {
        width: 43%;
        margin-right: 12%;
        margin-bottom: 84px;
      }
    }
  }
}

.fluid-container__content--bottom {
  position: absolute;
  bottom: 0;
}
