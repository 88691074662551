@import '../../common/style/styleguide';

.of-subscription-details {
  &__popover {
    max-width: 400px;
    p {
      line-height: initial;
      font-weight: initial;
    }
  }
}
