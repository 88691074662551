@import '../../common/style/styleguide';

.of-mobile-subscription-accordions {
  &__attach-subscription {
    .ds-contentWrapper {
      > div {
        padding-bottom: 3 * $ds-baseline;

        > div {
          padding-bottom: $global-baseline;
          padding-top: $global-baseline;
        }
      }
    }
  }
}
