@import '../../common/style/styleguide';

.of-checkout {
  $cartImageSizeLarge: 120px;
  $cartImageSizeMobile: 82px;

  &--migration-order-notice {
    margin-left: $global-baseline * 2;
    margin-top: $global-baseline;
  }

  // Pad globally the device checkout area
  padding-bottom: $global-baseline * 5;

  &__content {
    margin-top: 100px;

    .ds-loadingspinner {
      margin: 0 auto;
      display: block;
    }

    .of-checkout-steps {
      margin: 0 auto;
      max-width: $breakpoint-l;

      h2 {
        margin-left: 80px;
        margin-right: 80px;
      }
    }
  }

  &__images {
    text-align: center;
    width: 100%;

    img {
      max-width: $cartImageSizeMobile;
      max-height: $cartImageSizeMobile;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;

      @include breakpoint(tablet-and-up) {
        max-width: $cartImageSizeLarge;
        max-height: $cartImageSizeLarge;
        margin: 0 $global-column-cap;
      }
    }

    &--small img {
      @include breakpoint(tablet-and-up) {
        max-width: $cartImageSizeMobile;
        max-height: $cartImageSizeMobile;
      }
    }
  }

  &--order-delivery-details {
    @include breakpoint(laptop-and-up) {
      .ea-input {
        width: inherit;
      }
    }

    &-costCenter {
      margin-right: $global-baseline;
    }
  }

  .ea-fgrid {
    &__item {
      overflow: visible;
    }

    &--m-l-0 {
      margin-left: 0;
    }
  }

  .ea-input {
    display: flex;
  }

  .of-formik-combobox .ea-input {
    max-width: 100%;
    padding-bottom: 0;
  }

  .of-formik-combobox .ea-input span {
    white-space: nowrap;
  }

  .ea-combobox .ea-combobox__input:after {
    background-color: #fff;
  }

  .of-header {
    padding-bottom: 0;
    width: 100%;
    position: fixed;
    top: 0;
    background: $ea-color-white;
    z-index: $zindex2;
  }

  #termsAndConditions {
    border-bottom: 1px $ea-color-white-smoke solid;
  }

  .no-top-pad .ea-input {
    padding-top: 0;
  }

  &__stepper-wrapper#ea-stepper-wrapper {
    max-width: $breakpoint-l;
  }

  .of-formik-combobox--approverContact .ea-input__label {
    margin-top: -21px;
    margin-bottom: 3px;
  }

  .of-formik-combobox--approverContact div.ea-input {
    padding-bottom: 12px;
  }

  .of-device-checkout--order-delivery-details {
    div.of-checkout__costCenterAndReferenceNumber,
    div.of-device-checkout--approver-details {
      max-width: 100%;
      @include breakpoint(tablet-and-up) {
        max-width: 50%;
      }
    }
  }
}
