@import '../../common/style/styleguide';

.of-select-payment-method {
  &__tile {
    &.ds-selectgroup {
      padding: 30px 0 6 * $ds-baseline 0 !important;
    }

    &.ds-selectgroup {
      .ds-selectgroup__list {
        @include breakpoint(tablet-and-up) {
          width: $global-baseline * 32;
        }

        @include breakpoint(phone) {
          // The items get too narrow without this.
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
        }

        @include breakpoint(phone-large-and-down) {
          margin: 0 auto;
        }

        label {
          .ds-selectgroup__list-item__text__name {
            font-size: 1.45rem;
            white-space: unset;
            font-weight: bold;
          }

          .ds-selectgroup__list-item__text__price {
            font-size: 1.3rem;
            white-space: unset;
          }
        }
      }
    }
  }
}
