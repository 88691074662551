@import '../../common/style/styleguide';

.of-delivery-address {
  border: 1px solid $ea-color-dark-smoke;
  padding: $global-baseline;
  border-radius: 3px;

  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }

  @include breakpoint(laptop-and-up) {
    padding: 2 * $global-baseline;
  }

  &--left {
    @include breakpoint(laptop-and-up) {
      div {
        display: inline-block;
        padding: 0 3px;
      }
    }
  }

  &--right {
    @include breakpoint(phone-large-and-down) {
      right: $global-baseline;
      bottom: 60px;
    }

    @include breakpoint(laptop-and-up) {
      margin-left: auto;
    }
  }

  &--column {
    @include breakpoint(laptop-and-up) {
      display: flex;
      align-items: center;
    }
  }

  &--icon {
    display: inline-block;
    vertical-align: middle;
  }

  &--title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 6px;
    @include breakpoint(laptop-and-up) {
      padding: 0 6px 0 20px;
    }
  }

  #placeholder {
    visibility: hidden;
    height: $global-baseline * 7;
    display: none;
    padding-bottom: 0;

    @include breakpoint(tablet-and-up) {
      display: block;
    }
  }
}
