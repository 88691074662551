@import '../../common/style/styleguide';

.ea-icon-and-text {
  &__icon {
    height: 5 * $global-baseline;
    width: 7 * $global-baseline;
    margin: 0 auto;

    &--fixedbb {
      background: transparent url('../../common/svg/picto-fixedbb.svg') center top no-repeat;
    }

    &--mobilebb {
      background: transparent url('../../common/svg/picto-mobilebb.svg') center top no-repeat;
    }

    &--mobile-m2m {
      background: transparent url('../../common/svg/picto-broadband-m2m.svg') center top no-repeat;
    }

    &--sim {
      background: transparent url('../../common/svg/picto-sim.svg') center top no-repeat;
    }

    &--order-sim {
      background: transparent url('../../common/svg/picto-order-sim.svg') center top no-repeat;
    }

    &--sim-empty {
      background: transparent url('../../common/svg/sim-card-empty.svg') center bottom no-repeat;
      @include breakpoint(phone-large-and-down) {
        background: transparent url('../../common/svg/sim-card-empty.svg') right bottom no-repeat;
      }
    }

    &--mobileid {
      height: 60px;
      width: 60px;
      background: transparent url('../../common/svg/mobiilivarmenne-logo.svg') left top no-repeat;
    }

    &--services {
      height: 60px;
      width: 60px;
      background: transparent url('../../common/svg/ea-icon-generic-service-filled.svg') left top no-repeat;
    }
  }

  &__text {
    hyphens: auto;
    margin-top: $global-baseline;
    text-align: center;
  }
}
