@import '../../common/style/styleguide';

.of-order-physical-sim {
  &__actions {
    text-align: right;
    margin-top: 1em;
  }
  &__info {
    border-bottom: 1px solid #e0e0e1;
    padding: 1em 0 1em;
  }
}
