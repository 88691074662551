@import '../../common/style/styleguide';

.of-linkable-accordion {
  &.ea-accordion__element {
    padding: $global-baseline 0 0 0;
    cursor: inherit;
    & > .ea-accordion__header {
      cursor: pointer;
      line-height: 36px;
      height: auto;
      padding-top: $global-baseline;
      padding-bottom: $global-baseline * 2;
      &:before {
        line-height: 32px;
      }
    }
    & > .ea-accordion__content {
      padding-right: 0;
      padding-top: 0;
    }
  }

  // These are here to fix the problem with comboboxes and overflow
  @keyframes LinkableAccordionOpen {
    0% {
      opacity: 0;
      max-height: 0;
    }
    50% {
      opacity: 0.1;
      max-height: 900px;
    }
    100% {
      opacity: 1;
      max-height: 100%;
    }
  }

  @keyframes LinkableAccordionClose {
    0% {
      opacity: 1;
      max-height: 100%;
    }
    50% {
      opacity: 0;
      max-height: 900px;
    }
    100% {
      opacity: 0;
      max-height: 0;
    }
  }

  .ea-accordion__content {
    overflow: hidden;
    transition: none;
    animation-name: LinkableAccordionClose;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.82, 0, 0.1, 1);
  }
  &.ea-accordion__element--expanded {
    z-index: 1;
    .ea-accordion__content {
      overflow: visible;
      animation-name: LinkableAccordionOpen;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.82, 0, 0.1, 1);
      padding-bottom: 0;
    }
  }

  &.ea-accordion__element--no-animation {
    .ea-accordion__content {
      animation-duration: 0s;
    }
  }
}
