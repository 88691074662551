@import '../../common/style/styleguide';

.hovertooltip {
  position: absolute;
  display: inline-block;

  @include breakpoint(laptop-and-up) {
    right: 0px;
    top: 20px;
  }

  @include breakpoint(tablet-and-down) {
    position: relative;
    left: -6px;
    display: block;
  }

  @include breakpoint(phone-large-and-down) {
    display: inline-block;
    left: inherit;
  }

  .ea-icon--resend:before {
    vertical-align: initial;
  }

  .tooltiptext {
    padding: 3px 15px;
    background-color: white;
    color: black;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    visibility: hidden;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);

    @include breakpoint(tablet-and-down) {
      left: 42px;
    }

    &:after {
      background-color: white;
      content: ' ';
      position: absolute;
      top: 50%;
      left: 95%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent white transparent transparent;
      box-sizing: border-box;
      transform: rotate(45deg);
      box-shadow: 1px -1px 2px 0px rgba(0, 0, 0, 0.2);

      @include breakpoint(tablet-and-down) {
        position: absolute;
        right: 95%;
        left: initial;
        box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.hovertooltip:hover .tooltiptext {
  visibility: visible;
}
