@import '../../common/style/styleguide';

.of-billing-account-reference-numbers {
  &--read {
    label {
      display: block;
      font-weight: 600;
    }
  }

  .ea-fgrid__item {
    padding-bottom: $global-baseline;
  }
}
