@import '../../common/style/styleguide';

.subscription-selection-header {
  border-bottom: 1px solid $ds-color__neutral-300;
  margin: 0 $ds-baseline $ds-baseline * 5;
  > h4 {
    margin-bottom: 0;
    padding-bottom: $ds-baseline * 3;
  }
}
