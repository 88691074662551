@import '../../common/style/styleguide';

.of-selectgroup.ds-selectgroup {
  // ds-selectgroup changed the padding and made it !important...
  padding: 0 !important;
  @include breakpoint(phone) {
    .ds-selectgroup__list {
      // The items get too narrow without this.
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr)) !important;
    }
  }
}
